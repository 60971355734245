import { Box, Flex, Text } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useState, useEffect } from "react";

function formatarValor(valor) {
  if (valor === null || valor === undefined) {
    return "R$ 00,00";
  }

  const valorFormatado = valor.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return valorFormatado;
}

export default function Produtos({ data }) {
  const [forma, setForma] = useState([]);

  useEffect(() => {
    // console.log(data);
    setForma(data);
  }, [data]);

  const sortedProdutos = forma.sort((a, b) => b.valorTotal - a.valorTotal);
  const maiorValor = sortedProdutos[0]?.valorTotal;

  return (
    <Card bg="#0B0B61" color="white" align="center" direction="column" w="100%">
      <Flex align="center" w="100%" px="15px" py="10px">
        <Text me="auto"  fontSize="xl" fontWeight="700" lineHeight="100%">
          Top 10 Produtos mais Vendidos
        </Text>
      </Flex>
      <Box mt="2%">
        <ol style={{ listStyleType: "none", padding: 0 }}>
          {sortedProdutos.map((produto, index) => {
            const progressBarColor = index % 2 === 0 ? "#33b2df" : "#546E7A";
            const produtoNomeMaiusculo = produto._id.toUpperCase();
            return (
              <li key={index} style={{ marginBottom: "10px" }}>
                {/* <Flex align="center" justify="space-between"> */}
                <Box flex="1" w="100%">
                  <Flex align="center" justify="space-between">
                    <Text
                      me="10px"
                      fontWeight="600"
                      fontSize="sm"
                    >
                      {produtoNomeMaiusculo}
                    </Text>
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      title={`qtd: ${produto.quantidadeVendas}/ Vt: ${formatarValor(
                        produto.valorTotal
                      )}`}
                      noOfLines={1}
                      isTruncated
                    >
                      qtd: {produto.quantidadeVendas} - V: {formatarValor(produto.valorTotal)}
                    </Text>
                  </Flex>
                </Box>
                <Box flex="1" display="flex" alignItems="center">
                  {/* <Box
                      flex="1"
                      h="12px"
                      bg={progressBarColor}
                      borderRadius="4px"
                      position="relative"
                    >
                      <Box
                        position="absolute"
                        top="0"
                        left="0"
                        h="100%"
                        w={`${(produto.valorTotal / maiorValor) * 100}%`}
                        bg={progressBarColor}
                        borderRadius="4px"
                      />
                    </Box> */}
                  <Box
                    flex="1"
                    h="12px"
                    borderRadius="4px"
                    bg="#EDF2F7" // Cor de fundo da barra de progresso
                    overflow="hidden" // Esconder o conteúdo que ultrapassa a barra
                  >
                    <Box
                      h="100%"
                      bg="#3182CE" // Cor do preenchimento da barra de progresso
                      transform={`scaleX(${(produto.valorTotal / maiorValor)})`} // Escala horizontal do preenchimento
                      transformOrigin="left" // Origem da escala no lado esquerdo
                    />
                  </Box>
                </Box>
                {/* </Flex> */}
              </li>
            );
          })}
        </ol>
      </Box>
    </Card >
  );
}
