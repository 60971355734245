// /* eslint-disable */
// import React from "react";
// import { NavLink, useLocation } from "react-router-dom";
// // chakra imports
// import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";

// export function SidebarLinks(props) {
//   //   Chakra color mode
//   let location = useLocation();
//   let activeColor = useColorModeValue("gray.700", "white");
//   let inactiveColor = useColorModeValue(
//     "secondaryGray.600",
//     "secondaryGray.600"
//   );
//   let activeIcon = useColorModeValue("brand.500", "white");
//   let textColor = useColorModeValue("secondaryGray.500", "white");
//   let brandColor = useColorModeValue("brand.500", "brand.400");

//   const { routes } = props;

//   // verifies if routeName is the one active (in browser input)
//   const activeRoute = (routeName) => {
//     return location.pathname.includes(routeName);
//   };

//   // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
//   const createLinks = (routes) => {

//     return routes.map((route, index) => {
//       if (route.category) {
//         return (
//           <>
//             <Text
//               fontSize={"md"}
//               color={activeColor}
//               fontWeight='bold'
//               mx='auto'
//               ps={{
//                 sm: "10px",
//                 xl: "16px",
//               }}
//               pt='18px'
//               pb='12px'
//               key={index}>
//               {route.name}
//             </Text>
//             {createLinks(route.items)}
//           </>
//         );
//       } else if (
//         route.layout === "/admin" ||
//         route.layout === "/auth" ||
//         route.layout === "/rtl"
//       ) {
//         return (
//         route.name !== "Login" && (
//           <NavLink key={index} to={route.layout + route.path}>
//             {route.icon ? (
//               <Box>
//                 <HStack
//                   spacing={
//                     activeRoute(route.path.toLowerCase()) ? "16px" : "20px"
//                   }
//                   py='5px'
//                   ps='10px'>
//                   <Flex w='100%' alignItems='center' justifyContent='center'>
//                     <Box
//                       color={
//                         activeRoute(route.path.toLowerCase())
//                           ? activeIcon
//                           : textColor
//                       }
//                       me='18px'>
//                       {route.icon}
//                     </Box>
//                     <Text
//                       me='auto'
//                       color={
//                         activeRoute(route.path.toLowerCase())
//                           ? activeColor
//                           : textColor
//                       }
//                       fontWeight={
//                         activeRoute(route.path.toLowerCase())
//                           ? "bold"
//                           : "normal"
//                       }>
//                       {route.name}
//                     </Text>
//                   </Flex>
//                   <Box
//                     h='36px'
//                     w='4px'
//                     bg={
//                       activeRoute(route.path.toLowerCase())
//                         ? brandColor
//                         : "transparent"
//                     }
//                     borderRadius='5px'
//                   />
//                 </HStack>
//               </Box>
//             //  ) : null 
//             ) : 
//               <Box>
//                 <HStack
//                   spacing={
//                     activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
//                   }
//                   py='5px'
//                   ps='10px'>
//                   <Text
//                     me='auto'
//                     color={
//                       activeRoute(route.path.toLowerCase())
//                         ? activeColor
//                         : inactiveColor
//                     }
//                     fontWeight={
//                       activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
//                     }>
//                     {route.name}
//                   </Text>
//                   <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
//                 </HStack>
//               </Box>
//             }
//           </NavLink>
//           )
//         );
//       }
//     });
//   };
//   //  BRAND
//   return createLinks(routes);
// }

// export default SidebarLinks;
// import React from "react";
// import { NavLink, useLocation } from "react-router-dom";
// import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";

// export function SidebarLinks(props) {
//   let location = useLocation();
//   let activeColor = useColorModeValue("gray.700", "white");
//   let inactiveColor = useColorModeValue("secondaryGray.600", "secondaryGray.600");
//   let activeIcon = useColorModeValue("brand.500", "white");
//   let textColor = useColorModeValue("secondaryGray.500", "white");
//   let brandColor = useColorModeValue("brand.500", "brand.400");

//   const { routes } = props;

//   const activeRoute = (routeName) => {
//     return location.pathname.includes(routeName);
//   };

//   const groupedLinks = routes.reduce((acc, route) => {
//     if (route.menu) {
//       if (!acc[route.menu]) {
//         acc[route.menu] = [];
//       }
//       acc[route.menu].push(route);
//     }
//     return acc;
//   }, {});

//   const renderMenuLinks = () => {
//     return Object.entries(groupedLinks).map(([menu, links]) => {
//       return (
//         <React.Fragment key={menu}>
//           <Text
//             fontSize="md"
//             color={activeColor}
//             fontWeight="bold"
//             mx="auto"
//             ps={{ sm: "10px", xl: "16px" }}
//             pt="18px"
//             pb="12px">
//             Menu {menu}
//           </Text>
//           {links.map((route, index) => (
//             <NavLink key={index} to={route.layout + route.path}>
//               {route.icon ? (
//                 <Box>
//                   <HStack
//                     spacing={activeRoute(route.path.toLowerCase()) ? "16px" : "20px"}
//                     py="5px"
//                     ps="10px"
//                   >
//                     <Flex w="100%" alignItems="center" justifyContent="center">
//                       <Box
//                         color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor}
//                         me="18px"
//                       >
//                         {route.icon}
//                       </Box>
//                       <Text
//                         me="auto"
//                         color={activeRoute(route.path.toLowerCase()) ? activeColor : textColor}
//                         fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}
//                       >
//                         {route.name}
//                       </Text>
//                     </Flex>
//                     <Box
//                       h="36px"
//                       w="4px"
//                       bg={activeRoute(route.path.toLowerCase()) ? brandColor : "transparent"}
//                       borderRadius="5px"
//                     />
//                   </HStack>
//                 </Box>
//               ) : (
//                 <Box>
//                   <HStack
//                     spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
//                     py="5px"
//                     ps="10px"
//                   >
//                     <Text
//                       me="auto"
//                       color={activeRoute(route.path.toLowerCase()) ? activeColor : inactiveColor}
//                       fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}
//                     >
//                       {route.name}
//                     </Text>
//                     <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
//                   </HStack>
//                 </Box>
//               )}
//             </NavLink>
//           ))}
//         </React.Fragment>
//       );
//     });
//   };

//   return <>{renderMenuLinks()}</>;
// }

// export default SidebarLinks;


// import React, { useState } from "react";
// import { NavLink, useLocation } from "react-router-dom";
// import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";

// export function SidebarLinks(props) {
//   const location = useLocation();
//   const activeColor = useColorModeValue("gray.700", "white");
//   const inactiveColor = useColorModeValue("secondaryGray.600", "secondaryGray.600");
//   const activeIcon = useColorModeValue("brand.500", "white");
//   const textColor = useColorModeValue("secondaryGray.500", "white");
//   const brandColor = useColorModeValue("brand.500", "brand.400");

//   const { routes } = props;

//   const activeRoute = (routeName) => {
//     return location.pathname.includes(routeName);
//   };

//   const [expandedMenus, setExpandedMenus] = useState([]);

//   const toggleMenu = (menu) => {
//     if (expandedMenus.includes(menu)) {
//       setExpandedMenus(expandedMenus.filter((m) => m !== menu));
//     } else {
//       setExpandedMenus([...expandedMenus, menu]);
//     }
//   };

//   const groupedLinks = routes.reduce((acc, route) => {
//     if (route.menu) {
//       if (!acc[route.menu]) {
//         acc[route.menu] = [];
//       }
//       acc[route.menu].push(route);
//     }
//     return acc;
//   }, {});

//   const renderMenuLinks = () => {
//     return Object.entries(groupedLinks).map(([menu, links]) => {
//       const isExpanded = expandedMenus.includes(menu);

//       return (
//         <React.Fragment key={menu}>
//           <Text
//             fontSize="md"
//             color={activeColor}
//             fontWeight="bold"
//             mx="auto"
//             ps={{ sm: "10px", xl: "16px" }}
//             pt="18px"
//             pb="12px"
//             onClick={() => toggleMenu(menu)}
//             cursor="pointer"
//           >
//             Menu {menu}
//           </Text>
//           {isExpanded &&
//             links.map((route, index) => (
//               <NavLink key={index} to={route.layout + route.path}>
//                 {route.icon ? (
//                   <Box>
//                     <HStack
//                       spacing={activeRoute(route.path.toLowerCase()) ? "16px" : "20px"}
//                       py="5px"
//                       ps="10px"
//                     >
//                       <Flex w="100%" alignItems="center" justifyContent="center">
//                         <Box
//                           color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor}
//                           me="18px"
//                         >
//                           {route.icon}
//                         </Box>
//                         <Text
//                           me="auto"
//                           color={activeRoute(route.path.toLowerCase()) ? activeColor : textColor}
//                           fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}
//                         >
//                           {route.name}
//                         </Text>
//                       </Flex>
//                       <Box
//                         h="36px"
//                         w="4px"
//                         bg={activeRoute(route.path.toLowerCase()) ? brandColor : "transparent"}
//                         borderRadius="5px"
//                       />
//                     </HStack>
//                   </Box>
//                 ) : (
//                   <Box>
//                     <HStack
//                       spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
//                       py="5px"
//                       ps="10px"
//                     >
//                       <Text
//                         me="auto"
//                         color={
//                           activeRoute(route.path.toLowerCase()) ? activeColor : inactiveColor
//                         }
//                         fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}
//                       >
//                         {route.name}
//                       </Text>
//                       <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
//                     </HStack>
//                   </Box>
//                 )}
//               </NavLink>
//             ))}
//         </React.Fragment>
//       );
//     });
//   };

//   return <>{renderMenuLinks()}</>;
// }

// export default SidebarLinks;

// import React, { useState } from "react";
// import { NavLink, useLocation } from "react-router-dom";
// import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";

// export function SidebarLinks(props) {
//   const location = useLocation();
//   const activeColor = useColorModeValue("gray.700", "white");
//   const inactiveColor = useColorModeValue("secondaryGray.600", "secondaryGray.600");
//   const activeIcon = useColorModeValue("brand.500", "white");
//   const textColor = useColorModeValue("secondaryGray.500", "white");
//   const brandColor = useColorModeValue("brand.500", "brand.400");

//   const { routes } = props;

//   const activeRoute = (routeName) => {
//     return location.pathname.includes(routeName);
//   };

//   const [expandedMenus, setExpandedMenus] = useState([]);
//   const [activeMenu, setActiveMenu] = useState(""); // Estado para rastrear o menu pai ativo

//   const toggleMenu = (menu) => {
//     if (expandedMenus.includes(menu)) {
//       setExpandedMenus(expandedMenus.filter((m) => m !== menu));
//     } else {
//       setExpandedMenus([...expandedMenus, menu]);
//     }
//   };

//   const groupedLinks = routes.reduce((acc, route) => {
//     if (route.menu) {
//       if (!acc[route.menu]) {
//         acc[route.menu] = [];
//       }
//       acc[route.menu].push(route);
//     }
//     return acc;
//   }, {});

//   // Verificar se algum link dentro do menu está ativo
//   const isMenuActive = (menu) => {
//     const links = groupedLinks[menu];
//     if (!links) return false;
//     return links.some((route) => activeRoute(route.layout + route.path));
//   };

//   const renderMenuLinks = () => {
//     return Object.entries(groupedLinks).map(([menu, links]) => {
//       const isExpanded = expandedMenus.includes(menu);
//       const isActiveMenu = activeMenu === menu || isMenuActive(menu); // Verifica se o menu está ativo

//       return (
//         <React.Fragment key={menu}>
//           <Text
//             fontSize="md"
//             color={isActiveMenu ? activeColor : inactiveColor}
//             fontWeight="bold"
//             mx="auto"
//             ps={{ sm: "10px", xl: "16px" }}
//             pt="18px"
//             pb="12px"
//             onClick={() => {
//               toggleMenu(menu);
//               if (!isExpanded && isActiveMenu) { // Expandir o menu apenas se estiver ativo e não estiver expandido
//                 setExpandedMenus([...expandedMenus, menu]);
//               }
//               setActiveMenu(menu);
//             }}
//             cursor="pointer"
//           >
//             {menu}
//           </Text>
//           {isExpanded &&
//             links.map((route, index) => {
//               const isRouteActive = activeRoute(route.layout + route.path);
//               return (
//                 <NavLink key={index} to={route.layout + route.path}>
//                   {route.icon ? (
//                     <Box>
//                       <HStack
//                         spacing={isRouteActive ? "16px" : "20px"}
//                         py="5px"
//                         ps="10px"
//                       >
//                         <Flex w="100%" alignItems="center" justifyContent="center">
//                           <Box
//                             color={isRouteActive ? activeIcon : textColor}
//                             me="18px"
//                           >
//                             {route.icon}
//                           </Box>
//                           <Text
//                             me="auto"
//                             color={isRouteActive ? activeColor : textColor}
//                             fontWeight={isRouteActive ? "bold" : "normal"}
//                           >
//                             {route.name}
//                           </Text>
//                         </Flex>
//                         <Box
//                           h="36px"
//                           w="4px"
//                           bg={isRouteActive ? brandColor : "transparent"}
//                           borderRadius="5px"
//                         />
//                       </HStack>
//                     </Box>
//                   ) : (
//                     <Box>
//                       <HStack
//                         spacing={isRouteActive ? "22px" : "26px"}
//                         py="5px"
//                         ps="10px"
//                       >
//                         <Text
//                           me="auto"
//                           color={isRouteActive ? activeColor : inactiveColor}
//                           fontWeight={isRouteActive ? "bold" : "normal"}
//                         >
//                           {route.name}
//                         </Text>
//                         <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
//                       </HStack>
//                     </Box>
//                   )}
//                 </NavLink>
//               );
//             })}
//         </React.Fragment>
//       );
//     });
//   };

//   return <>{renderMenuLinks()}</>;
// }

// export default SidebarLinks;

// <React.Fragment key={menu}>
//   <Text
//     fontSize="md"
//     color={isActiveMenu ? activeColor : inactiveColor}
//     fontWeight="bold"
//     mx="auto"
//     ps={{ sm: "8px", xl: "16px" }}
//     pt="18px"
//     pb="12px"
//     onClick={() => {
//       toggleMenu(menu);
//       if (!isExpanded && isActiveMenu) {
//         setExpandedMenus([...expandedMenus, menu]);
//       }
//       setActiveMenu(menu);
//     }}
//     cursor="pointer"
//   >
//     {menu}
//   </Text>
//   <Box display={isExpanded || isActiveMenu ? "block" : "block"}> {/* Modificação aqui */}
//     {links.map((route, index) => {
//       const isRouteActive = activeRoute(route.layout + route.path);
//       return (
//         <NavLink key={index} to={route.layout + route.path}>
//           {route.icon ? (
//             <Box>
//               <HStack
//                 spacing={isRouteActive ? "16px" : "20px"}
//                 py="5px"
//                 ps="10px"
//               >
//                 <Flex w="100%" alignItems="center" justifyContent="center">
//                   <Box
//                     color={isRouteActive ? activeIcon : textColor}
//                     me="18px"
//                   >
//                     {route.icon}
//                   </Box>
//                   <Text
//                     fontSize={{ sm: "8px", xl: "16px" }}
//                     me="auto"
//                     color={isRouteActive ? activeColor : textColor}
//                     fontWeight={isRouteActive ? "bold" : "normal"}
//                   >
//                     {route.name}
//                   </Text>
//                 </Flex>
//                 <Box
//                   h="36px"
//                   w="4px"
//                   bg={isRouteActive ? brandColor : "transparent"}
//                   borderRadius="5px"
//                 />
//               </HStack>
//             </Box>
//           ) : (
//             <Box>
//               <HStack
//                 spacing={isRouteActive ? "22px" : "26px"}
//                 py="5px"
//                 ps="10px"
//               >
//                 <Text
//                   me="auto"
//                   color={isRouteActive ? activeColor : inactiveColor}
//                   fontWeight={isRouteActive ? "bold" : "normal"}
//                 >
//                   {route.name}
//                 </Text>
//                 <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
//               </HStack>
//             </Box>
//           )}
//         </NavLink>
//       );
//     })}
//   </Box>
// </React.Fragment>


import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import AsyncStorage from '@react-native-async-storage/async-storage';

export function SidebarLinks(props) {
  const location = useLocation();
  const activeColor = useColorModeValue("gray.700", "white");
  const inactiveColor = useColorModeValue("secondaryGray.600", "secondaryGray.600");
  const activeIcon = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const brandColor = useColorModeValue("brand.500", "brand.400");
  const [chave, setKey] = useState('');
  const [permissao, setPermisao] = useState('');
  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem('@storage_Key')

      if (value !== null) {
        return value;// value previously stored
      }
    } catch (e) {
      // error reading value
    }
  }

  const getData2 = async () => {
    try {
      const perfil = await AsyncStorage.getItem('@perfil')
      return perfil;// value previously stored
    } catch (e) {
      // error reading value
    }
  }

  function verificarPermissao(routePerfil, permissao) {
    // Se permissao for vazia ou igual a "2", retorna true diretamente
    if (permissao === "") {
      return true;
    }
  
    // Quebra a string permissao por "," e remove espaços em branco
    const permissoesArray = permissao.split(',').map(value => value.trim());
    // console.log(permissao, routePerfil)
    const resul = permissoesArray.includes(String(routePerfil));
    // console.log(resul);
    // Verifica se o perfil está incluso no array de permissoes
    return resul
  }

  useEffect(() => {
    const fetchData = async () => {
      const teste = await getData();
      const perfil = await getData2();
      // Restante do código aqui que depende do resultado de getData()
      setKey(teste);
      setPermisao(perfil)
    };


    fetchData();
  }, []);


  // console.log(chave)

  const { routes } = props;

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const [expandedMenus, setExpandedMenus] = useState([]);
  const [activeMenu, setActiveMenu] = useState(""); // Estado para rastrear o menu pai ativo

  const toggleMenu = (menu) => {
    if (expandedMenus.includes(menu)) {
      setExpandedMenus(expandedMenus.filter((m) => m !== menu));
    } else {
      setExpandedMenus([...expandedMenus, menu]);
    }
  };

  const groupedLinks = routes.reduce((acc, route) => {
    // console.log(route.menu);
    if (route.menu) {
      if (!acc[route.menu]) {
        acc[route.menu] = [];
      }
      acc[route.menu].push(route);
    }
    return acc;
  }, {});

  // Verificar se algum link dentro do menu está ativo
  const isMenuActive = (menu) => {
    const links = groupedLinks[menu];
    if (!links) return false;
    return links.some((route) => activeRoute(route.layout + route.path));
  };

  const renderMenuLinks = () => {

    // console.log(chave, permissao)

    const shouldRenderConfigurationMenu = chave === "adm@logoncloud.com.br";
    // console.log(groupedLinks);

    return Object.entries(groupedLinks).map(([menu, links]) => {
      const isExpanded = expandedMenus.includes(menu);
      const isActiveMenu = activeMenu === menu || isMenuActive(menu); // Verifica se o menu está ativo

      //   const shouldRenderConfigurationMenu = menu === "Configuração" && chave === "teste@teste.com";

      //   console.log(shouldRenderConfigurationMenu)
      // // Verifica se a condição é atendida para renderizar o menu ou não

      //   const shouldRenderMenu = menu !== "Configuração" || shouldRenderConfigurationMenu;

      //   if (!shouldRenderMenu) {
      //     return null; // Retorna nulo se o menu não deve ser renderizado
      //   }

      // if (shouldRenderOnlyConfigurationMenu && menu !== "Configuração") {
      //   return null; // Não renderizar menus que não sejam "Configuração"
      // }



      if ((menu === "Configuração" || menu === "Vendas" || menu === "Logistica") && shouldRenderConfigurationMenu) {
        return (
          <React.Fragment key={menu}>

            <Text
              fontSize={{ base: "md", md: "lg" }} // Defina tamanhos de texto para diferentes tamanhos de tela
              color={isActiveMenu ? activeColor : inactiveColor}
              fontWeight="bold"
              mx="auto"
              ps={{ sm: "8px", xl: "16px" }}
              pt={{ base: "12px", md: "18px" }} // Ajuste o espaçamento de acordo com a tela
              pb={{ base: "12px", md: "18px" }} // Ajuste o espaçamento de acordo com a tela
              onClick={() => {
                toggleMenu(menu);
                if (!isExpanded && isActiveMenu) {
                  setExpandedMenus([...expandedMenus, menu]);
                }
                setActiveMenu(menu);
              }}
              cursor="pointer"
            >
              {menu}
            </Text>
            <Box display={isExpanded || isActiveMenu ? "block" : "block"}>
              {links.map((route, index) => {
                const isRouteActive = activeRoute(route.layout + route.path);
                return (
                  <NavLink key={index} to={route.layout + route.path}>
                    {route.icon ? (
                      <Box>
                        <HStack
                          spacing={isRouteActive ? "16px" : "20px"}
                          py="5px"
                          ps="10px"
                        >
                          <Flex w="100%" alignItems="center" justifyContent="center">
                            <Box
                              color={isRouteActive ? activeIcon : textColor}
                              me="18px"
                            >
                              {route.icon}
                            </Box>
                            <Text
                              fontSize={{ base: "12px", md: "14px", lg: "16px" }} // Defina tamanhos de texto para diferentes tamanhos de tela
                              me="auto"
                              color={isRouteActive ? activeColor : textColor}
                              fontWeight={isRouteActive ? "bold" : "normal"}
                            >
                              {route.name}
                            </Text>
                          </Flex>
                          <Box
                            h="36px"
                            w="4px"
                            bg={isRouteActive ? brandColor : "transparent"}
                            borderRadius="5px"
                          />
                        </HStack>
                      </Box>
                    ) : (
                      <Box>
                        <HStack
                          spacing={isRouteActive ? "22px" : "26px"}
                          py="5px"
                          ps="10px"
                        >
                          <Text
                            me="auto"
                            color={isRouteActive ? activeColor : inactiveColor}
                            fontWeight={isRouteActive ? "bold" : "normal"}
                          >
                            {route.name}
                          </Text>
                          <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                        </HStack>
                      </Box>
                    )}
                  </NavLink>
                );
              })}
            </Box>

          </React.Fragment>
        );
      } else if (menu !== "Configuração" && !shouldRenderConfigurationMenu) {
        // console.log(links)

        return (
          <React.Fragment key={menu}>

            <Text
              fontSize={{ base: "md", md: "lg" }} // Defina tamanhos de texto para diferentes tamanhos de tela
              color={isActiveMenu ? activeColor : inactiveColor}
              fontWeight="bold"
              mx="auto"
              ps={{ sm: "8px", xl: "16px" }}
              pt={{ base: "12px", md: "18px" }} // Ajuste o espaçamento de acordo com a tela
              pb={{ base: "12px", md: "18px" }} // Ajuste o espaçamento de acordo com a tela
              onClick={() => {
                toggleMenu(menu);
                if (!isExpanded && isActiveMenu) {
                  setExpandedMenus([...expandedMenus, menu]);
                }
                setActiveMenu(menu);
              }}
              cursor="pointer"
            >
              {menu}
            </Text>
            <Box display={isExpanded || isActiveMenu ? "block" : "block"}>
              {links.map((route, index) => {
                const isRouteActive = activeRoute(route.layout + route.path);
                return (
                  // || chave != "teste@teste.com" 
                  // route.perfil == permissao || permissao == "" || permissao == "2" ?
                  verificarPermissao(route.perfil, permissao) ?
                    (<NavLink key={index} to={route.layout + route.path}>
                      {route.icon ? (
                        <Box>
                          <HStack
                            spacing={isRouteActive ? "16px" : "20px"}
                            py="5px"
                            ps="10px"
                          >
                            <Flex w="100%" alignItems="center" justifyContent="center">
                              <Box
                                color={isRouteActive ? activeIcon : textColor}
                                me="18px"
                              >
                                {route.icon}
                              </Box>
                              <Text
                                fontSize={{ base: "12px", md: "14px", lg: "16px" }} // Defina tamanhos de texto para diferentes tamanhos de tela
                                me="auto"
                                color={activeColor}
                                // color={isRouteActive ? activeColor : textColor}
                                fontWeight={"bold"}
                              // fontWeight={isRouteActive ? "bold" : "normal"}
                              >
                                {route.name}
                              </Text>
                            </Flex>
                            <Box
                              h="36px"
                              w="4px"
                              bg={isRouteActive ? brandColor : "transparent"}
                              borderRadius="5px"
                            />
                          </HStack>
                        </Box>
                      ) : (
                        <Box>
                          <HStack
                            spacing={isRouteActive ? "22px" : "26px"}
                            py="5px"
                            ps="10px"
                          >
                            <Text
                              me="auto"
                              color={isRouteActive ? activeColor : inactiveColor}
                              fontWeight={isRouteActive ? "bold" : "normal"}
                            >
                              {route.name}
                            </Text>
                            <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                          </HStack>
                        </Box>
                      )}
                    </NavLink>
                    ) : (
                      route.icon ? (
                        <Box>
                          <HStack
                            spacing={isRouteActive ? "16px" : "20px"}
                            py="5px"
                            ps="10px"
                          >
                            <Flex w="100%" alignItems="center" justifyContent="center">
                              <Box
                                color={isRouteActive ? activeIcon : textColor}
                                me="18px"
                              >
                                {route.icon}
                              </Box>
                              <Text
                                fontSize={{ base: "12px", md: "14px", lg: "16px" }} // Defina tamanhos de texto para diferentes tamanhos de tela
                                me="auto"
                                color={isRouteActive ? activeColor : textColor}
                                fontWeight={isRouteActive ? "bold" : "normal"}
                              >
                                {route.name}
                              </Text>
                            </Flex>
                            <Box
                              h="36px"
                              w="4px"
                              bg={isRouteActive ? brandColor : "transparent"}
                              borderRadius="5px"
                            />
                          </HStack>
                        </Box>
                      ) : (
                        <Box>
                          <HStack
                            spacing={isRouteActive ? "22px" : "26px"}
                            py="5px"
                            ps="10px"
                          >
                            <Text
                              me="auto"
                              color={isRouteActive ? activeColor : inactiveColor}
                              fontWeight={isRouteActive ? "bold" : "normal"}
                            >
                              {route.name}
                            </Text>
                            <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                          </HStack>
                        </Box>
                      )
                    )
                );
              })}
            </Box>

          </React.Fragment>
        );
      }
    });
  };

  return <>{renderMenuLinks()}</>;
}

export default SidebarLinks;
