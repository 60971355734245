import React from "react";

// Chakra imports
import { Flex, useColorModeValue, Text, Image  } from "@chakra-ui/react";
import logon from "assets/img/layout/logon.png";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column' bg={'#f7f7f7'}>
      {/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
      <Text
              fontSize={"md"}
              fontWeight='bold'
              mx='auto'
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              pt='18px'
              pb='12px'
      >Business Intelligence</Text>
         {/* <Image boxSize={100} src={logon} alt='Dan Abramov' /> */}
      <HSeparator />
    </Flex>
  );
}

export default SidebarBrand;
