import React from "react";
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Box, Text, Button } from "@chakra-ui/react";

import jsPDF from 'jspdf';
import autoTable from "jspdf-autotable";

function somarArray(array) {
  var soma = 0;
  for (var elemento of array) {
    soma += elemento;
  }
  return soma;
}


function TabelaFinanceira({ dados }) {

  // console.log(dados)

  const gruposDRE = [...new Set(dados.map((dado) => dado.GrupoDRE))];

  const totalgeraltrue = [];
  const totalgeralfalse = [];
  // console.log(gruposDRE)

  const gruposTrue = gruposDRE.filter((grupo) => dados.some((dado) => dado.GrupoDRE === grupo && dado.Despesa));
  const gruposFalse = gruposDRE.filter((grupo) => dados.some((dado) => dado.GrupoDRE === grupo && !dado.Despesa));


  // const gruposTrue = gruposDRE.filter((grupo) => grupo);
  // const gruposFalse = gruposDRE.filter((grupo) => !grupo);

  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ];

  const formatarValor = (valor) => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    return formatter.format(valor);
  };


//  const gerarPDF = () => {
//     const doc = new jsPDF('landscape');

//     // Cabeçalho do PDF
//     // doc.setFontSize(12);
//     // doc.text("Relatório de Plano de Contas", 10, 10);
//     // doc.setFontSize(9);

//     // Cabeçalhos da tabela
//     const header = [
//       "Grupo DRE", "Plano de Conta",
//       ...meses,
//       "Total Ano"
//     ];

//     const columns = ["", "", ...meses, "Total Ano"];

//     // Dados para Receitas e Despesas
//     const receitas = [];
//     const despesas = [];

//     // Processamento de Receitas
//     const totalgeraltrue = new Array(meses.length).fill(0);
//     gruposFalse.forEach((grupo) => {
//       if (grupo !== "") {
//         receitas.push([{ content: grupo || "Não Agrupado", colSpan: header.length, styles: { halign: 'left', fillColor: "white", textColor: "blue" } }]);
//         const filteredDados = dados.filter((dado) => {
//           if (grupo === "" || grupo === null) {
//             return (!dado.GrupoDRE || dado.GrupoDRE === "") && !dado.Despesa;
//           } else {
//             return dado.GrupoDRE === grupo && !dado.Despesa;
//           }
//         });

//         let totalValues = new Array(meses.length).fill(0);
//         filteredDados.forEach((dado) => {
//           totalValues = new Array(meses.length).fill(0); // Zerando todos os campos
//           dado.Meses.forEach((mes) => {
//             const value = mes.ValorTotalPago.ValorTotalPago || 0;
//             totalValues[mes.Mes - 1] += value;
//             totalgeraltrue[mes.Mes - 1] += value;
//           });
//           receitas.push([
//             "", dado.PlanoDeConta, ...totalValues.map(formatarValor), formatarValor(somarArray(totalValues))
//           ]);
//         });

//         receitas.push([
//           "", "Total", ...totalgeraltrue.map(formatarValor), formatarValor(somarArray(totalgeraltrue))
//         ]);
//       }
//     });

//     // Processamento de Despesas
//     const totalgeralfalse = new Array(meses.length).fill(0);
//     gruposTrue.forEach((grupo) => {
//       if (grupo !== "") {
//         despesas.push([{ content: grupo || "Não Agrupado", colSpan: header.length, styles: { halign: 'left', fillColor: "white", textColor: "red" } }]);
//         const filteredDados = dados.filter((dado) => {
//           if (grupo === "" || grupo === null) {
//             return (!dado.GrupoDRE || dado.GrupoDRE === "") && dado.Despesa;
//           } else {
//             return dado.GrupoDRE === grupo && dado.Despesa;
//           }
//         });

//         let totalValues = new Array(meses.length).fill(0); // Zerando todos os campos
//         filteredDados.forEach((dado) => {
//           totalValues = new Array(meses.length).fill(0);
//           dado.Meses.forEach((mes) => {
//             const value = mes.ValorTotalPago.ValorTotalPago || 0;
//             totalValues[mes.Mes - 1] += value;
//             totalgeralfalse[mes.Mes - 1] += value;
//           });
//           despesas.push([
//             "", dado.PlanoDeConta, ...totalValues.map(formatarValor), formatarValor(somarArray(totalValues))
//           ]);
//         });

//         despesas.push([
//           "", "Total", ...totalgeralfalse.map(formatarValor), formatarValor(somarArray(totalgeralfalse))
//         ]);
//       }
//     });

//     // Cálculo do Resultado e Total Ano
//     const resultado = meses.map((_, index) => formatarValor((totalgeraltrue[index] || 0) - (totalgeralfalse[index] || 0)));
//     const totalAnoResultado = formatarValor(somarArray(totalgeraltrue) - somarArray(totalgeralfalse));

//     // Adiciona legenda "Receitas(+)" em azul antes da tabela de receitas
//     doc.setTextColor(0, 0, 255); // Azul
//     doc.text("Receitas(+)", 10, 1);
//     doc.setTextColor(0, 0, 0); // Preto

//     autoTable(doc, {
//       head: [header],
//       body: receitas,
//       startY: doc.autoTable.previous ? doc.autoTable.previous.finalY + 20 : 30,
//       theme: 'grid',
//       styles: { fontSize: 9 }
//     });

//     // Adiciona legenda "Despesas(-)" em vermelho antes da tabela de despesas
//     doc.setTextColor(255, 0, 0); // Vermelho
//     doc.text("Despesas(-)", 10, doc.autoTable.previous.finalY + 10);
//     doc.setTextColor(0, 0, 0); // Preto

//     autoTable(doc, {
//       head: [header],
//       body: despesas,
//       startY: doc.autoTable.previous.finalY + 20,
//       theme: 'grid',
//       styles: { fontSize: 9 }
//     });

//     const data = [];

//     // const resultBalance = totalgeraltrue.map((value, index) => formatarValor(value - totalgeralfalse[index]));

//     // data.push(["Resultado Balanço:", "", ...resultBalance, formatarValor(somarArray(totalgeraltrue) - somarArray(totalgeralfalse))]);
//     // console.log(totalgeralfalse, totalgeraltrue)

//     // const resultBalance = totalgeraltrue.map((value, index) => {
//     //   const receita = value || 0; // Se value for null, undefined ou 0, usa 0
//     //   const despesa = totalgeralfalse[index] || 0; // Se totalgeralfalse[index] for null, undefined ou 0, usa 0
//     //   const balance = receita - despesa;
//     //   return formatarValor(Math.max(balance, 0)); // Garante que o valor seja no mínimo 0
//     // });

//     const resultBalance = totalgeralfalse.map((value, index) => {
//       const receita = totalgeraltrue[index] !== undefined ? totalgeraltrue[index] : 0; // Se não existir, usa 0
//       const despesa = value || 0; // Se não existir, usa 0
//       const balance = receita - despesa;
//       return formatarValor(balance); // Exibe o valor negativo se for o caso
//     });

//     // console.log("aui", resultBalance)
//     data.push([
//       "Resultado Balanço:",
//       "",
//       ...resultBalance,
//       formatarValor(Math.max(somarArray(totalgeraltrue) - somarArray(totalgeralfalse)))
//     ]);

//     autoTable(doc, {
//       head: [columns],
//       body: data,
//       styles: { fontSize: 8 },
//       margin: { top: 10, left: 10, right: 10, bottom: 10 },
//       pageBreak: 'auto',
//       startY: doc.autoTable.previous.finalY + 20
//     });

//     doc.save("tabela_financeira.pdf");
//   }; 


const gerarPDF = () => {
  const doc = new jsPDF('landscape');
  const margin = 10; // Define a margem entre os elementos

  doc.setFontSize(12);
  doc.text("Relatório de Plano de Contas", 10, 10);

  // Cabeçalhos da tabela
  const header = ["Grupo DRE", "Plano de Conta", ...meses, "Total Ano"];
  const columns = ["", "", ...meses, "Total Ano"];

  // Dados para Receitas e Despesas
  const receitas = [];
  const despesas = [];

  // Processamento de Receitas
  const totalgeraltrue = new Array(meses.length).fill(0);
  gruposFalse.forEach((grupo) => {
      if (grupo !== "") {
          receitas.push([{ content: grupo || "Não Agrupado", colSpan: header.length, styles: { halign: 'left', fillColor: "white", textColor: "blue" } }]);
          const filteredDados = dados.filter((dado) => {
              if (grupo === "" || grupo === null) {
                  return (!dado.GrupoDRE || dado.GrupoDRE === "") && !dado.Despesa;
              } else {
                  return dado.GrupoDRE === grupo && !dado.Despesa;
              }
          });

          let totalValues = new Array(meses.length).fill(0);
          filteredDados.forEach((dado) => {
              totalValues = new Array(meses.length).fill(0); // Zerando todos os campos
              dado.Meses.forEach((mes) => {
                  const value = mes.ValorTotalPago.ValorTotalPago || 0;
                  totalValues[mes.Mes - 1] += value;
                  totalgeraltrue[mes.Mes - 1] += value;
              });
              receitas.push([
                  "", dado.PlanoDeConta, ...totalValues.map(formatarValor), formatarValor(somarArray(totalValues))
              ]);
          });

          receitas.push([
              "", "Total", ...totalgeraltrue.map(formatarValor), formatarValor(somarArray(totalgeraltrue))
          ]);
      }
  });

  // Processamento de Despesas
  const totalgeralfalse = new Array(meses.length).fill(0);
  gruposTrue.forEach((grupo) => {
      if (grupo !== "") {
          despesas.push([{ content: grupo || "Não Agrupado", colSpan: header.length, styles: { halign: 'left', fillColor: "white", textColor: "red" } }]);
          const filteredDados = dados.filter((dado) => {
              if (grupo === "" || grupo === null) {
                  return (!dado.GrupoDRE || dado.GrupoDRE === "") && dado.Despesa;
              } else {
                  return dado.GrupoDRE === grupo && dado.Despesa;
              }
          });

          let totalValues = new Array(meses.length).fill(0); // Zerando todos os campos
          filteredDados.forEach((dado) => {
              totalValues = new Array(meses.length).fill(0);
              dado.Meses.forEach((mes) => {
                  const value = mes.ValorTotalPago.ValorTotalPago || 0;
                  totalValues[mes.Mes - 1] += value;
                  totalgeralfalse[mes.Mes - 1] += value;
              });
              despesas.push([
                  "", dado.PlanoDeConta, ...totalValues.map(formatarValor), formatarValor(somarArray(totalValues))
              ]);
          });

          despesas.push([
              "", "Total", ...totalgeralfalse.map(formatarValor), formatarValor(somarArray(totalgeralfalse))
          ]);
      }
  });

  // Cálculo do Resultado e Total Ano
  const resultado = meses.map((_, index) => formatarValor((totalgeraltrue[index] || 0) - (totalgeralfalse[index] || 0)));
  const totalAnoResultado = formatarValor(somarArray(totalgeraltrue) - somarArray(totalgeralfalse));

  let startY = 20; // Define a posição Y inicial para o primeiro elemento

  // Adiciona legenda "Receitas(+)" em azul antes da tabela de receitas
  doc.setTextColor(0, 0, 255); // Azul
  doc.text("Receitas(+)", 10, startY);
  startY += margin; // Atualiza a posição Y para o próximo elemento

  // Renderiza a tabela de receitas
  autoTable(doc, {
      head: [header],
      body: receitas,
      startY: startY,
      theme: 'grid',
      styles: { fontSize: 9 }
  });

  startY = doc.autoTable.previous.finalY + margin; // Atualiza a posição Y para o próximo elemento

  // Adiciona legenda "Despesas(-)" em vermelho antes da tabela de despesas
  doc.setTextColor(255, 0, 0); // Vermelho
  doc.text("Despesas(-)", 10, startY);
  startY += margin; // Atualiza a posição Y para o próximo elemento

  // Renderiza a tabela de despesas
  autoTable(doc, {
      head: [header],
      body: despesas,
      startY: startY,
      theme: 'grid',
      styles: { fontSize: 9 }
  });

  startY = doc.autoTable.previous.finalY + margin; // Atualiza a posição Y para o próximo elemento

  const data = [];
  const resultBalance = totalgeralfalse.map((value, index) => {
      const receita = totalgeraltrue[index] !== undefined ? totalgeraltrue[index] : 0; // Se não existir, usa 0
      const despesa = value || 0; // Se não existir, usa 0
      const balance = receita - despesa;
      return formatarValor(balance); // Exibe o valor negativo se for o caso
  });

  data.push([
      "Resultado Balanço:",
      "",
      ...resultBalance,
      formatarValor(somarArray(totalgeraltrue) - somarArray(totalgeralfalse))
  ]);

  // Renderiza a tabela de resultado do balanço
  autoTable(doc, {
      head: [columns],
      body: data,
      styles: { fontSize: 8 },
      margin: { top: 10, left: 10, right: 10, bottom: 10 },
      pageBreak: 'auto',
      startY: startY
  });

  // Salva o PDF
  doc.save("tabela_financeira.pdf");
};


  return (
    <>
      <Text mb={1} fontSize={{ base: 'sm', md: 'md', lg: 'lg' }} color={'blue'}>(+) Receitas</Text>
      <Table variant="striped" w='100%' size="sm" maxWidth="100%">
        {/* Tabela para grupos True */}
        <Thead>
          <Tr>
            <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Grupo DRE</Th>
            <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Plano de Conta</Th>
            {meses.map((mes, index) => (
              <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black" key={index}>{mes}</Th>
            ))}
            <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Total Ano</Th>
          </Tr>
        </Thead>
        <Tbody>
          {gruposFalse.map((grupo) => {
            // const filteredDados = dados.filter((dado) => dado.GrupoDRE === grupo && dado.Despesa);
            // const filteredDados = dados.filter((dado) => (dado.GrupoDRE === grupo || (!dado.GrupoDRE && grupo === "")) && !dado.Despesa);
            const filteredDados = dados.filter((dado) => {
              if (grupo === "" || grupo === null) {
                return (!dado.GrupoDRE || dado.GrupoDRE === "") && !dado.Despesa;
              } else {
                return dado.GrupoDRE === grupo && !dado.Despesa;
              }
            });
            const totalValues = meses.map(() => 0);

            return (
              grupo !== "" && (
                <React.Fragment key={grupo}>
                  <Tr>
                    <Td colSpan={15}>
                      <Text fontWeight="bold">{grupo || "Não Agrupado"}</Text>
                    </Td>
                  </Tr>
                  {filteredDados.map((dado) => {
                    const mesValues = dado.Meses.map((mes) => {
                      const value = mes.ValorTotalPago.ValorTotalPago || 0;
                      totalValues[mes.Mes - 1] += value;
                      if (totalgeraltrue[mes.Mes - 1]) {
                        totalgeraltrue[mes.Mes - 1] += value;
                      } else {
                        totalgeraltrue[mes.Mes - 1] = value;
                      }

                      // console.log(totalValues)
                      // console.log(totalgeraltrue)
                      return value;
                    });

                    return (
                      <Tr key={dado.PlanoDeConta}>
                        <Td></Td>
                        <Td>{dado.PlanoDeConta}</Td>
                        {mesValues.map((value, valueIndex) => (
                          <Td key={valueIndex}>{formatarValor(value)}</Td>
                        ))}
                        {/* <Td width="5%"></Td>
                        <Td width="35%">{dado.PlanoDeConta}</Td>
                        {mesValues.map((value, valueIndex) => (
                          <Td width="6%" key={valueIndex}>{formatarValor(value)}</Td>
                        ))} */}
                        <Td>{formatarValor(somarArray(mesValues))}</Td>
                      </Tr>
                    );
                  })}
                  <Tr>
                    <Td></Td>
                    <Td><Text fontWeight="bold">Total</Text></Td>
                    {totalValues.map((value, valueIndex) => (
                      <Td key={valueIndex}><Text fontWeight="bold">{formatarValor(value)}</Text></Td>
                    ))}
                    <Td><Text fontWeight="bold">{formatarValor(somarArray(totalValues))}</Text></Td>
                  </Tr>
                </React.Fragment>
              )
            );
          })}
        </Tbody>
        {/* </Table> */}
        <Text mt={4} mb={1} fontSize={{ base: 'sm', md: 'md', lg: 'lg' }} color={'red'}>(-) Despesa</Text>
        {/* <Table variant="striped" w='100%' size="sm" maxWidth="100%"> */}

        {/* Tabela para grupos False */}
        {/* <Thead>
          <Tr>
            <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Grupo DRE</Th>
            <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Plano de Conta</Th>
            {meses.map((mes, index) => (
              <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black" key={index}>{mes}</Th>
            ))}
          </Tr>
        </Thead> */}
        <Tbody>
          {gruposTrue.map((grupo) => {

            // const filteredDados = dados.filter((dado) => (dado.GrupoDRE === grupo || (!dado.GrupoDRE && grupo === "")) && dado.Despesa);
            // const filteredDados = dados.filter((dado) => (dado.GrupoDRE === grupo || (dado.GrupoDRE === null && grupo === "")) && dado.Despesa);
            const filteredDados = dados.filter((dado) => {
              if (grupo === "" || grupo === null) {
                return (!dado.GrupoDRE || dado.GrupoDRE === "") && dado.Despesa;
              } else {
                return dado.GrupoDRE === grupo && dado.Despesa;
              }
            });
            const totalValues = meses.map(() => 0);

            return (
              grupo !== "" && (
                <React.Fragment key={grupo}>
                  <Tr>
                    <Td colSpan={15}>
                      <Text fontWeight="bold">{grupo || "Não Agrupado"}</Text>
                    </Td>
                  </Tr>
                  {filteredDados.map((dado) => {
                    const mesValues = dado.Meses.map((mes) => {
                      const value = mes.ValorTotalPago.ValorTotalPago || 0;
                      totalValues[mes.Mes - 1] += value;
                      // totalgeralfalse[mes.Mes - 1] += value;
                      if (totalgeralfalse[mes.Mes - 1]) {
                        totalgeralfalse[mes.Mes - 1] += value;
                      } else {
                        totalgeralfalse[mes.Mes - 1] = value;
                      }

                      return value;
                    });

                    return (
                      <Tr key={dado.PlanoDeConta}>
                        {/* <Td></Td>
                        <Td>{dado.PlanoDeConta}</Td>
                        {mesValues.map((value, valueIndex) => (
                          <Td key={valueIndex}>{formatarValor(value)}</Td>
                        ))} */}
                        <Td width="5%"></Td>
                        <Td width="10%">{dado.PlanoDeConta}</Td>
                        {mesValues.map((value, valueIndex) => (
                          <Td width="7%" key={valueIndex}>{formatarValor(value)}</Td>
                        ))}
                        <Td>{formatarValor(somarArray(mesValues))}</Td>
                      </Tr>
                    );
                  })}
                  <Tr>
                    <Td></Td>
                    <Td><Text fontWeight="bold">Total</Text></Td>
                    {totalValues.map((value, valueIndex) => (
                      <Td key={valueIndex}><Text fontWeight="bold">{formatarValor(value)}</Text></Td>
                    ))}
                    <Td><Text fontWeight="bold">{formatarValor(somarArray(totalValues))}</Text></Td>
                  </Tr>
                </React.Fragment>
              )
            );
          })}
        </Tbody>
        <Text mt={3} fontWeight="bold" fontSize={{ base: 'sm', md: 'md', lg: 'lg' }} >Resultado Balanço:</Text>
        <Tbody>
          <Tr>
            <Td></Td>
            <Td><Text fontWeight="bold">Total</Text></Td>
            {/* {(() => {
              console.log(totalgeralfalse, totalgeraltrue)
              const elementosTd = [];
              for (let i = 0; i < totalgeralfalse.length; i++) {
                const diferencaSubtraida = totalgeraltrue[i] - totalgeralfalse[i];
                elementosTd.push(
                  <Td key={i}>
                    <Text fontWeight="bold">{formatarValor(diferencaSubtraida)}</Text>
                  </Td>
                );
              }
              return elementosTd;
            })()} */}
            {(() => {
              console.log(totalgeralfalse, totalgeraltrue);
              const elementosTd = [];

              for (let i = 0; i < totalgeralfalse.length; i++) {
                const receita = totalgeraltrue[i] !== undefined ? totalgeraltrue[i] : 0; // Usa 0 se não existir
                const despesa = totalgeralfalse[i] || 0; // Usa 0 se não existir
                const diferencaSubtraida = receita - despesa;

                elementosTd.push(
                  <Td key={i}>
                    <Text fontWeight="bold">{formatarValor(diferencaSubtraida)}</Text>
                  </Td>
                );
              }

              return elementosTd;
            })()}
            <Td><Text fontWeight="bold">{formatarValor(somarArray(totalgeraltrue) - somarArray(totalgeralfalse))}</Text></Td>
          </Tr>
        </Tbody>
      </Table>
      <Button onClick={gerarPDF} colorScheme="blue" mb={4}>
        Gerar PDF
      </Button>
    </>
  );
}

export default TabelaFinanceira;