// import React from "react";
// import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Box, Text, Button } from "@chakra-ui/react";
// import jsPDF from 'jspdf';
// import autoTable from "jspdf-autotable";


// function somarArray(array) {
//   var soma = 0;
//   for (var elemento of array) {
//     soma += elemento;
//   }
//   return soma;
// }

// function parseCurrency(value) {
//   return parseFloat(value.replace(/[^\d,-]/g, '').replace(',', '.'));
// }

// function agruparVendasPorUF(data) {
//   const agrupamento = {};

//   data.forEach((dado) => {
//     const uf = dado.UF || "Não Informado";
//     const municipio = dado.Municipio || "Não Informado";

//     if (!agrupamento[uf]) {
//       agrupamento[uf] = {};
//     }

//     if (!agrupamento[uf][municipio]) {
//       agrupamento[uf][municipio] = new Array(12).fill(0);
//     }

//     const vendaKey = Object.keys(dado).find(key => key.includes("VT. Venda"));
//     const mesKey = Object.keys(dado).find(key => key.includes("Data Pedido"));

//     const dataString = dado[mesKey];
//     const [dia, mes, ano] = dataString.split(" ")[0].split("/");
//     const dataFormatada = new Date(`${ano}-${mes}-${dia}`);

//     const mesIndex = dataFormatada.getMonth();
//     const vendaValue = vendaKey ? dado[vendaKey] : "R$ 0,00";

//     agrupamento[uf][municipio][mesIndex] += parseCurrency(vendaValue);
//   });

//   return agrupamento;
// }

// function TabelaVendas({ data }) {
//   const meses = [
//     "Janeiro",
//     "Fevereiro",
//     "Março",
//     "Abril",
//     "Maio",
//     "Junho",
//     "Julho",
//     "Agosto",
//     "Setembro",
//     "Outubro",
//     "Novembro",
//     "Dezembro"
//   ];

//   const formatarValor = (valor) => {
//     const formatter = new Intl.NumberFormat('pt-BR', {
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2
//     });

//     return formatter.format(valor);
//   };

//   const gerarPDF = () => {
//     const doc = new jsPDF('landscape');

//     const header = ["UF", "Municipio", ...meses, "Total Ano"];
//     const columns = ["", "", ...meses, "Total Ano"];

//     const agrupamento = agruparVendasPorUF(data);

//     const tabela = [];
//     const totaisGerais = new Array(12).fill(0);

//     Object.keys(agrupamento).forEach((uf) => {
//       tabela.push([{ content: uf, colSpan: header.length, styles: { halign: 'left', fillColor: "white", textColor: "blue" } }]);

//       Object.keys(agrupamento[uf]).forEach((municipio) => {
//         const valores = agrupamento[uf][municipio];
//         const totalAno = somarArray(valores);

//         tabela.push([
//           "", municipio, ...valores.map(formatarValor), formatarValor(totalAno)
//         ]);

//         valores.forEach((valor, index) => {
//           totaisGerais[index] += valor;
//         });
//       });

//       const totalUF = somarArray(Object.values(agrupamento[uf]).flat());
//       tabela.push([
//         "", "Total", ...totaisGerais.map(formatarValor), formatarValor(totalUF)
//       ]);
//     });

//     autoTable(doc, {
//       head: [header],
//       body: tabela,
//       theme: 'grid',
//       styles: { fontSize: 9 }
//     });

//     doc.save("vendas_por_uf.pdf");
//   };

//   const agrupamento = agruparVendasPorUF(data);

//   return (
//     <>
//       <Button onClick={gerarPDF} colorScheme="blue" mt={2} mb={4}>
//         Gerar PDF
//       </Button>
//       <Table variant="striped" w='100%' size="sm" maxWidth="100%">
//         <Thead>
//           <Tr>
//             <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">UF</Th>
//             <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Municipio</Th>
//             {meses.map((mes, index) => (
//               <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black" key={index}>{mes}</Th>
//             ))}
//             <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Total Ano</Th>
//           </Tr>
//         </Thead>
//         <Tbody>
//           {Object.keys(agrupamento).map((uf) => (
//             <React.Fragment key={uf}>
//               <Tr>
//                 <Td colSpan={15}>
//                   <Text fontWeight="bold">{uf}</Text>
//                 </Td>
//               </Tr>
//               {Object.keys(agrupamento[uf]).map((municipio) => {
//                 const valores = agrupamento[uf][municipio];
//                 const totalAno = somarArray(valores);

//                 return (
//                   <Tr key={municipio}>
//                     <Td></Td>
//                     <Td>{municipio}</Td>
//                     {valores.map((value, valueIndex) => (
//                       <Td key={valueIndex}>{formatarValor(value)}</Td>
//                     ))}
//                     <Td>{formatarValor(totalAno)}</Td>
//                   </Tr>
//                 );
//               })}
//               <Tr>
//                 <Td></Td>
//                 <Td><Text fontWeight="bold">Total</Text></Td>
//                 {Object.values(agrupamento[uf]).reduce((acc, valores) => {
//                   valores.forEach((valor, index) => {
//                     if (!acc[index]) {
//                       acc[index] = 0;
//                     }
//                     acc[index] += valor;
//                   });
//                   return acc;
//                 }, []).map((value, valueIndex) => (
//                   <Td key={valueIndex}><Text fontWeight="bold">{formatarValor(value)}</Text></Td>
//                 ))}
//                 <Td><Text fontWeight="bold">{formatarValor(somarArray(Object.values(agrupamento[uf]).flat()))}</Text></Td>
//               </Tr>
//             </React.Fragment>
//           ))}
//           <Tr>
//             <Td></Td>
//             <Td><Text fontWeight="bold">Total Geral</Text></Td>
//             {(() => {
//               const totaisGerais = new Array(12).fill(0);
//               Object.keys(agrupamento).forEach((uf) => {
//                 Object.values(agrupamento[uf]).forEach((valores) => {
//                   valores.forEach((valor, index) => {
//                     totaisGerais[index] += valor;
//                   });
//                 });
//               });
//               return totaisGerais.map((value, index) => (
//                 <Td key={index}><Text fontWeight="bold">{formatarValor(value)}</Text></Td>
//               ));
//             })()}
//             <Td><Text fontWeight="bold">{formatarValor(somarArray(Object.values(agrupamento).reduce((acc, municipio) => {
//               return acc.concat(Object.values(municipio).flat());
//             }, [])))}</Text></Td>
//           </Tr>
//         </Tbody>
//       </Table>
//     </>
//   );
// }

// export default TabelaVendas;

import React from "react";
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Box, Text, Button } from "@chakra-ui/react";
import jsPDF from 'jspdf';
import autoTable from "jspdf-autotable";

// Função para somar valores em um array
function somarArray(array) {
  let soma = 0;
  for (let elemento of array) {
    soma += elemento;
  }
  return soma;
}

// Função para converter valor de moeda em número
function parseCurrency(value) {
  return parseFloat(value.replace(/[^\d,-]/g, '').replace(',', '.'));
}

// Função para agrupar vendas por UF e Município
function agruparVendasPorUF(data) {
  const agrupamento = {};

  data.forEach((dado) => {
    const uf = dado.UF || "Não Informado";
    const municipio = dado.Municipio || "Não Informado";

    if (!agrupamento[uf]) {
      agrupamento[uf] = {};
    }

    if (!agrupamento[uf][municipio]) {
      agrupamento[uf][municipio] = new Array(12).fill(0);
    }

    const vendaKey = Object.keys(dado).find(key => key.includes("VT. Venda"));
    const mesKey = Object.keys(dado).find(key => key.includes("Data Pedido"));

    const dataString = dado[mesKey];
    const [dia, mes, ano] = dataString.split(" ")[0].split("/");
    const dataFormatada = new Date(`${ano}-${mes}-${dia}`);

    const mesIndex = dataFormatada.getMonth();
    const vendaValue = vendaKey ? dado[vendaKey] : "R$ 0,00";

    agrupamento[uf][municipio][mesIndex] += parseCurrency(vendaValue);
  });

  return agrupamento;
}

// Função para ordenar UFs e Municípios
function ordenarPorNome(objeto) {
  return Object.keys(objeto).sort((a, b) => {
    if (a === "Não Informado") return 1;
    if (b === "Não Informado") return -1;
    return a.localeCompare(b, 'pt', { sensitivity: 'base' });
  });
}

function TabelaVendas({ data }) {
  const meses = [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
    "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ];

  // Função para formatar valores monetários
  const formatarValor = (valor) => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return formatter.format(valor);
  };

  // Função para gerar o PDF
  const gerarPDF = () => {
    const doc = new jsPDF('landscape');

    const header = ["UF", "Municipio", ...meses, "Total Ano"];
    const columns = ["", "", ...meses, "Total Ano"];

    const agrupamento = agruparVendasPorUF(data);

    const tabela = [];
    const totaisGerais = new Array(12).fill(0);

    ordenarPorNome(agrupamento).forEach((uf) => {
      tabela.push([{ content: uf, colSpan: header.length, styles: { halign: 'left', fillColor: "white", textColor: "blue" } }]);

      ordenarPorNome(agrupamento[uf]).forEach((municipio) => {
        const valores = agrupamento[uf][municipio];
        const totalAno = somarArray(valores);

        tabela.push([
          "", municipio, ...valores.map(formatarValor), formatarValor(totalAno)
        ]);

        valores.forEach((valor, index) => {
          totaisGerais[index] += valor;
        });
      });

      const totalUF = somarArray(Object.values(agrupamento[uf]).flat());
      tabela.push([
        "", "Total", ...totaisGerais.map(formatarValor), formatarValor(totalUF)
      ]);
    });

    autoTable(doc, {
      head: [header],
      body: tabela,
      theme: 'grid',
      styles: { fontSize: 9 }
    });

    doc.save("vendas_por_uf.pdf");
  };

  const agrupamento = agruparVendasPorUF(data);

  return (
    <>
      <Button onClick={gerarPDF} colorScheme="blue" mt={2} mb={4}>
        Gerar PDF
      </Button>
      <Table variant="striped" w='100%' size="sm" maxWidth="100%">
        <Thead>
          <Tr>
            <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">UF</Th>
            <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Municipio</Th>
            {meses.map((mes, index) => (
              <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black" key={index}>{mes}</Th>
            ))}
            <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Total Ano</Th>
          </Tr>
        </Thead>
        <Tbody>
          {ordenarPorNome(agrupamento).map((uf) => (
            <React.Fragment key={uf}>
              <Tr>
                <Td colSpan={15}>
                  <Text fontWeight="bold">{uf}</Text>
                </Td>
              </Tr>
              {ordenarPorNome(agrupamento[uf]).map((municipio) => {
                const valores = agrupamento[uf][municipio];
                const totalAno = somarArray(valores);

                return (
                  <Tr key={municipio}>
                    <Td></Td>
                    <Td>{municipio}</Td>
                    {valores.map((value, valueIndex) => (
                      <Td key={valueIndex}>{formatarValor(value)}</Td>
                    ))}
                    <Td>{formatarValor(totalAno)}</Td>
                  </Tr>
                );
              })}
              <Tr>
                <Td></Td>
                <Td><Text fontWeight="bold">Total</Text></Td>
                {Object.values(agrupamento[uf]).reduce((acc, valores) => {
                  valores.forEach((valor, index) => {
                    if (!acc[index]) {
                      acc[index] = 0;
                    }
                    acc[index] += valor;
                  });
                  return acc;
                }, []).map((value, valueIndex) => (
                  <Td key={valueIndex}><Text fontWeight="bold">{formatarValor(value)}</Text></Td>
                ))}
                <Td><Text fontWeight="bold">{formatarValor(somarArray(Object.values(agrupamento[uf]).flat()))}</Text></Td>
              </Tr>
            </React.Fragment>
          ))}
          <Tr>
            <Td></Td>
            <Td><Text fontWeight="bold">Total Geral</Text></Td>
            {(() => {
              const totaisGerais = new Array(12).fill(0);
              Object.keys(agrupamento).forEach((uf) => {
                Object.values(agrupamento[uf]).forEach((valores) => {
                  valores.forEach((valor, index) => {
                    totaisGerais[index] += valor;
                  });
                });
              });
              return totaisGerais.map((value, index) => (
                <Td key={index}><Text fontWeight="bold">{formatarValor(value)}</Text></Td>
              ));
            })()}
            <Td><Text fontWeight="bold">{formatarValor(somarArray(Object.values(agrupamento).reduce((acc, municipio) => {
              return acc.concat(Object.values(municipio).flat());
            }, [])))}</Text></Td>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
}

export default TabelaVendas;
