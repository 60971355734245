import api from 'contexts/api';
import { Box, FormLabel, FormControl, Input, Icon, Select, SimpleGrid, Button, Text, Spinner } from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon } from '@chakra-ui/icons';
import { HiOutlineShoppingCart } from "react-icons/hi";
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import Pagination from 'views/admin/AnaliticoProduto/components/paginate';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as XLSX from 'xlsx/xlsx.mjs';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import DataDisplay from './components/table';
import ModalComponent from './components/modal';


function removeObjectsByKey(objects, keysToRemove) {
  return objects.map(obj => {
    const newObj = {};
    for (let key in obj) {
      if (!keysToRemove.includes(key)) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  });
}


function calcularPorcentagemDiferenca(valorAntigo, valorNovo) {
  const diferenca = valorAntigo - valorNovo;
  const porcentagem = (diferenca / valorAntigo) * 100;

  // Verifica se a porcentagem possui casas decimais
  if (porcentagem % 1 !== 0) {
    return porcentagem.toFixed(2);
  } else {
    return porcentagem.toFixed(0);
  }
}


function generatePDF(excel, total, filtros) {

  // console.log(filtros)
  let tableFiltro = transformaFiltros([filtros]);
  let result = [];
  
  for (let i = 0; i < tableFiltro.length; i++) {
    const rowEntries = Object.entries(tableFiltro[i]);
    const filtrosConvertidos = rowEntries.reduce((acc, [key, value]) => {
      let convertedValue = value;
      if (typeof value === 'object' && value !== null && value.props && value.props.children) {
        convertedValue = value.props.children.toString(); // Converte o objeto em uma string
      }
      acc.push({ [key]: convertedValue });
      return acc;
    }, []);
    result.push(filtrosConvertidos);
  }

  const doc = new jsPDF('landscape');
  let tableData = [];

  const data = excel;
  const headers = Object.keys(data[0]).slice(0, 12);
  const headers_total = Object.values(total).slice(0, 12).map(obj => Object.values(obj)[0]);

  for (let i = 0; i < data.length; i++) {
    const rowValues = Object.values(data[i]).slice(0, 12);
    const convertedRowValues = rowValues.map((value) => {
      if (typeof value === 'object' && value !== null && value.props && value.props.children) {
        return value.props.children.toString(); // Converte o objeto em uma string
      }
      return value;
    });
    tableData.push(convertedRowValues);
  }


  doc.setFontSize(18);
  doc.text('Relatório faturamento por cliente', 15, 15);

  doc.setFontSize(10);
  doc.text('Filtros:', 15, 25);

  const filters = result[0]; // Obtém os filtros do resultado

  if (filters) {
    let currentX = 35; // Posição X inicial para os valores dos filtros
    const y = 25; // Posição Y para a primeira linha dos filtros
  
    const mergedFilters = filters.reduce((acc, filter) => {
      const [label, value] = Object.entries(filter)[0]; // Obtém a legenda e o valor do filtro
      return `${acc}${label} ${value}, `; // Concatena a legenda e o valor com vírgula
    }, '');
  
    // Remove a vírgula extra no final
    const mergedFiltersText = mergedFilters.slice(0, -2);
  
    // Define o tamanho da fonte para os filtros
    doc.setFontSize(8);
  
    // Verifica se o texto dos filtros cabe em uma linha
    const textWidth = doc.getStringUnitWidth(mergedFiltersText) * doc.internal.getFontSize();
    const maxWidth = doc.internal.pageSize.getWidth() - currentX - 10; // Largura máxima disponível
    let filteredText = mergedFiltersText;
  
    if (textWidth > maxWidth) {
      // O texto excede a largura máxima, então é necessário quebrar em várias linhas
      const lines = doc.splitTextToSize(mergedFiltersText, maxWidth);
      filteredText = lines.join('\n'); // Concatena as linhas com quebras de linha
    }
  
    // Adiciona o texto dos filtros no documento PDF
    doc.text(filteredText, currentX, y);
  }

  // Define a margem superior da tabela
  const tableMarginTop = 35;

  // Adicionar cabeçalhos
  doc.autoTable({
    head: [headers_total, headers], // Adicione os cabeçalhos em ordem
    body: tableData,
    startY: tableMarginTop, // Ajuste a posição vertical para evitar sobreposição com o título e a legenda
    columnWidth: 'auto',
    styles: {
      fontSize: 6,
      cellStyles: {
        overflow: 'linebreak', // Quebra de linha automática para células
      },
    },
  });

  doc.save('faturamento_cliente.pdf');
}


function calcularTotalizadores(array) {
  const totalizadores = {};


  array.forEach((obj) => {
    for (const key in obj) {
      const valor = obj[key];

      if (typeof valor === 'string' && valor.includes('R$')) {
        const valorNumerico = parseFloat(valor.replace(/[^\d,-]+/g, '').replace(',', '.'));
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else if (key === 'Qtd. Vendida') {
        const valorNumerico = parseFloat(valor);
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else if (key === '% Total') {
        totalizadores[key] = '100%'
      } else {
        if (!totalizadores[key]) {
          totalizadores[key] = '';
        }
      }
    }
  });

  const resultado = [];

  for (const key in totalizadores) {
    let value = totalizadores[key];
    if ((key !== 'Qtd. Utilizadas') && (key !== '% Do total')) {
      value = totalizadores[key] !== '' ? `${formatarValor(totalizadores[key])}` : '';
    }
    resultado.push({
      [`Total ${key}`]: value,
    });
  }

  return resultado;
}

const formatDate = (date) => {
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  if (month.length === 1) {
    month = '0' + month;
  }
  let day = date.getDate().toString();
  if (day.length === 1) {
    day = '0' + day;
  }
  return `${year}-${month}-${day}`;
};

function formatarDataHora(valor) {
  if (valor === null || valor === undefined) {
    return "Sem Data";
  }

  const data = new Date(valor);
  if (isNaN(data)) {
    return "Sem Data";
  }

  const dia = ("0" + data.getDate()).slice(-2);
  const mes = ("0" + (data.getMonth() + 1)).slice(-2);
  const ano = data.getFullYear();
  const horas = ("0" + data.getHours()).slice(-2);
  const minutos = ("0" + data.getMinutes()).slice(-2);

  return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
}


function transformaFiltros(data) {
  return data.map((item) => {
    const filtro = {};

    if (item.origem) {
      filtro['Origem Venda: '] = item.origem;
    }

    if (item.empresa) {
      filtro['Empresa: '] = item.empresa;
    }

    if (item.tipoData) {
      filtro['Tipo Data: '] = item.tipoData;
    }

    if (item.cliente) {
      filtro['Cliente: '] = item.cliente;
    }

    if (item.grupoPessoa) {
      filtro['Grupo do Cliente: '] = item.grupoPessoa;
    }

    if (item.inicio) {
      filtro['Data Inicio: '] = formatarDataHora(item.inicio);
    }

    if (item.fim) {
      filtro['Data Final: '] = formatarDataHora(item.fim);
    }

    return filtro;
  });
}



function generateTableData(analitico, dataAtual) {
  // console.log(analitico)
  if (analitico.length === 0) {
    const keys = [
      "Razao Social",
      "Nome Fantasia",
      "Whatsapp",
      "Celular",
      "Telefone",
      "Email",
      "Valor Total",
      "% Total",
      "Ultima Compra",
      "Media de Atraso"
    ];

    const emptyObject = {};
    keys.forEach(key => {
      emptyObject[key] = '';
    });

    return [emptyObject];
  }

  const total = analitico.reduce((acc, item) => acc + item.ValorTotal, 0);
  // const [anoAtual, mesAtual, diaAtual] = dataAtual.split('-').map(Number);
  return analitico.map((item) => ({
    "Razao Social": item.RazaoSocial,
    "Nome Fantasia": item.NomeFantasia,
    "Whatsapp": item.Celular,
    "Celular": item.Celular,
    "Telefone": item.Telefone,
    "Email": item.Email,
    "Valor Total": formatarValor(item.ValorTotal),
    "% Total" : calcularPorcentagem(total, item.ValorTotal),
    "Ultima Compra" : formatarDataHora(item.Data),
    "Media de Atraso" : item.MediaAtraso? item.MediaAtraso : 0
    // "% Total": (item.ValorTotal),
  }));
}


function gerarExcel(dados, objeto) {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(dados);

  // Extrair os valores do objeto
  const objetoValores = Object.values(objeto).map(item => Object.values(item)[0]);

  // Obter a matriz existente dos dados
  const matrizDados = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  // Mesclar as matrizes (inserir a nova matriz acima da matriz existente)
  const matrizFinal = [objetoValores, ...matrizDados];

  // Converter a matriz final em uma planilha
  const novaWorksheet = XLSX.utils.aoa_to_sheet(matrizFinal);

  // Adicionar a nova planilha ao workbook
  XLSX.utils.book_append_sheet(workbook, novaWorksheet, 'Sheet 1');

  XLSX.writeFile(workbook, 'faturamento_cliente.xlsx');
}

function calcularPorcentagem(objeto, numero) {
  // Verificar se o índice existe no objeto
  // console.log(objeto)
  if (objeto['Total Valor Total']) {
    // Extrair o valor do objeto e remover o símbolo "R$" e espaços em branco
    const valorTotal = objeto['Total Valor Total'].replace(/R\$\s/g, '');

    // Converter o valor para número
    const valorNumerico = parseFloat(valorTotal);

    // Calcular a porcentagem
    const porcentagem = (numero / valorNumerico) * 100;

    // Retornar a porcentagem formatada
    return porcentagem.toFixed(2) + '%';
  } else {

    const valorNumerico = parseFloat(objeto);
    // Caso o índice não exista, retornar o valor normal do objeto
    const porcentagem = (numero / valorNumerico) * 100;

    // Retornar a porcentagem formatada
    return porcentagem.toFixed(2) + '%';
  }
}

function formatarValor(valor) {
  if (valor === null || valor === undefined) {
    return "R$ 00,00";
  }

  const valorFormatado = valor.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return valorFormatado;
}


export default function UserReports() {
  const [analitico, setAnalitico] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [status, setStatus] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [origem, setOrigens] = useState('');
  const [cliente, setCliente] = useState('');
  const [grupoPessoa, setGrupoPessoa ] = useState('');

  const [categVen, setCategVenda] = useState('');
  const [deposito, setDeposito] = useState('');
  const [vendedor, setVendedor] = useState('');
  const [tabela, setTabela] = useState('');
  const [diaAtual, setDia] = useState('');
  const [filtros, setFiltros] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [inicio, setInicio] = useState('');
  const [fim, setFim] = useState('');
  const [TipoData, setTipoData] = useState('Data');
  const [generoProd, setGeneroProd] = useState("00 – Mercadoria para Revenda");
  const history = useHistory();
  const [token, setToken] = useState('');
  const [dados, setDados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState('');
  const [excel, setExcel] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [camposTotal, setCampos] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleValuesSelected = (values) => {
    setSelectedValues(values);
  };

  const handleClickExcel = () => {
    gerarExcel(excel, total);
  };

  const handleClickPdf = () => {
    generatePDF(excel, total, filtros);
  };

  async function index(data) {

    const dados_formatados = await generateTableData(data, diaAtual)
    setCampos(dados_formatados)
    const objFinal = removeObjectsByKey(dados_formatados, selectedValues)
    // console.log(dados_formatados)
    const totais = calcularTotalizadores(objFinal)
    // console.log("dados formatados")
    // console.log(objFinal, totais)
    setExcel(objFinal)
    setTotal(totais)

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToShow = objFinal.slice(startIndex, endIndex);
    // console.log(itemsToShow);
    setAnalitico(itemsToShow)
  }

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem('@storage_Key')
      if (value !== null) {
        return value;// value previously stored
      }
    } catch (e) {
      // error reading value
    }
  }

  useEffect(async () => {
    const param = {};
    const key = await getData();

    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const formattedFirstDay = formatDate(firstDayOfMonth);
    const formattedLastDay = formatDate(lastDayOfMonth);

    setInicio(formattedFirstDay);
    setFim(formattedLastDay);

    if (typeof key !== 'undefined' && key !== null) {
      param.user = key;
      setFiltros(param);

      const fetchData = async () => {
        setIsLoading(true);

        try {
          const [filtrosData, dadosData] = await Promise.all([
            api.get('/vendas_filters', { params: param, timeout: 500000 }),
            api.get('/fatur_cliente', { params: param, timeout: 5000000 }),
          ]);

          const filtros = filtrosData.data;
          const dados = dadosData.data;

          setToken(key);
          setDados(dados);
          setTotalItems(dados.length);

          // const select_origens = [...new Set(filtros[0].map(item => item.origemVenda))];
          // const select_empresas = [...new Set(filtros[1].map(item => item.NomeFantasia))];
          // const select_categ = [...new Set(filtros[2].map(item => item.VendaCategoria))];
          // const selectGrupoCliente = [...new Set(filtros[3].map(item => item.Nome))];

          const select_origens = [...new Set(filtros[0].map(item => item.origemVenda).filter(value => value !== undefined))];
          const select_empresas = [...new Set(filtros[1].map(item => item.NomeFantasia).filter(value => value !== undefined))];
          const select_categ = [...new Set(filtros[2].map(item => item.VendaCategoria).filter(value => value !== undefined))];
          const selectGrupoCliente = [...new Set(filtros[3].map(item => item.Nome).filter(value => value !== undefined))];


          setDia(filtros[3]);

          const selectEmpresas = document.getElementById("select-empresas");
          select_empresas.forEach(empresa => {
            const option = document.createElement("option");
            option.value = empresa;
            option.text = empresa;
            selectEmpresas.appendChild(option);
          });

          const selectOrigens = document.getElementById("select-origens");
          select_origens.forEach(origens => {
            const option = document.createElement("option");
            option.value = origens;
            option.text = origens;
            selectOrigens.appendChild(option);
          });

          const selectCateg = document.getElementById("select-categoria-venda");
          select_categ.forEach(categVenda => {
            const option = document.createElement("option");
            option.value = categVenda;
            option.text = categVenda;
            selectCateg.appendChild(option);
          });

          const selectGrupo = document.getElementById("select-grupo-pessoa");
          selectGrupoCliente.forEach(categCleinte => {
            const option = document.createElement("option");
            option.value = categCleinte;
            option.text = categCleinte;
            selectGrupo.appendChild(option);
          });

          if (currentPage === 1) {
            await index(dados);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else {
      history.push('/');
    }
  }, []);

  //executa a paginação na alteração de seus estados
  useEffect(() => {
    index(dados);
  }, [currentPage, itemsPerPage, selectedValues]);


  //aplica os filtros
  const handleFilter = async () => {
    const data = {}

    if ((typeof token !== 'undefined') && token !== null) {
      data.user = token

      if ((status !== '') && status !== null) {
        data.status = status
      }

      if ((empresa !== '') && empresa !== null) {
        data.empresa = empresa
      }

      if ((origem !== '') && origem !== null) {
        data.origem = origem
      }
      
      if ((TipoData !== '') && TipoData !== null) {
        data.TipoData = TipoData
      }

      if ((categVen !== '') && categVen !== null) {
        data.categVen = categVen
      }

      if ((grupoPessoa !== '') && grupoPessoa !== null) {
        data.grupoPessoa = grupoPessoa
      }

      if ((cliente !== '') && cliente !== null) {
        data.cliente = cliente
      }

      if (inicio !== null && inicio !== '') {
        if (fim !== null && fim !== '') {
          data.fim = fim
          data.inicio = inicio
        } else {
          alert("preencher os dois periodos");
        }
      }

      // console.log(data);
      setFiltros(data)

      if (Object.keys(data).length !== 0) {
        setCurrentPage(1)
        setItemsPerPage(20)
      }

      try {
        setIsLoading(true);
        api.get('/fatur_cliente', { params: data, timeout: 1000000 })
          .then(response => {
            // console.log(response.data)

            if (response.data.length !== 0) {
              // console.log(response.data[0])
              setDados(response.data);
              setTotalItems(response.data.length);
            } else {
              setDados(response.data);
              handlePageChange(1)
              setTotalItems(20);
            }

            if (currentPage === 1) {
              index(response.data);
            }
          })
          .catch(error => {
            console.error(error);
          }).finally(() => {
            setIsLoading(false)
          });;

        // console.log(data[0].length)
      } catch (error) {
        console.error(error);
      }
    } else {
      history.push('/');
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // handleSubmit(); // Atualiza os dados ao alterar a página
  };


  const handleItemsPerPageChange = (newItens) => {
    setItemsPerPage(newItens);
    setCurrentPage(1); // Reset currentPage when changing items per page
    // handleSubmit();
  };


  return (
    <Box mt={{ sm: '140px', md: '12%', lg: '15%', xl: '6%' }}>
      {isLoading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="rgba(0, 0, 0, 0.5)" // Define a cor de fundo como transparente com um leve tom escuro (50% de opacidade)
          zIndex="9999"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            color="blue.500"
            size="xl"
          />
        </Box>
      )}
      <SimpleGrid border="1px solid black" columns={[1, 2, 3, 4]} spacing={5} mb={5} ml={2} bg="white" rounded="md" p={4}>
        <Box maxW="md">
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Origem da Venda:</FormLabel>
            <Select id="select-origens" onChange={(e) => setOrigens(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Empresas:</FormLabel>
            <Select id="select-empresas" onChange={(e) => setEmpresa(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Status da Venda:</FormLabel>
            <Select onChange={(e) => setStatus(e.target.value)}>
              <option value="Pedido Faturado">Pedido Faturado</option>
              <option value="all">Todos</option>
              <option value="Pedido">Pedido</option>
              <option value="Orçamento">Orçamento</option>
              <option value="Pedido Nao Faturado">Pedido não Faturado</option>
              <option value="Pedido Aprovado Sem Faturamento">Pedido Aprovado Sem Faturamento</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Categoria da Venda:</FormLabel>
            <Select id="select-categoria-venda" onChange={(e) => setCategVenda(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Cliente:</FormLabel>
            <Input type="input" id="cliente" onChange={(e) => setCliente(e.target.value)} />
          </FormControl>
        </Box>
        <Box maxW="md">
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Grupo do CLiente:</FormLabel>
            <Select id="select-grupo-pessoa" onChange={(e) => setGrupoPessoa(e.target.value)}>
              <option value="">Todos</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Tipo Data:</FormLabel>
            <Select id="select-data" onChange={(e) => setTipoData(e.target.value)}>
              <option value="Data">Data da Venda</option>
              <option value="DataAprovacao">Data Aprovação</option>
              <option value="DataFaturamento">Data Faturamento</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Início do Período:</FormLabel>
            <Input type="date" id="inicio" value={inicio} onChange={(e) => setInicio(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Fim do Período:</FormLabel>
            <Input type="date" id="Fim" value={fim} onChange={(e) => setFim(e.target.value)} />
          </FormControl>
        </Box>
        <Box mt="auto">
          <Button colorScheme="blue" onClick={handleFilter}>Enviar</Button>
        </Box>
      </SimpleGrid>

      {/* <SimpleGrid mt='1%'>
        <Button size="sm" w="10%" onClick={handleOpenModal}>Selecionar Campos</Button>
        <ModalComponent
          data={camposTotal}
          onValuesSelected={handleValuesSelected}
          isOpen={isOpen}
          onClose={handleCloseModal}
          selectedValues={selectedValues}
        />
        <Box mt='1%'>
          <Text fontWeight="bold">Valores Retirados: {selectedValues.length > 0 ? selectedValues.join(', ') : 'Nenhum valor selecionado'}</Text>
        </Box>
      </SimpleGrid> */}

      <SimpleGrid mt='2%'>
        <TableContainer w='96%' ml='2%' overflowX="auto" >
          {analitico && total && (
            <DataDisplay data={analitico} total={total} />
          )}
        </TableContainer>
      </SimpleGrid>
      <Box display='flex'>
        <Pagination
          totalItems={totalItems}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          onLimitChange={handleItemsPerPageChange}
        />
        <Button mt={4} colorScheme="blue" mb="5%" mr={1} size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickExcel}>Excel</Button>
        <Button mt={4} colorScheme="blue" mb="5%" size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickPdf}>PDF</Button>
      </Box>
    </Box>
  );
}
