import React from 'react';
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Box } from "@chakra-ui/react";
const DataDisplay = ({ data, total }) => {

    console.log(data, total)

    return (
        <Table id="teste" variant="striped" w='98%' size="sm" maxWidth="100%">
            <Thead>
                <Tr>
                    {total.some(item => Object.values(item).some(value => value !== '' || value !== 'R$ NaN')) ? (
                        total.map((item, index) => (
                            index === 0 ? (
                                <Td key={index} fontWeight="bold">Totais:</Td>
                            ) : (
                                <Td key={index} textAlign="center">{Object.values(item)[0]}</Td>
                            )
                        ))
                    ) : (
                        <Td colSpan={total.length > 0 ? total.length : 1} textAlign="center" fontWeight="bold">Totais:</Td>
                    )}
                </Tr>
                {data.length > 0 ? (
                    <Tr>
                        {Object.keys(data[0]).map((key) => (
                            // Restante do seu código
                            <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black" key={key}>{key}</Th>
                        ))}
                    </Tr>
                ) : ''}
            </Thead>
            <Tbody>
                {/* {data.map((item, index) => (
                    <Tr key={index}>
                        {Object.values(item).map((value, index) => (
                            <Td textAlign="center" key={index}>{value}</Td>
                        ))}
                    </Tr>
                ))} */}
                {data.length > 0 ? (
                    data.some(item => Object.values(item).some(value => value !== '')) ? (
                        data.map((item, index) => (
                            <Tr key={index}>
                                {Object.values(item).map((value, index) => (
                                    <Td textAlign="center" key={index}>{value}</Td>
                                ))}
                            </Tr>
                        ))
                    ) : (
                        <Tr>
                            <Td colSpan={Object.keys(data[0]).length} textAlign="center">
                                Nenhum registro foi encontrado!
                            </Td>
                        </Tr>
                    )
                ) : (
                    <Tr>
                        <Td colSpan={1} textAlign="center">
                            Nenhum registro foi encontrado!
                        </Td>
                    </Tr>
                )}
            </Tbody>
        </Table>
        // <Box></Box>
    );
};

export default DataDisplay;