// import React, { useEffect, useState } from 'react';
// import { Box, Button, Table, Tbody, Td, Th, Thead, Tr, VStack } from '@chakra-ui/react';
// import axios from 'axios';
// import api from 'contexts/api';
// import { useHistory } from 'react-router-dom'; // Importe o hook useHistory


// const Index = () => {
//     const [users, setUsers] = useState([]);
//     const history = useHistory(); // Inicialize o useHistor

//     useEffect(() => {
//         fetchUsers();
//     }, []);

//     const fetchUsers = async () => {
//         try {
//             const response = await api.get('/users'); // Substitua pela rota correta para buscar os usuários
//             console.log(response.data)
//             setUsers(response.data.users);
//         } catch (error) {
//             console.error(error);
//         }
//     };

//     const deleteUser = async (userId) => {
//         try {
//             await axios.delete(`/api/users/${userId}`); // Substitua pela rota correta para excluir o usuário
//             fetchUsers();
//         } catch (error) {
//             console.error(error);
//         }
//     };

//     const editUser = (userId) => {
//         history.push(`/edit`); // Redireciona para a página de edição com o user_id na rota/${userId}
//       };
    

//     return (
//         <Box mt={{ sm: '140px', md: '12%', lg: '15%', xl: '6%' }}>
//             <VStack spacing={4} align="stretch">
//                 <Button colorScheme="blue" onClick={fetchUsers}>Atualizar</Button>
//                 <Table>
//                     <Thead>
//                         <Tr>
//                             <Th>Nome</Th>
//                             <Th>Email</Th>
//                             <Th>Vendedor</Th>
//                             <Th>Ações</Th>
//                         </Tr>
//                     </Thead>
//                     <Tbody>
//                         {users.map((user) => (
//                             <Tr key={user.user_id}>
//                                 <Td>{user.name}</Td>
//                                 <Td>{user.email}</Td>
//                                 <Td>{user.vendedor}</Td>
//                                 <Td>
//                                     <Button colorScheme="red" size="sm" onClick={() => deleteUser(user.user_id)}>Excluir</Button>
//                                     <Button colorScheme="blue" size="sm" onClick={() => editUser(user.user_id)}>Editar</Button>
//                                     {/* Adicione aqui o botão de editar que redireciona para a página de edição */}
//                                 </Td>
//                             </Tr>
//                         ))}
//                     </Tbody>
//                 </Table>
//                 {/* Adicione aqui o botão de adicionar que redireciona para a página de criação */}
//             </VStack>
//         </Box>
//     );
// };

// export default Index;



import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import axios from 'axios';
import api from 'contexts/api';

import Form from './form.jsx'; // Importe o componente Form corretamente

const Index = () => {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // Alterado para armazenar o usuário selecionado

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await api.get('/users');
      console.log(response)
      setUsers(response.data.users);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteUser = async (userId) => {
    try {
      await api.delete(`/users/${userId}`);
      fetchUsers();
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = (user = null) => {
    setSelectedUser(user); // Define o usuário selecionado
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null); // Reseta o usuário selecionado
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    fetchUsers();
    closeModal();
  };

  return (
    <Box mt={{ sm: '140px', md: '12%', lg: '15%', xl: '6%' }}>
      <VStack spacing={4} align="stretch">
        <Table variant="striped" w='98%' size="sm" maxWidth="100%">
          <Thead>
            <Tr>
              <Th>Nome</Th>
              <Th>Email</Th>
              <Th>Vendedor</Th>
              <Th>banco</Th>
              <Th>Perfil</Th>
              <Th>Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user) => (
              <Tr key={user.user_id}>
                <Td>{user.name}</Td>
                <Td>{user.email}</Td>
                <Td>{user.vendedor}</Td>
                <Td>{user.banco}</Td>
                <Td>{user.perfil}</Td>
                <Td>
                  {/* <Button colorScheme="red" size="sm" onClick={() => deleteUser(user.user_id)}>
                    Excluir
                  </Button> */}
                  <Button colorScheme="blue" ml={1} size="sm" onClick={() => openModal(user)}>
                    Editar
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Button w={'5%'} size="sm" fontSize={{ sm: "10px" }} colorScheme="blue" onClick={() => openModal()}>
          Incluir
        </Button>
      </VStack>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedUser ? 'Editar Usuário' : 'Incluir Usuário'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form user={selectedUser} onSubmit={handleSubmit} /> {/* Passa o usuário selecionado para o componente Form */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Index;

