import api from 'contexts/api';
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  FormControl, Input,
  Icon,
  Select,
  SimpleGrid,
  Grid,
  useColorModeValue,
  Button,
  Text,
  Spinner,
  Table, Thead, Tbody, Tr, Th, Td, TableCaption
} from "@chakra-ui/react";
import { CalendarIcon } from '@chakra-ui/icons';
import { HiOutlineShoppingCart } from "react-icons/hi";

import IconBox from "components/icons/IconBox";
import React, { useState, useEffect, useCallback } from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import { FaMoneyBillAlt } from "react-icons/fa";
import Diario from "views/admin/Financeiro/components/ChartDiario";
import Mensal from "views/admin/Financeiro/components/ChartMensal"
import { useHistory } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';


// import Vendedor from "views/admin/default/components/vendedores";

function formatarPeriodo(inicio, fim) {
  const dataInicio = new Date(inicio);
  const dataFim = new Date(fim);

  const diaInicio = dataInicio.getUTCDate().toString().padStart(2, '0');
  const mesInicio = (dataInicio.getUTCMonth() + 1).toString().padStart(2, '0');
  const anoInicio = dataInicio.getUTCFullYear().toString();

  const diaFim = dataFim.getUTCDate().toString().padStart(2, '0');
  const mesFim = (dataFim.getUTCMonth() + 1).toString().padStart(2, '0');
  const anoFim = dataFim.getUTCFullYear().toString();

  const inicioFormatado = `${diaInicio}/${mesInicio}/${anoInicio}`;
  const fimFormatado = `${diaFim}/${mesFim}/${anoFim}`;

  return `Período: ${inicioFormatado} - ${fimFormatado}`;
}

const getData = async () => {
  try {
    const value = await AsyncStorage.getItem('@storage_Key')
    if (value !== null) {
      return value;// value previously stored
    }
  } catch (e) {
    // error reading value
  }
}

const formatDate = (date) => {
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  if (month.length === 1) {
      month = '0' + month;
  }
  let day = date.getDate().toString();
  if (day.length === 1) {
      day = '0' + day;
  }
  return `${year}-${month}-${day}`;
};


export default function UserReports() {
  const history = useHistory();
  const [token, setToken] = useState('');
  const [entradas, setEntradas] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [datas, setDatas] = useState('');
  const [detalhes, setDetalhes] = useState('');
  const [ano, setAnos] = useState(obterAnoAtual());
  const [dia, setDia] = useState('');
  // const [ano, setAno] = useState('');
  const [mensal, setMensal] = useState('');
  const [inicio, setInicio] = useState('');
  const [fim, setFim] = useState('');
  const [situacao, setSituacao] = useState('');
  const [dataSelecionadas, setSelecionadas] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [quadros, setQuadros] = useState([]);

  const [receita, setReceita] = useState({ vpago: 0 });
  const [despesa, setDespesa] = useState({ vpago: 0 });

  const meses = [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
    "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ];
  const dataAtual = new Date();
  const mesAtual = meses[dataAtual.getMonth()];

  function obterAnoAtual() {
    const dataAtual = new Date();
    const anoAtual = dataAtual.getFullYear();
    return anoAtual;
  }

  function formatarValor(valor) {
    if (valor === null || valor === undefined) {
      return "R$ 00,00";
    }

    const valorFormatado = valor.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return valorFormatado;
  }

  // function calcularPorcentagem(valorTotal, valorParcial) {
  //   if (valorTotal <= 0 || valorParcial < 0) {
  //     return "Valores inválidos";
  //   }

  //   const porcentagem = Math.round((valorParcial / valorTotal) * 100);
  //   return `${porcentagem}%`;
  // }

  function calcularPorcentagem(valorTotal, valorParcial) {
    if (valorTotal === 0 || valorParcial === 0) {
      return "0%";
    }

    const valorTotalAbs = Math.abs(valorTotal);
    const valorParcialAbs = Math.abs(valorParcial);

    // const porcentagem = Math.round((valorParcialAbs / valorTotalAbs) * 100);
    const porcentagem = ((valorParcialAbs / valorTotalAbs) * 100).toFixed(2);

    if (valorParcial < 0) {
      return `-${porcentagem}%`;
    } else {
      return `${porcentagem}%`;
    }
  }

  // PREENCHE TODOS OS DADOS DOS GRAFICOS
  useEffect(async () => {
    const param = {}
    const key = await getData();

    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const formattedFirstDay = formatDate(firstDayOfMonth);
    const formattedLastDay = formatDate(lastDayOfMonth);

    setInicio(formattedFirstDay);
    setFim(formattedLastDay);


    if ((typeof key !== 'undefined') && key !== null) {
      param.user = key;

      const fetchData = async () => {
        setIsLoading(true);

        try {
          const { data } = await api.get(`/financeiro`, { params: param, timeout: 500000 });

          // console.log(data)
          setToken(key);
          setEntradas(data[0]);

          console.log(data[4], data[2])


          setMensal(data[2])
          setDia(data[3]);
          setQuadros(data[4]);

          const select_empresas = [...new Set(data[1].map(item => item.NomeFantasia))];

          // // PREENCHE O SELECT BOX DE EMPRESAS
          const selectEmpresas = document.getElementById("select-empresas-financeiro");
          select_empresas.forEach(empresa => {
            const option = document.createElement("option");
            option.value = empresa;
            option.text = empresa;
            selectEmpresas.appendChild(option);
          });


        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false); // Define o estado isLoading como false após a chamada Axios
        }
      };

      fetchData();
    } else {
      history.push('/');
    }
  }, []);


  // console.log(detalhes)

  const handleSubmit = (errors) => {
    const data = {}

    if ((typeof token !== 'undefined') && token !== null) {
      data.user = token

      if ((typeof empresa !== 'undefined') && empresa !== null) {
        data.empresa = empresa
      }

      if ((typeof datas !== 'undefined') && datas !== null) {
        data.datas = datas
      }

      if (inicio !== null && inicio !== '') {
        if (fim !== null && fim !== '') {
          data.fim = fim
          data.inicio = inicio
          setSelecionadas('true')
        } else {
          alert("preencher os dois periodos");
        }
      } else {
        setSelecionadas('')
      }

      if (fim !== null && fim !== '') {
        data.fim = fim
      }

      if ((typeof situacao !== 'undefined') && situacao !== null) {
        data.situacao = situacao
      }

      // console.log(inicio, fim)

      // console.log(data)
      if (data !== '') {
        setIsLoading(true);

        api.get('/financeiro', { params: data })
          .then(response => {
            // console.log(response)
            // setDetalhes(response.data[2])
            setEntradas(response.data[0]);
            setMensal(response.data[2])
            setDia(response.data[3]);
            setQuadros(response.data[4]);
            // console.log(response)
            // if (data !== null) {
            // console.log(response.data[0])
            // }
          })
          .catch(error => {
            console.error(error);
          }).finally(() => {
            setIsLoading(false)
          });
      }
    } else {
      alert('Erro no acesso');
      history.push('/');
    }

  };

  useEffect(() => {
    // Aqui você define os quadros como é feito atualmente, provavelmente algo parecido com:
    // setQuadros([...novosQuadros]);

    // Separação dos quadros em despesas e receitas
    const despesas = quadros.filter(item => item.Despesa === true);
    const receitas = quadros.filter(item => item.Despesa === false);

    // Verificação e definição de valores padrão caso os arrays estejam vazios
    setDespesa(despesas.length > 0 ? despesas[0] : { vSaida: 0 });
    setReceita(receitas.length > 0 ? receitas[0] : { vEntrada: 0 });

    // Aqui você pode utilizar despesa e receita como desejar
  }, [quadros]);

  return (
    <Box mt={{ sm: '2%', md: '2%', lg: '2%', xl: '2%' }} bg={"#0B0B3B"}>
      {isLoading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="rgba(0, 0, 0, 0.5)" // Define a cor de fundo como transparente com um leve tom escuro (50% de opacidade)
          zIndex="9999"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            color="blue.500"
            size="xl"
          />
        </Box>
      )}
      <SimpleGrid
        columns={[1, 2, 3, 4]}
        spacing={10}
        mb={5} ml={2}
        rounded="md" p={4}
        color={'white'}
        border="2px solid white"
      >
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Empresas:</FormLabel>
            <Select id="select-empresas-financeiro" onChange={(e) => setEmpresa(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Tipos de Data:</FormLabel>
            <Select id="datas" onChange={(e) => setDatas(e.target.value)}>
              <option value="DataVencimento">Data Vencimento</option>
              <option value="DataPagamento">Data Pagamento</option>
              <option value="DataCompetencia">Data Competencia</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Início do Período:</FormLabel>
            <Input color={'white'} type="date" value={inicio} id="inicio" onChange={(e) => setInicio(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Fim do Período:</FormLabel>
            <Input color={'white'} type="date" value={fim} id="Fim" onChange={(e) => setFim(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Situação do Titulo:</FormLabel>
            <Select id="situacao" onChange={(e) => setSituacao(e.target.value)}>
              <option value="">Todas</option>
              <option value="true">Quitado</option>
              <option value="false">Não quitado</option>
            </Select>
          </FormControl>
        </Box>
        {/* <Box>
          <FormControl>
            <FormLabel>Tipo de Lançamento:</FormLabel>
            <Select id="situacao" onChange={(e) => setSituacao(e.target.value)}>
              <option value="1">Todos</option>
              <option value="2">Receitas</option>
              <option value="3">Despesas</option>
              <option value="4">Receitas de Vendas</option>
              <option value="5">Comissões de vendedores</option>
            </Select>
          </FormControl>
        </Box> */}
        <Box mt="auto">
          <Button colorScheme="blue" onClick={handleSubmit}>Enviar</Button>
        </Box>
      </SimpleGrid>

      <SimpleGrid mt={2} columns={5} display={{ md: 'flex' }} rounded="sm" p={2} width={"100%"}>
        {receita && (
          <Box borderRadius="md" m={1} boxShadow="md" p={4} w={{ md: '20%', lg: '20%', xl: '20%' }} bg="#5ce1e6">
            <Text fontSize={{ md: '13px', lg: '18px', xl: '24px' }} fontWeight="bold" textAlign="center">Receita Bruta</Text>
            <Flex alignItems="center" justifyContent="center" mb={2}>
              <Text fontSize={{ md: '10px', lg: '13px', xl: '20px' }} fontWeight="bold">
                {formatarValor(receita.vEntrada)}
              </Text>
            </Flex>
          </Box>
        )}
        {despesa && (
          <Box borderRadius="md" m={1} boxShadow="md" p={4} w={{ md: '20%', lg: '20%', xl: '20%' }} bg="#38b6ff">
            <Text fontSize={{ md: '13px', lg: '18px', xl: '24px' }} fontWeight="bold" textAlign="center">Custos e Despesas</Text>
            <Flex alignItems="center" justifyContent="center" mb={2}>
              <Text fontSize={{ md: '10px', lg: '13px', xl: '20px' }} fontWeight="bold">
                -{formatarValor(despesa.vSaida)}
              </Text>
            </Flex>
          </Box>
        )}
        {/* Demais boxes usando despesa e receita */}
        {receita && (
          <Box borderRadius="md" m={1} boxShadow="md" p={4} w={{ md: '20%', lg: '20%', xl: '20%' }} bg="#1E90FF">
            <Text fontSize={{ md: '13px', lg: '18px', xl: '24px' }} fontWeight="bold" textAlign="center">% Custos e Despesas</Text>
            <Flex alignItems="center" justifyContent="center" mb={2}>
              <Text fontSize={{ md: '10px', lg: '13px', xl: '20px' }} fontWeight="bold">
                {calcularPorcentagem(receita.vEntrada, despesa.vSaida)}
              </Text>
            </Flex>
          </Box>
        )}
        {receita && despesa && (
          <Box borderRadius="md" m={1} boxShadow="md" p={4} w={{ md: '20%', lg: '20%', xl: '20%' }} bg="#ADD8E6">
            <Text fontSize={{ md: '13px', lg: '18px', xl: '24px' }} fontWeight="bold" textAlign="center">Lucro Liquido</Text>
            <Flex alignItems="center" justifyContent="center" mb={2}>
              <Text fontSize={{ md: '10px', lg: '13px', xl: '20px' }} fontWeight="bold">
                {formatarValor(receita.vEntrada - despesa.vSaida)}
              </Text>
            </Flex>
          </Box>
        )}
        {receita && despesa && (
          <Box borderRadius="md" m={1} boxShadow="md" p={4} w={{ md: '20%', lg: '20%', xl: '20%' }} bg="#5ce1e6">
            <Text fontSize={{ md: '13px', lg: '18px', xl: '24px' }} fontWeight="bold" textAlign="center">% Lucro Liquido</Text>
            <Flex alignItems="center" justifyContent="center" mb={2}>
              <Text fontSize={{ md: '10px', lg: '13px', xl: '20px' }} fontWeight="bold">
                {calcularPorcentagem(receita.vEntrada, (receita.vEntrada - despesa.vSaida))}
              </Text>
            </Flex>
          </Box>
        )}
      </SimpleGrid>


      <SimpleGrid bg="#0B0B61" color={"white"} columns={[1, 2, 3]} display={{ sm: 'flex', md: 'flex' }} rounded="sm" gap='20px' mb='20px'>
        <Table variant='simple' w={{ base: "100%", md: "96%" }} ml={{ md: "2%" }} mb={{ md: "2%" }}>
          <Thead>
            <Tr h="40px">
              <Th color={"white"} colSpan={4} textAlign='center' py='2' px='2'>
                Lançamentos no {dataSelecionadas ? `${formatarPeriodo(inicio, fim)}` : 'Período de ' + mesAtual}
              </Th>
            </Tr>
            <Tr h="40px">
              <Th bgColor='#5ce1e6' color='black' borderTopWidth='0' textAlign='center' py='2' px='2'>
                <Icon as={FaMoneyBillAlt} mr='1' />
                Situação
              </Th>
              <Th bgColor='#38b6ff' color='black' borderTopWidth='0' textAlign='center' py='2' px='2'>
                <Icon as={FaMoneyBillAlt} mr='1' />
                Contas a pagar
              </Th>
              <Th bgColor='#0097b2' color='black' borderTopWidth='0' textAlign='center' py='2' px='2'>
                <Icon as={FaMoneyBillAlt} mr='1' />
                Contas a receber
              </Th>
              <Th bgColor='#008fe7' color='black' borderTopWidth='0' textAlign='center' py='2' px='2'>
                <Icon as={FaMoneyBillAlt} mr='1' />
                Saldo
              </Th>
            </Tr>
          </Thead>
          {dia[0] && (
            <Tbody>
              <Tr>
                <Td borderBottomWidth='2px' fontWeight="bold" borderBottomStyle='solid' textAlign='center'>Previsão do Período</Td>
                <Td borderBottomWidth='2px' fontWeight="bold" borderBottomStyle='solid' textAlign='center'>{dia[0].totalSaida}</Td>
                <Td borderBottomWidth='2px' fontWeight="bold" borderBottomStyle='solid' textAlign='center'>{dia[1].totalEntrada}</Td>
                <Td borderBottomWidth='2px' fontWeight="bold" borderBottomStyle='solid' textAlign='center'>
                  {(Number(dia[1].totalEntrada.replace(/[^\d,-]/g, '').replace(',', '.').replace(/-/g, '')) - Number(dia[0].totalSaida.replace(/[^\d,-]/g, '').replace(',', '.').replace(/-/g, ''))).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Td>
              </Tr>
              <Tr>
                <Td borderBottomWidth='2px' fontWeight="bold" borderBottomStyle='solid' textAlign='center'>Realizado no Período</Td>
                <Td borderBottomWidth='2px' fontWeight="bold" borderBottomStyle='solid' textAlign='center'>{dia[0].totalPago}</Td>
                <Td borderBottomWidth='2px' fontWeight="bold" borderBottomStyle='solid' textAlign='center'>{dia[1].totalPago}</Td>
                <Td borderBottomWidth='2px' fontWeight="bold" borderBottomStyle='solid' textAlign='center'>
                  {(Number(dia[1].totalPago.replace(/[^\d,-]/g, '').replace(',', '.').replace(/-/g, '')) - Number(dia[0].totalPago.replace(/[^\d,-]/g, '').replace(',', '.').replace(/-/g, ''))).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Td>
              </Tr>
              <Tr>
                <Td borderBottomWidth='2px' fontWeight="bold" borderBottomStyle='solid' textAlign='center'>Não pago no Período</Td>
                <Td borderBottomWidth='2px' fontWeight="bold" borderBottomStyle='solid' textAlign='center'>{dia[0].totalPagar}</Td>
                <Td borderBottomWidth='2px' fontWeight="bold" borderBottomStyle='solid' textAlign='center'>{dia[1].totalReceber}</Td>
                <Td borderBottomWidth='2px' fontWeight="bold" borderBottomStyle='solid' textAlign='center'>
                  {(Number(dia[1].totalReceber.replace(/[^\d,-]/g, '').replace(',', '.').replace(/-/g, '')) - Number(dia[0].totalPagar.replace(/[^\d,-]/g, '').replace(',', '.').replace(/-/g, ''))).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Td>
              </Tr>
            </Tbody>
          )}
        </Table>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <Diario data={entradas} />

        {/* {forma ? (
          <Forma data={forma} />
        ) : (
          <Forma />
        )} */}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <Mensal data={mensal} anos={ano} />
      </SimpleGrid>
    </Box>
  );
}
