import axios from 'axios';
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import LineChart from "components/charts/BarChart";
import React, { useState, useEffect } from "react";
import { MdBarChart } from "react-icons/md";
import ReactApexChart from "react-apexcharts";
// import {
//   barChartDataConsumption,
//   barChartOptionsConsumption,
// } from "variables/charts";

function obterAnoAtual() {
  const dataAtual = new Date();
  const anoAtual = dataAtual.getFullYear();
  return anoAtual;
}



export default function Mensal({ data, anos }) {
  // const { ...rest } = props;
  const [barChart, setbarChart] = useState([]);
  const [ano, setAno] = useState('');



  useEffect(() => {
    setbarChart(data);
    setAno(anos);
    // (async () => {
    //   const { data } = await axios.get('http://localhost:4000/anual');
    //   setbarChart(data);
    // })();
  }, [data]);

  const mesesAno = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const series = {};
  let valorTotalGeral = 0;

  for (let i = 0; i < barChart.length; i++) {
    const dado = barChart[i];
    const name = dado.Status;

    // Cria uma série se ainda não existe para o status atual
    if (!series[name]) {
      series[name] = {
        name: name,
        data: [],
      };
    }

    // Adiciona um ponto à série com as informações do dado atual
    const novoPonto = {
      x: dado.Mes,
      y: dado.Total
      // valorTotalFormatado: dado.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
    };

    // console.log(novoPonto)
    valorTotalGeral += dado.Total;

    series[name].data.push(novoPonto);

    // Ordena os pontos da série em ordem crescente com base no valor de x
    series[name].data.sort((a, b) => a.x - b.x);
  }

  for (const serie of Object.values(series)) {
    const dataMonths = serie.data.map(ponto => ponto.x);
    for (const month of mesesAno) {
      if (!dataMonths.includes(month)) {
        serie.data.push({ x: month, y: 0 });
      }
    }
  }

  const seriesArray = Object.values(series);
  // console.log(seriesArray)

  const mesesNaOrdemDesejada = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  // Mapeamento de meses para garantir a ordem correta
  const mapaMeses = {};
  mesesNaOrdemDesejada.forEach((mes, index) => {
    mapaMeses[mes] = index;
  });

  // Função para reorganizar os dados no seriesArray
  const reorganizarSeriesArray = (seriesArray, mesesMap) => {
    return seriesArray.map(series => ({
      ...series,
      data: series.data.sort((a, b) => mesesMap[a.x] - mesesMap[b.x])
    }));
  };

  // Aplicar a reorganização
  const seriesArrayReorganizado = reorganizarSeriesArray(seriesArray, mapaMeses);

  // console.log(seriesArrayReorganizado);
  const title = ano + " - Valor Total do Período - R$" + valorTotalGeral.toLocaleString('pt-BR', { minimumFractionDigits: 2 });



  const options = {
    chart: {
      type: 'bar',
      height: 500,
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    title: {
      text: title,
      align: 'center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: 'white'
      },
    },
    plotOptions: {
      bar: {
        barHeight: '80%', // ajuste o valor de barHeight para controlar a altura das barras
        barWidth: '50%',
      },
    },
    tooltip: {
      theme: "dark",
      enabled: true,
      style: {
        fontSize: '14px', // Ajuste o tamanho da fonte conforme necessário
        background: '#2c3e50', // Cor de fundo desejada
        color: '#ffffff', // Cor do texto desejada
      },
      onDatasetHover: {
        highlightDataSeries: true,
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      // categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      categories: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      labels: {
        formatter: function (val) {
          // Mapeie o valor completo para o valor abreviado
          const mesAbreviado = {
            'Janeiro': 'Jan',
            'Fevereiro': 'Fev',
            'Março': 'Mar',
            'Abril': 'Abr',
            'Maio': 'Mai',
            'Junho': 'Jun',
            'Julho': 'Jul',
            'Agosto': 'Ago',
            'Setembro': 'Set',
            'Outubro': 'Out',
            'Novembro': 'Nov',
            'Dezembro': 'Dez'
          };

          // Use o mapeamento para obter o valor abreviado
          return mesAbreviado[val];
        },
        style: {
          colors: "white",
        }
      }
    },
    yaxis: {
      show: true,
      labels: {
        formatter: function (value) {
          return 'R$ ' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        style: {
          colors: "white",
        }
      },
    },
    fill: {
      opacity: 1
    },
    responsive: [
      {
        breakpoint: 768, // Define o ponto de quebra para telas menores
        options: {
          title: {
            style: {
              fontSize: '14px' // Tamanho do título para telas menores
            }
          }
        }
      }
      // Você pode adicionar mais breakpoints e opções conforme necessário
    ]
  };

  return (
    <Card bg="#0B0B61" color="white" borderRadius={0} align='center' direction='column' w='100%'>
      <Flex align='center' w='100%' px='15px' py='10px'>
        <Text
          me='auto'
          // color={textColor}
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'>
          Evolução de vendas mensal 
        </Text>
      </Flex>
      {/* <Flex align='center' w='100%' px='15px'>
        <Text
          me='auto'
          color="white"
          fontSize='sm'  // Tamanho menor
          lineHeight='100%'>
          {title}
        </Text>
      </Flex> */}
      <Box h='300px' mt='auto'>
        {/* <LineChart
            chartData={bar}
            chartOptions={lineChartOptionsTotalSpent}
          /> */}
        <ReactApexChart
          options={options}
          series={seriesArrayReorganizado}
          type='bar'
          width='100%'
          height='100%'
        />
      </Box>
    </Card>
  )
}