import api from 'contexts/api';
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  FormControl, Input,
  Icon,
  Select,
  SimpleGrid,
  Grid,
  useColorModeValue,
  Button,
  Text
} from "@chakra-ui/react";
import React, { useState, useEffect, useCallback } from "react";



export default function Pagination({ totalItems, currentPage, itemsPerPage, onPageChange, onLimitChange }) {
  
    const [totalPages, setTotalPages] = useState(Math.ceil(totalItems / itemsPerPage));
  
    useEffect(() => {
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
    }, [totalItems, itemsPerPage]);
  
    const handlePageChange = (newPage) => {
      onPageChange(newPage);
    };
  
    const handleItemsPerPageChange = (e) => {
      const newItemsPerPage = Number(e.target.value);
      onPageChange(1); // Voltar para a primeira página ao alterar itemsPerPage
      onLimitChange(newItemsPerPage);
    };
  
    return (
      <Box mt={4} textAlign="center" w="100%">
        <SimpleGrid spacing={1} alignItems="center">
          <Box display='flex' alignItems='baseline'>
            <Button
              colorScheme="blue"
              onClick={() => handlePageChange(currentPage - 1)}
              isDisabled={currentPage === 1}
              mr={2}
              size="sm"
            >
              Anterior
            </Button>
            <Text as="span" fontSize="sm" fontWeight="bold" mr={2}>
              Página {currentPage} de {totalPages}
            </Text>
            <Button
              colorScheme="blue"
              onClick={() => handlePageChange(currentPage + 1)}
              isDisabled={currentPage === totalPages}
              ml={2}
              size="sm"
            >
              Próxima
            </Button>
            <Text as="span" fontSize="sm" fontWeight="bold" ml={2}>
              Itens por página:
            </Text>
            <Select ml={2}
              id="select-limite"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              size="sm"
              maxWidth="70px"
            >
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
            </Select>
            {/* <Text as="span" fontSize="sm" fontWeight="bold" ml='2%'>
              Página: {currentPage}
            </Text> */}
          </Box>
        </SimpleGrid>
      </Box>
    );
  }