import React, { useState, useEffect, useRef } from "react";
import {
    SimpleGrid, Text, Box, FormControl, FormLabel, Input, Select,
    Checkbox, Button, Table, Thead, Tbody, Tr, Th, Td, Flex,
    Textarea, Alert, AlertIcon, AlertTitle, AlertDescription,
    useToast, CloseButton, Spinner, FormErrorMessage, Icon
} from "@chakra-ui/react";
import { AddIcon, DownloadIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import axios from "axios";
import api from 'contexts/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useHistory } from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { MdPictureAsPdf } from 'react-icons/md';

function formatarValor(valor) {
    return valor ? valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "R$ 00,00";
}

function formatarDataHora(valor) {
    if (!valor) return "Sem Data";
    const data = new Date(valor);
    if (isNaN(data)) return "Sem Data";
    const dia = ("0" + data.getDate()).slice(-2);
    const mes = ("0" + (data.getMonth() + 1)).slice(-2);
    const ano = data.getFullYear();
    // return `${dia}/${mes}/${ano}`;
    return `${dia}/${mes}/${ano}`;
}

function formatDate(dateString) {
    // || (Date(dateString) ===  Date('1970-01-01T00:00:00.000Z'))
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() retorna meses de 0 a 11
    const day = String(date.getDate()).padStart(2, '0');
    const nulo = `${year}-${month}-${day}`;

    if (nulo == '1969-12-31') {
        return '';
    }

    return `${year}-${month}-${day}`;
}

const encodeStatus = (status) => {
    switch (status) {
        case "Aguardando":
            return 0;
        case "Separação":
            return 2;
        case "Despachada":
            return 4;
        default:
            return null;
    }
};

const DataTable = ({ data, onMove, moveDirection }) => (
    <Box border="1px" height="300px" overflowY="auto" overflowX="auto">
        <Table variant="striped" size="sm" width="100%"> {/* Tamanho pequeno da tabela */}
            <Thead>
                <Tr>
                    <Th width="10%" style={{ textAlign: 'center', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, fontSize: '0.8em' }}>
                        <Button onClick={() => onMove("all")} leftIcon={<AddIcon />} variant="outline" size="xs">
                            {moveDirection === 'right' ? 'Todos' : 'Remover Todos'}
                        </Button>
                    </Th>
                    {['Data Venda', 'Nº Pedido', 'Cliente', 'Vendedor', 'Forma de Pagamento', 'Cidade', 'Região', 'Valor', 'Peso KG'].map(header => (
                        <Th key={header} style={{ textAlign: 'center', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, fontSize: '0.8em' }}>
                            {header}
                        </Th>
                    ))}
                </Tr>
            </Thead>
            <Tbody>
                {data.map((item, index) => (
                    <Tr key={item._id}>
                        <Td width="10%" style={{ textAlign: 'center', whiteSpace: 'nowrap', fontSize: '0.8em' }}>
                            <Flex align="center">
                                <Button onClick={() => onMove(index)} leftIcon={<AddIcon />} variant="outline" size="xs">
                                    {moveDirection === 'right' ? 'Adicionar' : 'Remover'}
                                </Button>
                            </Flex>
                        </Td>
                        <Td style={{ textAlign: 'center', whiteSpace: 'nowrap', fontSize: '0.8em' }}>{formatarDataHora(item.Data)}</Td>
                        <Td style={{ textAlign: 'center', whiteSpace: 'nowrap', fontSize: '0.8em' }}>{item.Codigo}</Td>
                        <Td style={{ textAlign: 'center', whiteSpace: 'nowrap', fontSize: '0.8em' }}>{item.Cliente}</Td>
                        <Td style={{ textAlign: 'center', whiteSpace: 'nowrap', fontSize: '0.8em' }}>{item.Vendedor}</Td>
                        <Td style={{ textAlign: 'center', whiteSpace: 'nowrap', fontSize: '0.8em' }}>{item.FormadePagamento}</Td>
                        <Td style={{ textAlign: 'center', whiteSpace: 'nowrap', fontSize: '0.8em' }}>{item.Municipio}</Td>
                        <Td style={{ textAlign: 'center', whiteSpace: 'nowrap', fontSize: '0.8em' }}>{item.cliente[0].UF}</Td>
                        <Td style={{ textAlign: 'center', whiteSpace: 'nowrap', fontSize: '0.8em' }}>
                            {formatarValor(item.venda_produtos.reduce((ValorTotal, produto) => ValorTotal + produto.ValorTotal, 0))}
                        </Td>
                        <Td style={{ textAlign: 'center', whiteSpace: 'nowrap', fontSize: '0.8em' }}>
                            {item.venda_produtos.reduce((totalPeso, produto) => totalPeso + produto.PesoKG, 0)} - KG
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    </Box>
);

export default function CadastroExpedicao({ ID, onClose }) {
    const history = useHistory();
    const [cliente, setCliente] = useState('');
    const [codigo, setID] = useState();
    const [cidade, setCidade] = useState('');
    const [vendedor, setVendedor] = useState('');
    const [regiao, setRegiao] = useState([]);
    const [empresa, setEmpresa] = useState([]);
    const [municipio, setMunicipio] = useState([]);
    const [grupo, setGrupo] = useState([]);
    // const [regiao, setEmpresa] = useState([]);
    const [vendedores, setVendedores] = useState([]);

    const [selectedEmpresa, setSelectedEmpresa] = useState([]);
    const [empresaCarga, setEmpresaCarga] = useState([]);
    const [vendedorCargo, setVendedorCargo] = useState([]);
    const [despachoResp, setDespachoResp] = useState([]);

    const handleMultiSelectChange = (selectedList) => {
        if (selectedList.length > 1) {
            setEmpresaCarga([selectedList[selectedList.length - 1]]);
            setValue('empresaEx', [selectedList[selectedList.length - 1]]);
        } else {
            setEmpresaCarga(selectedList);
            setValue('empresaEx', selectedList);
        }
    };

    const handleMultiVendedor = (selectedList) => {
        if (selectedList.length > 1) {
            setVendedorCargo([selectedList[selectedList.length - 1]]);
            setValue('ResponsavelSeparacao', [selectedList[selectedList.length - 1]]);
        } else {
            setVendedorCargo(selectedList);
            setValue('ResponsavelSeparacao', selectedList);
        }
    };

    const handleMultiDespacho = (selectedList) => {
        if (selectedList.length > 1) {
            setDespachoResp([selectedList[selectedList.length - 1]]);
            setValue('ResponsavelDespacho', [selectedList[selectedList.length - 1]]);
        } else {
            setDespachoResp(selectedList);
            setValue('ResponsavelDespacho', selectedList);
        }
    };

    const [inicio, setInicio] = useState('');
    const [fim, setFim] = useState('');

    const [VendasTable, setVendasTable] = useState([]);
    const [rightTableData, setRightTableData] = useState([]);


    const [status, setStatus] = useState(0);
    const [statusVenda, setStatusVenda] = useState([]);
    const [listMunicipio, setListMunicipio] = useState([]);
    const [listGrupo, setListGrupo] = useState([]);
    const [listRegiao, setlistRegiao] = useState([]);

    const { register, handleSubmit, setValue, watch, formState: { errors }, setError, clearErrors } = useForm();

    const optionsStatus = [
        { value: 'Pedido', label: 'Pedido' },
        { value: 'Orçamento', label: 'Orçamento' },
        { value: 'Pedido Faturado', label: 'Pedido Faturado' },
        { value: 'Pedido Nao Faturado', label: 'Pedido Nao Faturado' },
        { value: 'Pedido Aprovado Sem Faturamento', label: 'Pedido Aprovado Sem Faturamento' },
    ];

    const veiculoProprio = watch('VeiculoProprio', false);

    const updateStatus = () => {
        let newStatus;
        if (status === 0) {
            newStatus = 2;
        } else if (status === 2) {
            newStatus = 4;
        } else if (status === 4) {
            newStatus = 0;
        }

        setStatus(newStatus);
        setValue('Status', decodeStatus(newStatus));
    };

    const getButtonLabel = () => {
        if (status === 0) return "Separar";
        if (status === 2) return "Despachar";
        if (status === 4) return "Cancelar";
    };

    function generatePdfPedidos(data, pedidos) {
        const doc = new jsPDF();

        console.log(data, pedidos);
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        // Data do dia
        const currentDate = new Date().toLocaleString();

        // Cabeçalho com bordas
        doc.setFontSize(20);
        doc.setFont('helvetica', 'bold');
        const empresaUpperCase = data[0].Empresa.toUpperCase();
        doc.text(empresaUpperCase, pageWidth / 2, 20, { align: 'center' });
        doc.setFontSize(12);
        doc.text(`Resumo de Carregamento - Listagem de Carga`, pageWidth / 2, 30, { align: 'center' });
        doc.rect(5, 10, pageWidth - 10, 40);  // Borda do cabeçalho

        // Data e quantidade de páginas
        doc.setFontSize(10);
        doc.setFont('helvetica', 'normal');
        doc.text(`Data: ${currentDate}`, 10, 40);

        // Informações do Veículo
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text(`Nº da Carga: ${data[0].Codigo}`, 10, 60);
        doc.text(`Placa do Veiculo: ${data[0].PlacaVeiculo || 'Não informado'}`, 10, 70);

        // Linha separadora
        doc.line(5, 80, pageWidth - 5, 80);

        // Texto "Listagem de Produtos"
        const textWidth = doc.getTextWidth("Listagem de Produtos");
        doc.text("Listagem de Produtos", (pageWidth - textWidth) / 2, 80 + 10); // Centralizando o texto

        // Tabela de Produtos
        const startY = 100;
        let currentY = startY;
        const colWidths = [20, 70, 30, 30, 30];

        let rows = [];

        // Objeto para acumular os produtos agrupados por Codigo
        let aggregatedProducts = {};

        // Primeiro loop para agrupar os produtos
        pedidos.forEach(product => {
            if (product.venda_produtos.length > 0) {
                product.venda_produtos.forEach(produto => {
                    let codigo = produto.Codigo || '';
                    if (!aggregatedProducts[codigo]) {
                        aggregatedProducts[codigo] = {
                            Codigo: codigo,
                            Descricao: produto.Descricao || '',
                            Quantidade: 0,
                            PesoKG: 0,
                            ValorTotal: 0
                        };
                    }
                    aggregatedProducts[codigo].Quantidade += produto.Quantidade || 0;
                    aggregatedProducts[codigo].PesoKG += produto.PesoKG || 0;
                    aggregatedProducts[codigo].ValorTotal += produto.ValorTotal || 0;
                });
            }
        });

        // Segundo loop para preencher o array rows com os produtos agregados
        for (let codigo in aggregatedProducts) {
            let produto = aggregatedProducts[codigo];
            rows.push([
                produto.Codigo,
                produto.Descricao,
                produto.Quantidade,
                produto.PesoKG,
                produto.ValorTotal
            ]);
        }

        // Adicionando a linha de total geral
        const totalQuantity = rows.reduce((acc, row) => acc + (row[2] || 0), 0);
        const totalPeso = rows.reduce((acc, row) => acc + (row[3] || 0), 0);
        const totalValor = rows.reduce((acc, row) => acc + (row[4] || 0), 0);
        rows.push([
            'TOTAL GERAL',
            '',
            totalQuantity.toString(),
            totalPeso.toString(),
            totalValor
        ]);

        rows = rows.map(row => {
            row[4] = formatarValor(row[4]);
            return row;
        });

        // Configurações da tabela
        const tableOptions = {
            startY: currentY, // Ajuste para dar espaço abaixo do texto "Listagem de Produtos"
            margin: { top: 10 },
            bodyStyles: { valign: 'middle', halign: 'center' },
            head: [['Codigo Produto', 'Produto', 'Quantidade', 'Peso', 'Valor']],
            body: rows,
            columns: colWidths.map((width, i) => ({ width, halign: 'center' })),
            theme: 'grid',
            didDrawPage: function (data) {
                // Adicionando linha superior para separar cabeçalho
                doc.setDrawColor(0); // linha na cor preta
                doc.setLineWidth(0.1);
                doc.line(5, 95, pageWidth - 5, 95);
            }
        };

        doc.autoTable(tableOptions);

        // Save the PDF
        doc.save(`ResumoCarregamento${data[0].Codigo}.pdf`);
    }


    function generatePDFteste(data, vendas) {
        const doc = new jsPDF();

        // Configurações de estilo
        const margin = 10;
        const padding = 5;
        const pageWidth = doc.internal.pageSize.getWidth();

        // Cabeçalho
        doc.setFontSize(10);
        doc.text("RESUMO PARA ENTREGA - ", margin + padding, margin + padding);
        const empresaUpperCase = data[0].Empresa.toUpperCase();
        doc.text(empresaUpperCase, margin + padding + doc.getTextWidth("RESUMO PARA ENTREGA - "), margin + padding);
        doc.text(`Protocolo de Saída de Mercadorias - Carga Número: ${data[0].Codigo}`, margin + padding, margin + padding + 10);

        // Desenha borda do cabeçalho
        doc.rect(margin, margin, pageWidth - 2 * margin, 20);

        // Detalhes principais
        const detailsStartY = margin + 30;
        const dataEmissaoFormatada = formatDate(data[0].DataEmissao);
        const dataDespachoFormatada = formatDate(data[0].DataDespacho);
        const veiculoProprio = data[0].VeiculoProprio ? "Sim" : "Não";

        doc.text(`Responsável Separação: ${data[0].ResponsavelSeparacao}`, margin + padding, detailsStartY);
        doc.text(`Responsável Despacho: ${data[0].ResponsavelDespacho}`, margin + padding, detailsStartY + 10);
        doc.text(`Data Emissão: ${dataEmissaoFormatada}`, margin + padding, detailsStartY + 20);
        doc.text(`Data Despacho: ${dataDespachoFormatada}`, margin + padding + 80, detailsStartY + 20);
        doc.text(`Veículo Próprio: ${veiculoProprio}`, margin + padding, detailsStartY + 30);
        doc.text(`Placa Veículo: ${data[0].PlacaVeiculo}`, margin + padding + 50, detailsStartY + 30);
        doc.text(`Frete: ${data[0].Frete}`, margin + padding + 100, detailsStartY + 30);
        doc.text(`Observações: ${data[0].Observacoes}`, margin + padding, detailsStartY + 40);

        // Desenha borda da seção de detalhes principais
        doc.rect(margin, detailsStartY - 5, pageWidth - 2 * margin, 60);

        vendas.sort((a, b) => {
            const municipioA = (a.Municipio || "Sem Municipio").toLowerCase();
            const municipioB = (b.Municipio || "Sem Municipio").toLowerCase();
            if (municipioA < municipioB) return -1;
            if (municipioA > municipioB) return 1;
            return 0;
        });


        // Tabela de pedidos
        // let startY = detailsStartY + 60;
        // vendas.forEach(item => {
        //     // Dados da venda principal
        //     const vendaData = [
        //         [
        //             item.Codigo.toString(),
        //             item.Cliente,
        //             item.Municipio || "Sem Municipio",
        //             item.UF || "Sem região",
        //             item.FormadePagamento || "Sem forma de Pagamento",
        //             formatarValor(item.ValorFinal),
        //             item.cliente[0] && item.cliente[0].PessoaGrupo ? item.cliente[0].PessoaGrupo : "Sem Grupo"
        //         ]
        //     ];

        //     doc.autoTable({
        //         startY: startY,
        //         head: [['Nº Pedido', 'Cliente', 'Município', 'UF', 'Forma de Pagamento', 'Valor Final', 'Grupo Cliente']],
        //         body: vendaData,
        //         styles: { fontSize: 8, halign: 'center', valign: 'middle' },
        //         columnStyles: {
        //             1: { halign: 'left' }  // Alinha a coluna Cliente à esquerda
        //         },
        //         headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0] },
        //         didDrawCell: function (data) {
        //             doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
        //         }
        //     });

        //     // Cabeçalho e dados dos produtos da venda
        //     const produtosData = item.venda_produtos.map(produto => [
        //         produto.Codigo,
        //         produto.Descricao,
        //         produto.Quantidade.toString(),
        //         formatarValor(produto.ValorTotal)
        //     ]);

        //     doc.autoTable({
        //         startY: doc.lastAutoTable.finalY,
        //         head: [['Código Produto', 'Descrição Produto', 'Quantidade', 'Valor Total']],
        //         body: produtosData,
        //         styles: { fontSize: 8, halign: 'center', valign: 'middle' },
        //         columnStyles: {
        //             1: { halign: 'left' }  // Alinha a coluna Descrição Produto à esquerda
        //         },
        //         headStyles: { fillColor: [211, 211, 220], textColor: [0, 0, 0] },
        //         didDrawCell: function (data) {
        //             doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
        //         }
        //     });

        //     // Atualiza a posição Y para a próxima venda
        //     startY = doc.lastAutoTable.finalY;
        // });

        // Tabela de pedidos
        console.log(vendas);
        const tableData = vendas.map(item => [
            item.Codigo.toString(),
            item.Cliente,
            item.Municipio || "Sem Municipio",
            item.UF || "Sem região",
            item.FormadePagamento || "Sem forma de Pagamento",
            formatarValor(item.ValorFinal),
            item.cliente[0] && item.cliente[0].PessoaGrupo ? item.cliente[0].PessoaGrupo : "Sem Grupo"
        ]);

        console.log(tableData)

        doc.autoTable({
            startY: detailsStartY + 60,
            head: [['Nº Pedido', 'Cliente', 'Município', 'UF', 'Forma de Pagamento', 'Valor Final', 'Grupo Cliente']],
            body: tableData,
            styles: { fontSize: 8, halign: 'center', valign: 'middle' },
            columnStyles: {
                1: { halign: 'left' }  // Alinha a coluna Cliente à esquerda
            },
            headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0] },
            didDrawCell: function (data) {
                doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
            }
        });

        const finalY = doc.lastAutoTable.finalY;
        const pesoTotal = vendas.reduce((acc, item) => acc + item.venda_produtos.reduce((a, b) => a + b.PesoKG, 0), 0);
        const quantidade = vendas.reduce((acc, item) => acc + item.venda_produtos.reduce((a, b) => a + b.Quantidade, 0), 0);
        const valorTotal = vendas.reduce((acc, item) => acc + item.ValorFinal, 0);

        doc.text(`Peso Total: ${pesoTotal} KG`, margin + padding, finalY + 10);
        doc.text(`Quantidade: ${quantidade}`, margin + padding + 80, finalY + 10);
        doc.text(`Valor Total: ${formatarValor(valorTotal)}`, margin + padding + 140, finalY + 10);


        doc.line(margin, finalY + 15, pageWidth - margin, finalY + 15);

        doc.text(`Resumo de Pagamento`, margin + padding, finalY + 25);
        // Tabela de resumo de formas de pagamento
        const formasPagamento = {};
        vendas.map(item => {
            const formaPagamento = item.FormadePagamento || "Sem forma de Pagamento";
            if (!formasPagamento[formaPagamento]) {
                formasPagamento[formaPagamento] = 0;
            }
            formasPagamento[formaPagamento] += item.ValorFinal;
        });

        console.log(formasPagamento)

        const formasPagamentoData = Object.entries(formasPagamento).map(([forma, valorTotal]) => [
            forma,
            formatarValor(valorTotal)
        ]);

        console.log(formasPagamentoData)

        doc.autoTable({
            startY: finalY + 20,
            head: [['Forma de Pagamento', 'Valor Total']],
            body: formasPagamentoData,
            styles: { fontSize: 8, halign: 'center', valign: 'middle' },
            headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0] },
            didDrawCell: function (data) {
                doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
            }
        });

        // Linha de assinatura
        const offsetY = doc.lastAutoTable.finalY + 10;
        doc.line(margin, offsetY, pageWidth - margin, offsetY);

        // Assinatura
        doc.text("Declaro ter conferido as mercadorias bem como as notas/pedidos sendo o responsável pela entrega", margin + padding, offsetY + 15);
        doc.text("e retorno dos documentos e pagamentos assinados e conferidos.", margin + padding, offsetY + 25);
        doc.text("Assinatura: ____________________________", margin + padding, offsetY + 35);

        // Salva o PDF
        doc.save("CargaResumo.pdf");
    }

    function generatePDFV2(data, vendas) {
        const doc = new jsPDF();

        // Configurações de estilo
        const margin = 10;
        const padding = 5;
        const pageWidth = doc.internal.pageSize.getWidth();

        // Cabeçalho
        doc.setFontSize(10);
        doc.text("RESUMO PARA ENTREGA - ", margin + padding, margin + padding);
        const empresaUpperCase = data[0].Empresa.toUpperCase();
        doc.text(empresaUpperCase, margin + padding + doc.getTextWidth("RESUMO PARA ENTREGA - "), margin + padding);
        doc.text(`Protocolo de Saída de Mercadorias - Carga Número: ${data[0].Codigo}`, margin + padding, margin + padding + 10);

        // Desenha borda do cabeçalho
        doc.rect(margin, margin, pageWidth - 2 * margin, 20);

        // Detalhes principais
        const detailsStartY = margin + 30;
        const dataEmissaoFormatada = formatDate(data[0].DataEmissao);
        const dataDespachoFormatada = formatDate(data[0].DataDespacho);
        const veiculoProprio = data[0].VeiculoProprio ? "Sim" : "Não";

        doc.text(`Responsável Separação: ${data[0].ResponsavelSeparacao}`, margin + padding, detailsStartY);
        doc.text(`Responsável Despacho: ${data[0].ResponsavelDespacho}`, margin + padding, detailsStartY + 10);
        doc.text(`Data Emissão: ${dataEmissaoFormatada}`, margin + padding, detailsStartY + 20);
        doc.text(`Data Despacho: ${dataDespachoFormatada}`, margin + padding + 80, detailsStartY + 20);
        doc.text(`Veículo Próprio: ${veiculoProprio}`, margin + padding, detailsStartY + 30);
        doc.text(`Placa Veículo: ${data[0].PlacaVeiculo}`, margin + padding + 50, detailsStartY + 30);
        doc.text(`Frete: ${data[0].Frete}`, margin + padding + 100, detailsStartY + 30);
        doc.text(`Observações: ${data[0].Observacoes}`, margin + padding, detailsStartY + 40);

        // Desenha borda da seção de detalhes principais
        doc.rect(margin, detailsStartY - 5, pageWidth - 2 * margin, 60);

        vendas.sort((a, b) => {
            const municipioA = (a.Municipio || "Sem Municipio").toLowerCase();
            const municipioB = (b.Municipio || "Sem Municipio").toLowerCase();
            if (municipioA < municipioB) return -1;
            if (municipioA > municipioB) return 1;
            return 0;
        });


        // Tabela de pedidos
        let startY = detailsStartY + 60;
        vendas.forEach(item => {
            // Dados da venda principal
            const vendaData = [
                [
                    item.Codigo.toString(),
                    item.Cliente,
                    item.Municipio || "Sem Municipio",
                    item.UF || "Sem região",
                    item.FormadePagamento || "Sem forma de Pagamento",
                    formatarValor(item.ValorFinal),
                    item.cliente[0] && item.cliente[0].PessoaGrupo ? item.cliente[0].PessoaGrupo : "Sem Grupo"
                ]
            ];

            doc.autoTable({
                startY: startY,
                head: [['Nº Pedido', 'Cliente', 'Município', 'UF', 'Forma de Pagamento', 'Valor Final', 'Grupo Cliente']],
                body: vendaData,
                styles: { fontSize: 8, halign: 'center', valign: 'middle' },
                columnStyles: {
                    1: { halign: 'left' }  // Alinha a coluna Cliente à esquerda
                },
                headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0] },
                didDrawCell: function (data) {
                    doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
                }
            });

            // Cabeçalho e dados dos produtos da venda
            const produtosData = item.venda_produtos.map(produto => [
                produto.Codigo,
                produto.Descricao,
                produto.Quantidade.toString(),
                formatarValor(produto.ValorTotal)
            ]);

            doc.autoTable({
                startY: doc.lastAutoTable.finalY,
                head: [['Código Produto', 'Descrição Produto', 'Quantidade', 'Valor Total']],
                body: produtosData,
                styles: { fontSize: 8, halign: 'center', valign: 'middle' },
                columnStyles: {
                    1: { halign: 'left' }  // Alinha a coluna Descrição Produto à esquerda
                },
                headStyles: { fillColor: [211, 211, 220], textColor: [0, 0, 0] },
                didDrawCell: function (data) {
                    doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
                }
            });

            // Atualiza a posição Y para a próxima venda
            startY = doc.lastAutoTable.finalY;
        });
        // Tabela de pedidos
        // console.log(vendas);
        // const tableData = vendas.map(item => [
        //     item.Codigo.toString(),
        //     item.Cliente,
        //     item.Municipio || "Sem Municipio",
        //     item.UF || "Sem região",
        //     item.FormadePagamento || "Sem forma de Pagamento",
        //     formatarValor(item.ValorFinal),
        //     item.cliente[0] && item.cliente[0].PessoaGrupo ? item.cliente[0].PessoaGrupo : "Sem Grupo"
        // ]);

        // console.log(tableData)

        // doc.autoTable({
        //     startY: detailsStartY + 60,
        //     head: [['Nº Pedido', 'Cliente', 'Município', 'UF', 'Forma de Pagamento', 'Valor Final', 'Grupo Cliente']],
        //     body: tableData,
        //     styles: { fontSize: 8, halign: 'center', valign: 'middle' },
        //     columnStyles: {
        //         1: { halign: 'left' }  // Alinha a coluna Cliente à esquerda
        //     },
        //     headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0] },
        //     didDrawCell: function (data) {
        //         doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
        //     }
        // });

        const finalY = doc.lastAutoTable.finalY;
        const pesoTotal = vendas.reduce((acc, item) => acc + item.venda_produtos.reduce((a, b) => a + b.PesoKG, 0), 0);
        const quantidade = vendas.reduce((acc, item) => acc + item.venda_produtos.reduce((a, b) => a + b.Quantidade, 0), 0);
        const valorTotal = vendas.reduce((acc, item) => acc + item.ValorFinal, 0);

        doc.text(`Peso Total: ${pesoTotal} KG`, margin + padding, finalY + 10);
        doc.text(`Quantidade: ${quantidade}`, margin + padding + 80, finalY + 10);
        doc.text(`Valor Total: ${formatarValor(valorTotal)}`, margin + padding + 140, finalY + 10);


        doc.line(margin, finalY + 15, pageWidth - margin, finalY + 15);

        doc.text(`Resumo de Pagamento`, margin + padding, finalY + 25);
        // Tabela de resumo de formas de pagamento
        const formasPagamento = {};
        vendas.map(item => {
            const formaPagamento = item.FormadePagamento || "Sem forma de Pagamento";
            if (!formasPagamento[formaPagamento]) {
                formasPagamento[formaPagamento] = 0;
            }
            formasPagamento[formaPagamento] += item.ValorFinal;
        });

        console.log(formasPagamento)

        const formasPagamentoData = Object.entries(formasPagamento).map(([forma, valorTotal]) => [
            forma,
            formatarValor(valorTotal)
        ]);

        console.log(formasPagamentoData)

        doc.autoTable({
            startY: finalY + 20,
            head: [['Forma de Pagamento', 'Valor Total']],
            body: formasPagamentoData,
            styles: { fontSize: 8, halign: 'center', valign: 'middle' },
            headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0] },
            didDrawCell: function (data) {
                doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
            }
        });

        // Linha de assinatura
        const offsetY = doc.lastAutoTable.finalY + 10;
        doc.line(margin, offsetY, pageWidth - margin, offsetY);

        // Assinatura
        doc.text("Declaro ter conferido as mercadorias bem como as notas/pedidos sendo o responsável pela entrega", margin + padding, offsetY + 15);
        doc.text("e retorno dos documentos e pagamentos assinados e conferidos.", margin + padding, offsetY + 25);
        doc.text("Assinatura: ____________________________", margin + padding, offsetY + 35);

        // Salva o PDF
        doc.save("CargaResumo.pdf");
    }

    function formatDate(date) {
        // Função fictícia para formatar a data
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    const formatDateInput = (date) => {
        const data = new Date(date)
        const year = data.getFullYear();
        let month = (data.getMonth() + 1).toString();
        if (month.length === 1) {
            month = '0' + month;
        }
        let day = data.getDate().toString();
        if (day.length === 1) {
            day = '0' + day;
        }
        return `${year}-${month}-${day}`;
    };

    function formatarValor(valor) {
        // Função fictícia para formatar o valor
        return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    const getData = async () => {
        try {
            const key = await AsyncStorage.getItem('@storage_Key');
            return key || null;
        } catch (e) {
            console.error(e);
        }
    };

    function findByLabel(dado, label) {
        console.log(dado, label);
        return dado.find(item => item.label === label);
    }

    const handleFilter = async () => {
        const data = {};
        const token = await getData();
        if (!token) return history.push('/');

        data.user = token;
        if (listRegiao) data.regiao = listRegiao;
        if (cliente) data.cliente = cliente;
        if (selectedEmpresa) data.empresa = selectedEmpresa;
        if (statusVenda) data.status = statusVenda;
        if (listMunicipio) data.listMunicipio = listMunicipio;
        if (listGrupo) data.grupo = listGrupo;
        if (vendedor) data.vendedor = vendedor;
        if (cidade) data.cidade = cidade;
        if (inicio && fim) {
            data.inicio = inicio;
            data.fim = fim;
        } else if (inicio || fim) {
            return alert("Preencher os dois períodos");
        }

        console.log(data)

        try {
            // setLoading(false);
            setLoading(true);
            const response = await api.get('/vendas-cargas', { params: data, timeout: 1000000 });
            console.log("resposta de filtro", response)
            setVendasTable(response.data.length ? response.data[0] : []);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const [PesoTotal, setPesoTotal] = useState(0);
    const [dados, setDados] = useState([]);

    //calcular peso total
    useEffect(() => {
        // console.log("somou");
        const calcularPesoTotal = () => {
            let total = 0;

            if (rightTableData.length > 0) {
                rightTableData.forEach(record => {
                    total += record.venda_produtos.reduce((acumulador, objeto) => acumulador + objeto.PesoKG, 0);
                });
                setPesoTotal(total);
                setValue("PesoTotal", total);
            }
        };

        calcularPesoTotal();
    }, [rightTableData]);

    // DATA THE SELECTORS - begin component
    useEffect(async () => {
        const fetchData = async () => {
            setValue('Status', decodeStatus(0));

            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            const formattedFirstDay = formatDateInput(firstDayOfMonth);
            const formattedLastDay = formatDateInput(lastDayOfMonth);

            setInicio(formattedFirstDay);
            setFim(formattedLastDay);
            setValue("DataEmissao", formatDateInput(currentDate));
            // setSelectEmpresa(empresa)
            // console.log(empresa)
            try {
                const data = {};
                const token = await getData();
                if (!token) return history.push('/');
                data.user = token;

                const filtros = await api.get('/dados-logistica', { params: data, timeout: 500000 });

                // console.log(filtros);

                const muni = filtros.data[4];
                if (muni) {
                    const munin = muni.map(empres => ({
                        value: empres.Municipio,
                        label: empres.Municipio
                    }));
                    setMunicipio(munin);
                    // console.log(empre); // Aqui você verá os dados atualizados
                }

                const grupo = filtros.data[5];
                if (muni) {
                    const grupoPessoa = grupo.map(grp => ({
                        value: grp._id,
                        label: grp.Nome
                    }));
                    setGrupo(grupoPessoa);
                    // console.log(empre); // Aqui você verá os dados atualizados
                }

                const regi = filtros.data[3];
                if (regi) {
                    const regia = regi.map(regiao => ({
                        value: regiao.Regiao,
                        label: regiao.Regiao
                    }));
                    setRegiao(regia);
                    // console.log(empre); // Aqui você verá os dados atualizados
                }
                // setMunicipio([])

                const emp = filtros.data[0];
                const vendedoresFiltros = filtros.data[1];
                // console.log(filtros)
                if (emp) {
                    const empre = emp.map(empres => ({
                        value: empres._id,
                        label: empres.NomeFantasia
                    }));
                    setEmpresa(empre);
                    // console.log(empre); // Aqui você verá os dados atualizados
                }

                if (vendedoresFiltros) {
                    const vend = vendedoresFiltros.map(vendedor => ({
                        value: vendedor._id,
                        label: vendedor.NomeFantasia
                    }));
                    setVendedores(vend);
                    // console.log(vend); // Aqui você verá os dados atualizados
                }

            } catch (error) {
                console.error("Erro ao carregar dados:", error);
                // Trate o erro conforme necessário
            } finally {
                if (ID) {
                    setID(ID)
                }
            }
        };

        await fetchData();
    }, []);

    //fill on the data in edit 
    useEffect(async () => {
        // console.log(ID, "entrou");
        if (codigo) {
            setLoading(true);
            const data = {};
            const token = await getData();
            if (!token) return history.push('/');
            data.user = token;
            data.id = codigo;
            // Faz uma requisição para pegar os dados com base no ID
            await api.get('/editar-carga', { params: data, timeout: 1000000 })
                .then(response => {

                    // console.log(response)
                    const registros = response.data[0];
                    const vendas = response.data[1];
                    const registro = registros[0];

                    console.log(vendas);
                    setRightTableData(vendas);
                    setDados(registros);

                    console.log("prencher editar", registros);
                    // Define os valores do formulário com os dados da resposta
                    for (const key in registro) {
                        if (registro.hasOwnProperty(key)) {
                            if (key == "DataEmissao" || key == "DataDespacho") {
                                // console.log(formatDateInput(registro[key]));
                                setValue(key, formatDateInput(registro[key]));
                            } else if (key == "Status") {
                                setValue('Status', decodeStatus(registro[key]));
                                setStatus(registro[key]);
                            } else if (key == "VeiculoProprio") {
                                // console.log(registro[key]);
                                setValue('VeiculoProprio', Boolean(registro[key]));
                            } else if (key == "EmpresaID" || key == "Empresa") {
                                const value = registro[key];
                                // Verifica se o valor é um array
                                if (Array.isArray(value)) {
                                    const isLabelValueArray = value.every(item => item.label && item.value);
                                    if (isLabelValueArray) {
                                        // Caso seja um array com objetos que possuem `label` e `value`
                                        // console.log(value);
                                        setEmpresaCarga(value);
                                        setValue('empresaEx', value);
                                    }
                                } else if (typeof value === 'string') {
                                    // Caso seja uma string
                                    const result = findByLabel(empresa, value);
                                    console.log(result);
                                    if (result) {
                                        setEmpresaCarga([result]);
                                        setValue('empresaEx', [result]);
                                    }
                                }

                            } else if (key == "ResponsavelSeparacao") {
                                if (vendedores.length > 0) {
                                    const value = registro[key];

                                    // Verifica se o valor é um array
                                    if (Array.isArray(value)) {
                                        const isLabelValueArray = value.every(item => item.label && item.value);

                                        if (isLabelValueArray) {
                                            // Caso seja um array com objetos que possuem `label` e `value`
                                            console.log(value);
                                            setVendedorCargo(value);
                                            setValue('ResponsavelSeparacao', value);
                                        }
                                    } else if (typeof value === 'string') {
                                        // Caso seja uma string
                                        const result = findByLabel(vendedores, value);
                                        console.log(result);
                                        if (result) {
                                            setVendedorCargo([result]);
                                            setValue('ResponsavelSeparacao', [result]);
                                        }
                                    }
                                }
                            } else if (key == "ResponsavelDespacho") {
                                if (vendedores.length > 0) {
                                    const value = registro[key];

                                    // Verifica se o valor é um array
                                    if (Array.isArray(value)) {
                                        const isLabelValueArray = value.every(item => item.label && item.value);

                                        if (isLabelValueArray) {
                                            // Caso seja um array com objetos que possuem `label` e `value`
                                            console.log(value);
                                            setDespachoResp(value);
                                            setValue('ResponsavelDespacho', value);
                                        }
                                    } else if (typeof value === 'string') {
                                        // Caso seja uma string
                                        const result = findByLabel(vendedores, value);
                                        console.log(result);
                                        if (result) {
                                            setDespachoResp([result]);
                                            setValue('ResponsavelDespacho', [result]);
                                        }
                                    }
                                }
                            } else {
                                setValue(key, registro[key]);
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error("Erro ao buscar os dados:", error);
                });
            setLoading(false);
        }
    }, [codigo]);

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [showAlert, setShowAlert] = useState(false);

    // ALERTS MSGS
    useEffect(() => {
        if (alert) {
            setShowAlert(true);
            const timeout = setTimeout(() => {
                setShowAlert(false);
            }, 2000); // 5 segundos
            return () => clearTimeout(timeout);
        }
    }, [alert]);

    const handleClickPdf = () => {
        generatePDFteste(dados, rightTableData);
    };

    const handleClickPdfPedidos = () => {
        generatePdfPedidos(dados, rightTableData);
    };

    const handleClickPdV2 = () => {
        generatePDFV2(dados, rightTableData);
    };

    // FUNCTION SUBMIT FORM 
    const onSubmit = async (data) => {
        // Inicia o spinner

        setAlert(null); // Reseta o alerta

        console.log("submit", data, rightTableData)

        const token = await getData();

        if (!token) return history.push('/');

        data.user = token;
        const encodedData = {
            ...data,
            Status: encodeStatus(data.Status)
        };

        if (!rightTableData.length) {
            setError("rightTableData", {
                type: "manual",
                message: "Selecione pelo menos um pedido",
            });
            setLoading(false); // Para o spinner
            return;
        }

        clearErrors("rightTableData");

        try {
            setLoading(true);

            console.log(rightTableData);
            let response;
            if (ID) {
                response = await api.put('/editarCarga', {
                    formData: encodedData,
                    selectedOrders: rightTableData,
                    id: ID
                });

                // console.log(response);
                setLoading(false);
                setAlert({
                    status: "success",
                    title: "Registro editado com Sucesso!",
                    description: ""
                });
            } else {
                response = await api.post('/logisticaInserir', {
                    formData: encodedData,
                    selectedOrders: rightTableData
                });
                setLoading(false);
                setAlert({
                    status: "success",
                    title: "Carga cadastrada com sucesso!",
                    description: ""
                });
            }
            // console.log("Response:", response.data);
            setTimeout(() => {
                onClose();
            }, 500);
        } catch (error) {
            setLoading(false);
            console.error("Error:", error);
            setAlert({
                status: "error",
                title: "Ocorreu um erro.",
                description: "Tente novamente."
            });
        }
    };

    const moveToLeft = (index) => {
        if (index === "all") {
            setRightTableData([]);
            setVendasTable(prev => [...prev, ...rightTableData]);
        } else {
            const selected = rightTableData[index];
            setRightTableData(prev => prev.filter((_, i) => i !== index));
            // setVendasTable(prev => [...prev, selected]);
        }
    };

    const moveToRight = (index) => {
        clearErrors("rightTableData");
        if (index === "all") {
            setVendasTable([]);
            setRightTableData(prev => [...prev, ...VendasTable]);
        } else {
            const selected = VendasTable[index];
            setVendasTable(prev => prev.filter((_, i) => i !== index));
            setRightTableData(prev => [...prev, selected]);
        }
    };

    const decodeStatus = (value) => {
        switch (value) {
            case 0:
                return "Aguardando";
            case 2:
                return "Separação";
            case 4:
                return "Despachada";
            default:
                return "Status desconhecido";
        }
    };

    return (
        <Box>
            {errors && (
                console.log(errors)
            )}
            {loading && (
                <Box
                    position="fixed"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    zIndex="9999"
                    background="rgba(255, 255, 255, 0.8)"
                    width="100vw"
                    height="100vh"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Spinner size="xl" />
                </Box>
            )}
            {showAlert && (
                <Box
                    position="fixed"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    zIndex="9999"
                >
                    <Alert status={alert.status} boxShadow="lg">
                        <AlertIcon />
                        <Box flex="1">
                            <AlertTitle>{alert.title}</AlertTitle>
                            <AlertDescription>{alert.description}</AlertDescription>
                        </Box>
                        {/* <CloseButton position="absolute" right="8px" top="8px" onClick={() => setShowAlert(false)} /> */}
                    </Alert>
                </Box>
            )}

            {/* BEGIN FILTER FORM  */}
            <SimpleGrid columns={[1, 2, 4, 6]} spacing={2} mb={5} ml={2} p={4} bg={"gray.200"}>
                <Box gridColumn="1 / -1">
                    <Text fontSize="md" fontWeight="bold">Pesquisar Pedidos/Vendas</Text>
                </Box>
                <Box>
                    <FormControl>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Empresa</FormLabel>
                        <MultiSelect
                            options={empresa}
                            value={selectedEmpresa}
                            onChange={setSelectedEmpresa}
                            hasSelectAll={false}
                            // hasSelectAll={false}
                            disableSearch={false}
                            overrideStrings={
                                {
                                    "allItemsAreSelected": "Todos",
                                    "clearSearch": "Limpar Pesquisa",
                                    "clearSelected": "Limpar Selecionado",
                                    "noOptions": "Sem opções",
                                    "search": "Pesquisar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Select All (Filtered)",
                                    "selectSomeItems": "Selecione",
                                    "create": "Criar",
                                }
                            }
                        />
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Status da Venda</FormLabel>
                        <MultiSelect
                            options={optionsStatus}
                            value={statusVenda}
                            onChange={setStatusVenda}
                            // hasSelectAll={false}
                            disableSearch={true}
                            overrideStrings={
                                {
                                    "allItemsAreSelected": "Todos.",
                                    "clearSearch": "Clear Search",
                                    "clearSelected": "Clear Selected",
                                    "noOptions": "No options",
                                    "search": "Search",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Selecionar todos",
                                    "selectSomeItems": "Selecione",
                                    // "create": "Criar",
                                }
                            }
                        />
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Cidades</FormLabel>
                        <MultiSelect
                            options={municipio}
                            value={listMunicipio}
                            onChange={setListMunicipio}
                            // hasSelectAll={false}
                            disableSearch={true}
                            overrideStrings={
                                {
                                    "allItemsAreSelected": "Todos.",
                                    "clearSearch": "Clear Search",
                                    "clearSelected": "Clear Selected",
                                    "noOptions": "No options",
                                    "search": "Search",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Selecionar todos",
                                    "selectSomeItems": "Selecione",
                                    // "create": "Criar",
                                }
                            }
                        />
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Grupo Cliente</FormLabel>
                        <MultiSelect
                            options={grupo}
                            value={listGrupo}
                            onChange={setListGrupo}
                            // hasSelectAll={false}
                            disableSearch={true}
                            overrideStrings={
                                {
                                    "allItemsAreSelected": "Todos.",
                                    "clearSearch": "Clear Search",
                                    "clearSelected": "Clear Selected",
                                    "noOptions": "No options",
                                    "search": "Search",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Selecionar todos",
                                    "selectSomeItems": "Selecione",
                                    // "create": "Criar",
                                }
                            }
                        />
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Região</FormLabel>
                        <MultiSelect
                            options={regiao}
                            value={listRegiao}
                            onChange={setlistRegiao}
                            // hasSelectAll={false}
                            disableSearch={true}
                            overrideStrings={
                                {
                                    "allItemsAreSelected": "Todos.",
                                    "clearSearch": "Clear Search",
                                    "clearSelected": "Clear Selected",
                                    "noOptions": "No options",
                                    "search": "Search",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Selecionar todos",
                                    "selectSomeItems": "Selecione",
                                    // "create": "Criar",
                                }
                            }
                        />
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Início do Período</FormLabel>
                        <Input background={"white"} type="date" id="inicio" value={inicio} onChange={(e) => setInicio(e.target.value)} />
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Fim do Período</FormLabel>
                        <Input background={"white"} type="date" id="Fim" value={fim} onChange={(e) => setFim(e.target.value)} />
                    </FormControl>
                </Box>
                <Box mt="auto">
                    <Button colorScheme="blue" onClick={handleFilter}>Filtrar</Button>
                </Box>
            </SimpleGrid>
            <SimpleGrid columns={[1, 2, 4, 5]} spacing={2} mb={5} ml={2} p={4}>
                <Box gridColumn="1 / -1">
                    <Text fontSize="md" fontWeight="bold">Informações da Carga</Text>
                </Box>
                <Box>
                    <FormControl isInvalid={errors.Codigo}>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Código</FormLabel>
                        <Input isDisabled
                            {...register("Codigo")}
                            background={errors.Codigo ? "red.100" : "white"} type="input"
                        />
                        <FormErrorMessage>
                            <span style={{ color: "red" }}>{errors.Codigo && errors.Codigo.message}</span>
                        </FormErrorMessage>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl isInvalid={errors.empresaEx}>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Empresa:</FormLabel>
                        <MultiSelect
                            {...register("empresaEx", { required: "Campo obrigatório!" })}
                            options={empresa}
                            value={empresaCarga}
                            onChange={handleMultiSelectChange}
                            hasSelectAll={false}
                            disableSearch={false}
                            style={errors.empresaEx ? { border: "1px solid red" } : {}}
                            overrideStrings={
                                {
                                    "allItemsAreSelected": "Todos",
                                    "clearSearch": "Limpar Pesquisa",
                                    "clearSelected": "Limpar Selecionado",
                                    "noOptions": "Sem opções",
                                    "search": "Pesquisar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Select All (Filtered)",
                                    "selectSomeItems": "Selecione",
                                    "create": "Criar",
                                }
                            }
                        />
                        <FormErrorMessage>
                            <span style={{ color: "red" }}>{errors.empresaEx && errors.empresaEx.message}</span>
                        </FormErrorMessage>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl isInvalid={errors.Status}>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Status</FormLabel>
                        <Input {...register("Status")} background={errors.Status ? "red.100" : "white"} type="input" id="status" isDisabled />
                        <FormErrorMessage>
                            <span style={{ color: "red" }}>{errors.Status && errors.Status.message}</span>
                        </FormErrorMessage>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl isInvalid={errors.ResponsavelSeparacao}>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Responsável Separação</FormLabel>
                        <MultiSelect
                            {...register("ResponsavelSeparacao", { required: "Campo obrigatório!" })}
                            options={vendedores}
                            value={vendedorCargo}
                            onChange={handleMultiVendedor}
                            hasSelectAll={false}
                            disableSearch={false}
                            overrideStrings={
                                {
                                    "allItemsAreSelected": "Todos",
                                    "clearSearch": "Limpar Pesquisa",
                                    "clearSelected": "Limpar Selecionado",
                                    "noOptions": "Sem opções",
                                    "search": "Pesquisar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Select All (Filtered)",
                                    "selectSomeItems": "Selecione",
                                    "create": "Criar",
                                }
                            }
                        />
                        <FormErrorMessage>
                            <span style={{ color: "red" }}>{errors.ResponsavelSeparacao && errors.ResponsavelSeparacao.message}</span>
                        </FormErrorMessage>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl isInvalid={errors.ResponsavelDespacho}>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Responsável Despacho</FormLabel>
                        <MultiSelect
                            {...register("ResponsavelDespacho", { required: "Campo obrigatório!" })}
                            options={vendedores}
                            value={despachoResp}
                            onChange={handleMultiDespacho}
                            hasSelectAll={false}
                            disableSearch={false}
                            overrideStrings={
                                {
                                    "allItemsAreSelected": "Todos",
                                    "clearSearch": "Limpar Pesquisa",
                                    "clearSelected": "Limpar Selecionado",
                                    "noOptions": "Sem opções",
                                    "search": "Pesquisar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Select All (Filtered)",
                                    "selectSomeItems": "Selecione",
                                    "create": "Criar",
                                }
                            }
                        />
                        <FormErrorMessage>
                            <span style={{ color: "red" }}>{errors.ResponsavelDespacho && errors.ResponsavelDespacho.message}</span>
                        </FormErrorMessage>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl isInvalid={errors.DataEmissao}>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Data Emissão</FormLabel>
                        <Input {...register("DataEmissao", { required: "Campo obrigatório!" })}
                            // background={errors.DataEmissao ? "red.100" : "white"} 
                            type="date" id="DataEmissao" />
                        <span style={{ color: "red" }}>{errors.DataEmissao && errors.DataEmissao.message}</span>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl isInvalid={errors.DataDespacho}>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Data Despacho</FormLabel>
                        <Input {...register("DataDespacho")} background={errors.DataDespacho ? "red.100" : "white"} type="date" id="DataDespacho" />
                        <FormErrorMessage>
                            <span style={{ color: "red" }}>{errors.DataDespacho && errors.DataDespacho.message}</span>
                        </FormErrorMessage>
                    </FormControl>
                </Box>
            </SimpleGrid>

            <SimpleGrid columns={[1, 1, 1, 1]} spacing={2} ml={2} p={4}>
                <SimpleGrid columns={[1]} spacing={0} ml={0} p={0}>
                    <Box mb={5}>
                        <Text fontSize="md" mb={2} fontWeight="bold">Selecione os Pedidos</Text>
                        <DataTable
                            data={VendasTable}
                            onMove={moveToRight}
                            moveDirection="right"
                        />
                    </Box>
                    <Box mb={5}>
                        <Text fontSize="md" mb={2} fontWeight="bold">Pedidos Selecionados</Text>
                        <DataTable
                            data={rightTableData}
                            onMove={moveToLeft}
                            moveDirection="left"
                        />
                        {errors.rightTableData && (
                            <span style={{ color: "red" }}>{errors.rightTableData.message}</span>
                        )}
                    </Box>
                </SimpleGrid>

                <SimpleGrid
                    columns={[1, 2, 4, 5]}
                    spacing={2} mb={5} ml={2}
                    p={4}
                >
                    <Box>
                        <FormControl>
                            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Veículo Próprio</FormLabel>
                            <Checkbox {...register("VeiculoProprio")} isChecked={veiculoProprio} background={"white"} />
                        </FormControl>
                    </Box>
                    {!veiculoProprio && (
                        <Box>
                            <FormControl>
                                <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Transportadora</FormLabel>
                                <Input isDisabled={!veiculoProprio} {...register("Transportadora")} background={"white"} type="input" id="Transportadora" />
                            </FormControl>
                        </Box>
                    )}
                    <Box>
                        <FormControl>
                            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Placa Veículo</FormLabel>
                            <Input {...register("PlacaVeiculo")} background={"white"} type="input" id="PlacaVeiculo" />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Peso KG:</FormLabel>
                            <Input {...register("PesoTotal")} background={"white"} isDisabled value={PesoTotal} type="input" id="PesoTotal" onChange={(e) => setPesoTotal(e.target.value)} />
                        </FormControl>
                    </Box>
                    <Box></Box>
                    <Box gridColumn="1 / -1">
                        <FormControl >
                            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Observacão</FormLabel>
                            <Textarea {...register("Observacoes")} width={"100%"} background={"white"} type="input" id="Observacao" />
                        </FormControl>
                    </Box>
                    <Box>
                        <Flex minWidth="950px" alignItems="center" mt={8} direction={{ base: "column", md: "row" }}>
                            <Button
                                w="auto"
                                fontSize={{ base: "12px", lg: "16px" }}
                                size='md'
                                colorScheme="blue"
                                onClick={handleSubmit(onSubmit)}
                                borderRadius="0"
                                mb={0}
                            >
                                Salvar
                            </Button>
                            <Button
                                type="button"
                                w="auto"
                                fontSize={{ base: "12px", lg: "16px" }}
                                size="md"
                                colorScheme="green"
                                onClick={updateStatus}
                                ml={2}
                                mb={0}
                                borderRadius="0"
                            >
                                {getButtonLabel()}
                            </Button>
                            {codigo && (
                                <>
                                    <Button
                                        w="auto"
                                        ml={2}
                                        colorScheme="teal"
                                        size="md"
                                        fontSize={{ base: "12px", lg: "16px" }}
                                        onClick={handleClickPdf}
                                        leftIcon={<Icon as={MdPictureAsPdf} />}
                                        mb={0}
                                        borderRadius="0"
                                    >
                                        Resumo para Entrega
                                    </Button>
                                    <Button
                                        w="auto"
                                        ml={2}
                                        colorScheme="teal"
                                        size="md"
                                        fontSize={{ base: "12px", lg: "16px" }}
                                        onClick={handleClickPdfPedidos}
                                        leftIcon={<Icon as={MdPictureAsPdf} />}
                                        mb={0}
                                        borderRadius="0"
                                    >
                                        Resumo para Carregamento
                                    </Button>
                                    <Button
                                        w="auto"
                                        ml={2}
                                        colorScheme="teal"
                                        size="md"
                                        fontSize={{ base: "12px", lg: "16px" }}
                                        onClick={handleClickPdV2}
                                        leftIcon={<Icon as={MdPictureAsPdf} />}
                                        mb={0}
                                        borderRadius="0"
                                    >
                                        Resumo para Entraga Detalhado
                                    </Button>
                                </>
                            )}
                        </Flex>
                    </Box>
                    {/* <Box>
                        
                    </Box> */}
                    {/* <Box mt="auto">
                    <Button colorScheme="blue">Salvar</Button>
                </Box> */}
                </SimpleGrid>
            </SimpleGrid >
        </Box >
    );
}


