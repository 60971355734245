import axios from 'axios';
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

function extrairDia(data) {
  const partes = data.split('-');
  return parseInt(partes[2]);
}

export default function DiarioVendas({ data, valorMes, mes, anos }) {
  const [barChartDataConsumption, setbarChartDataConsumption] = useState([]);
  const [valor, setValor] = useState([]);
  const [text, setText] = useState('');
  const [ano, setAno] = useState('');


  const meses_select = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  useEffect(() => {

    // console.log(mes);

    setbarChartDataConsumption(data);
    setAno(anos);
    setValor(valorMes);
    setText(meses_select[mes - 1]);
  }, [data]);

  const categ = [];
  const series = {};
  const line = {};

  let hoje = new Date();
  let primeiroDiaDoMes = new Date(hoje.getFullYear(), hoje.getMonth(), 1).getDate();
  let ultimoSegundoDoMes = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0).getDate();

  let maxValorY = Number.MIN_VALUE;
  let menorValorY = Number.MAX_VALUE;
  let valorTotalGeral = 0;

  for (let i = 0; i < barChartDataConsumption.length; i++) {
    const dado = barChartDataConsumption[i];
    const name = dado.Status;

    if (!categ.includes(extrairDia(dado.Data))) {
      categ.push(extrairDia(dado.Data));
    }

    if (!series[name]) {
      series[name] = {
        name: name,
        type: 'area',
        data: [],
      };
    }

    const novoPonto = {
      x: extrairDia(dado.Data),
      y: dado.valorTotal,
    };

    valorTotalGeral += dado.valorTotal;

    series[name].data.push(novoPonto);

    series[name].data.sort((a, b) => a.x - b.x);

    if (dado.valorTotal > maxValorY) {
      maxValorY = dado.valorTotal;
    }
    if (dado.valorTotal < menorValorY) {
      menorValorY = dado.valorTotal;
    }
  }

  Object.keys(series).forEach(name => {
    const pontosDaSerie = series[name].data;

    for (let dia = primeiroDiaDoMes; dia <= ultimoSegundoDoMes; dia++) {
      if (!pontosDaSerie.some(ponto => ponto.x === dia)) {
        pontosDaSerie.push({
          x: dia,
          y: 0,
        });
      }
    }
    pontosDaSerie.sort((a, b) => a.x - b.x);
  });

  const seriesArray = [...Object.values(series), ...Object.values(line)];
  const title = text+"/"+ano+" - Valor Total do Período - R$"+valorTotalGeral.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  const options = {
    chart: {
      type: 'area',
      // width: "100%",
      height: 350,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
    },
    title: {
      text: title,
      align: 'center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: 'white'
      },
    },
    tooltip: {
      theme: "dark",
      enabled: true,
      style: {
        fontSize: '14px', // Ajuste o tamanho da fonte conforme necessário
        background: '#2c3e50', // Cor de fundo desejada
        color: '#ffffff', // Cor do texto desejada
      },
      onDatasetHover: {
        highlightDataSeries: true,
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      width: 4
    },
    xaxis: {
      type: 'numeric',
      min: 0,
      max: ultimoSegundoDoMes,
      tickAmount: 30,
      tickPlacement: 'on',
      tickInterval: 1,
      labels: {
        formatter: function (value) {
          return Math.floor(value).toString();
        },
        style: {
          colors: "white",
        }
      },
    },
    yaxis: {
      show: true,
      labels: {
        formatter: function (value) {
          return value ? 'R$ ' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : '';
        },
        style: {
          colors: "white",
        }
      },
      // forceNiceScale: true
    },
    markers: {
      size: 5,
      // colors: ["#000524"],
      strokeColor: "#00BAEC",
      strokeWidth: 3
    },
    legend: {
      labels: {
        colors: "white",  // Altere aqui para a cor desejada
      },
    },
    responsive: [
      {
        breakpoint: 768, // Define o ponto de quebra para telas menores
        options: {
          title: {
            style: {
              fontSize: '14px' // Tamanho do título para telas menores
            }
          }
        }
      }
      // Você pode adicionar mais breakpoints e opções conforme necessário
    ]
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Card bg="#0B0B61" color="white" borderRadius={0} align='center' direction='column' w='100%'>
      <Flex align='center' w='100%' px='15px' py='10px'>
        <Text
          me='auto'
          // color={textColor}
          color="white"
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'>
          Evolução de Vendas Diárias 
          {/* - {text} */}
        </Text>
      </Flex>
      {/* <Flex align='center' w='100%' px='15px'>
        <Text
          me='auto'
          color="white"
          fontSize='sm'  // Tamanho menor
          lineHeight='100%'>
          {title}
        </Text>
      </Flex> */}

      <Box h='400px' mt='auto' >
        <ReactApexChart
          options={options}
          series={seriesArray}
          type='area'
          width='100%'
          height='100%'
        />
      </Box>
    </Card>
  );
}
