import axios from 'axios';
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import LineChart from "components/charts/BarChart";
import React, { useState, useEffect } from "react";
import { MdBarChart } from "react-icons/md";
import ReactApexChart from "react-apexcharts";
// import {
//   barChartDataConsumption,
//   barChartOptionsConsumption,
// } from "variables/charts";

export default function Mensal({ data, anos }) {
  // const { ...rest } = props;
  const [barChart, setbarChart] = useState([]);
  const [ano, setAno] = useState('');

  let VtotalEntrada = 0;
  let VtotalSaida = 0;

  useEffect(() => {
    setbarChart(data)
    setAno(anos)
    // (async () => {
    //   const { data } = await axios.get('http://localhost:4000/anual');
    //   setbarChart(data);
    // })();
  }, [data]);

  const mesesAno = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const series = {};
  for (let i = 0; i < barChart.length; i++) {
    const dado = barChart[i];

    let name = "";
    if (dado.Despesa) {
      name = "Despesa"

      // VtotalSaida += dado.vpago;
    } else {
      name = "Receita"

      // VtotalEntrada += dado.vpago;
    }

    // Cria uma série se ainda não existe para o status atual
    if (!series[name]) {
      series[name] = {
        name: name,
        data: [],
      };
    }

    // Adiciona um ponto à série com as informações do dado atual
    // const novoPonto = {
    //   x: dado.Mes,
    //   y: dado.vpago
    //   // valorTotalFormatado: dado.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
    // };

    let novoPonto = {}

    if (dado.Despesa) {
      novoPonto = {
        x: dado.Mes,
        y: dado.vSaida
        // valorTotalFormatado: dado.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
      };

      VtotalSaida += dado.vSaida

    } else {
      novoPonto = {
        x: dado.Mes,
        y: dado.vEntrada
        // valorTotalFormatado: dado.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
      };

      VtotalEntrada += dado.vEntrada
    }

    series[name].data.push(novoPonto);

    // Ordena os pontos da série em ordem crescente com base no valor de x
    series[name].data.sort((a, b) => a.x - b.x);
  }

  for (const serie of Object.values(series)) {
    const dataMonths = serie.data.map(ponto => ponto.x);
    for (const month of mesesAno) {
      if (!dataMonths.includes(month)) {
        serie.data.push({ x: month, y: 0 });
      }
    }
  }

  const seriesArray = Object.values(series);
  const title = ano +" - Total Receitas R$" + VtotalEntrada.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) + " | Total Despesas R$" + VtotalSaida.toLocaleString('pt-BR', { minimumFractionDigits: 2 });

  // console.log(seriesArray)

  const mesesNaOrdemDesejada = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  // Mapeamento de meses para garantir a ordem correta
  const mapaMeses = {};
  mesesNaOrdemDesejada.forEach((mes, index) => {
    mapaMeses[mes] = index;
  });

  // Função para reorganizar os dados no seriesArray
  const reorganizarSeriesArray = (seriesArray, mesesMap) => {
    return seriesArray.map(series => ({
      ...series,
      data: series.data.sort((a, b) => mesesMap[a.x] - mesesMap[b.x])
    }));
  };

  // Aplicar a reorganização
  const seriesArrayReorganizado = reorganizarSeriesArray(seriesArray, mapaMeses);


  const options = {
    chart: {
      type: 'bar',
      height: 500,
      stacked: false,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: false
      }
    },
    title: {
      text: title,
      align: 'center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: 'white'
      },
    },
    tooltip: {
      theme: "dark",
      enabled: true,
      style: {
        fontSize: '14px', // Ajuste o tamanho da fonte conforme necessário
        background: '#2c3e50', // Cor de fundo desejada
        color: '#ffffff', // Cor do texto desejada
      },
      onDatasetHover: {
        highlightDataSeries: true,
      },
    },
    plotOptions: {
      bar: {
        barHeight: '80%', // ajuste o valor de barHeight para controlar a altura das barras
        barWidth: '50%',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      // categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      categories: mesesAno,
      labels: {
        style: {
          colors: "white",
        }
      },
    },
    yaxis: {
      show: true,
      labels: {
        formatter: function (value) {
          return 'R$ ' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        style: {
          colors: "white"
        }
      },
    },
    legend: {
      labels: {
        colors: "white",  // Altere aqui para a cor desejada
      },
    },
    fill: {
      opacity: 1
    },
  };

  return (
    <Card bg="#0B0B61" align='center' direction='column' w='100%'>
      <Flex align='center' w='100%' px='15px' py='10px'>
        <Text
          me='auto'
          color={"white"}
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'>
          Fluxo de Caixa Anual
        </Text>
      </Flex>
      <Box h='400px' mt='auto'>
        {/* <LineChart
            chartData={bar}
            chartOptions={lineChartOptionsTotalSpent}
          /> */}
        <ReactApexChart
          options={options}
          series={seriesArrayReorganizado}
          type='bar'
          width='100%'
          height='100%'
        />
      </Box>
    </Card>
  )
}