import api from 'contexts/api';
import { Avatar, Box, Flex, FormLabel, FormControl, Input, Icon, Select, SimpleGrid, Grid, useColorModeValue, Button, Text, Spinner } from "@chakra-ui/react";
import { HiOutlineShoppingCart } from "react-icons/hi";
import AsyncStorage from '@react-native-async-storage/async-storage';
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect, useCallback } from "react";
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy } from "react-icons/md";
import DiarioVendas from "views/admin/default/components/DiarioVendas";
import Maps from "views/admin/default/components/maps";
import VendedorChart from "views/admin/default/components/VendedorChart";
import Mensal from "views/admin/default/components/Mensal";
import Forma from "views/admin/default/components/formaPag";
import Conversao from "views/admin/default/components/conversao";
import Produtos from "views/admin/default/components/produtos";
import Categ from "views/admin/default/components/categoriaProduto";
import Clientes from "views/admin/default/components/clientes";
import Financeiro from "views/admin/Financeiro";
import Uf from "views/admin/default/components/uf";
import { useHistory } from 'react-router-dom';


function getMesAtual() {
  const now = new Date();
  const mesAtual = String(now.getMonth() + 1).padStart(2, '0'); // +1 porque os meses começam de zero
  return mesAtual;
}


export default function UserReports() {
  const meses_select = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' },
  ];

  const meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
  const [mes, setMes] = useState(getMesAtual());
  const [empresa, setEmpresa] = useState('');
  const [status, setStatus] = useState('');
  const [origem, setOrigens] = useState('');
  const [anos, setAnos] = useState(obterAnoAtual());
  const [token, setToken] = useState('');
  const [resumo, setResumofiltro] = useState('');
  const [vendedores, setVendedores] = useState([]);
  const [vendas, setVendas] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [forma, setForma] = useState([]);
  const [TipoData, setTipoData] = useState('Data');
  const [vendasResumo, setResumo] = useState([]);
  const [mesResumo, setMesResumo] = useState([]);
  const [anualResumo, setAnualResumo] = useState([]);
  const [VendasAnual, setVendasAnual] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [estados, setEstados] = useState([]);
  const [conversao, setConversao] = useState([]);
  const [categ, setCateg] = useState([]);
  const [perfil, setPerfil] = useState('');
  const [vendedor, setVendedor] = useState('');
  const [name, setName] = useState('');

  const history = useHistory();

  const getData = async () => {
    try {
      const param = []
      const value = await AsyncStorage.getItem('@storage_Key')
      param.push(value);
      const perf = await AsyncStorage.getItem('@perfil')
      param.push(perf);
      const vende = await AsyncStorage.getItem('@vendedor')
      param.push(vende);
      const vend = await AsyncStorage.getItem('@name')
      param.push(vend);

      // setVendedor(vend);
      // setPerfil(perf);
      if (param !== null) {
        return param;// value previously stored
      }
    } catch (e) {
      alert('Error');
    }
  }

  // PREENCHE TODOS OS DADOS DOS GRAFICOS
  useEffect(async () => {
    const param = {}
    const key = await getData();

    if ((typeof key !== 'undefined') && key !== null) {
      param.user = key[0];
      param.perfil = key[1];
      param.name = key[3];
      param.vendedor = key[2];

      setVendedor(key[2]);
      setPerfil(key[1]);
      setName(key[3]);

      // console.log(param)

      const fetchData = async () => {
        setIsLoading(true);

        try {
          const { data } = await api.get(`/vendas`, { params: param, timeout: 500000 });
          // const { filtros } = await api.get(`/vendas_filters`, { params: param, timeout: 500000 });


          setToken(key[0]);
          setProdutos(data[0]);
          setVendas(data[1]);
          setForma(data[2]);
          setVendedores(data[3]);
          setResumo(data[4]);
          setMesResumo(data[5]);
          setAnualResumo(data[8]);
          setVendasAnual(data[9]);
          setClientes(data[10]);
          setEstados(data[12])
          setConversao(data[13])
          setCateg(data[14])

          // setAnos();

          // console.log();

          const select_origens = [...new Set(data[6].map(item => item.origemVenda))];
          const select_empresas = [...new Set(data[7].map(item => item.NomeFantasia))];
          const years = [...new Set(data[11].map(item => item.ano))];

          // PREENCHE O SELECT BOX DE EMPRESAS
          const selectEmpresas = document.getElementById("select-empresas");
          select_empresas.forEach(empresa => {
            const option = document.createElement("option");
            option.value = empresa;
            option.text = empresa;
            selectEmpresas.appendChild(option);
          });

          const selectOrigens = document.getElementById("select-origens");
          select_origens.forEach(origemVenda => {
            const option = document.createElement("option");
            option.value = origemVenda;
            option.text = origemVenda;
            selectOrigens.appendChild(option);
          });

          const selectYears = document.getElementById("years");
          years.forEach(ano => {
            const option = document.createElement("option");
            option.value = ano;
            option.text = ano;
            selectYears.appendChild(option);
          });

        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false); // Define o estado isLoading como false após a chamada Axios
        }
      };



      fetchData();
    } else {
      history.push('/');
    }

  }, []);


  function obterAnoAtual() {
    const dataAtual = new Date();
    const anoAtual = dataAtual.getFullYear();
    return anoAtual;
  }


  const handleSubmit = (errors) => {
    const data = {}

    if ((typeof token !== 'undefined') && token !== null) {
      data.user = token
      data.perfil = perfil
      data.vendedor = vendedor
      data.name = name

      console.log(token)

      if ((typeof status !== 'undefined') && status !== null) {
        data.status = status
      }

      if ((typeof empresa !== 'undefined') && empresa !== null) {
        data.empresa = empresa
      }

      if ((typeof origem !== 'undefined') && origem !== null) {
        data.origem = origem
      }

      if ((typeof resumo !== 'undefined') && resumo !== null) {
        data.resumo = resumo
      }

      if ((typeof anos !== 'undefined') && anos !== null) {
        data.anos = anos
      }

      if ((typeof TipoData !== 'undefined') && TipoData !== null) {
        data.TipoData = TipoData
      }

      if ((mes !== '') && mes !== null) {
        data.mes = mes
      }


      // console.log(data)
      if (data !== '') {
        setIsLoading(true);

        api.get('/vendas', { params: data, timeout: 500000 })
          .then(response => {
            // const {  } = response.data;

            // console.log(response)
            // if (data !== null) {
            // console.log(response.data[0])
            setProdutos(response.data[0]);
            setVendas(response.data[1]);
            setForma(response.data[2]);
            setVendedores(response.data[3]);
            setResumo(response.data[4]);
            setMesResumo(response.data[5]);
            setAnualResumo(response.data[8]);
            setVendasAnual(response.data[9]);
            setClientes(response.data[10]);
            setEstados(response.data[12]);
            setConversao(response.data[13]);
            setCateg(response.data[14]);
            // }
            // console.log(response.data[4]);
          })
          .catch(error => {
            console.error(error);
          }).finally(() => {
            setIsLoading(false)
          });
      }
    } else {
      alert('Erro no acesso');
      history.push('/');
    }
  };


  return (
    <Box mt={{ sm: '140px', md: '12%', lg: '15%', xl: '6%' }}>
      {/* <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' display={isLoading ? 'block' : 'none'} /> */}
      {isLoading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="rgba(0, 0, 0, 0.5)" // Define a cor de fundo como transparente com um leve tom escuro (50% de opacidade)
          zIndex="9999"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            color="blue.500"
            size="xl"
          />
        </Box>
      )}

      <SimpleGrid
        width={"100%"}
        color={'white'}
        // bg="linear-gradient(0deg, rgba(167,225,226,0.6114946466867996) 0%, rgba(45,238,253,0.6086935262386204) 100%)"
        // bg="#1E90FF"
        border="2px solid white"
        columns={[1, 2, 3, 6]}
        spacing={8} ml={2} rounded="md" p={6}
      >
        <Box colSpan={2}>
          <Text fontSize={{ md: '10px', lg: '13px', xl: '20px' }} fontWeight="bold" >
            Filtros de Pesquisa
          </Text>
        </Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box></Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Empresas:</FormLabel>
            <Select id="select-empresas" onChange={(e) => setEmpresa(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Origem da Venda:</FormLabel>
            <Select id="select-origens" onChange={(e) => setOrigens(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Status da Venda:</FormLabel>
            <Select onChange={(e) => setStatus(e.target.value)}>
              <option value="Pedido Faturado">Pedido Faturado</option>
              <option value="all">Todos</option>
              <option value="Pedido">Pedido</option>
              <option value="Orçamento">Orçamento</option>
              <option value="Pedido Nao Faturado">Pedido não Faturado</option>
              <option value="Pedido Aprovado Sem Faturamento">Pedido Aprovado Sem Faturamento</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Tipo Data:</FormLabel>
            <Select id="select-data" onChange={(e) => setTipoData(e.target.value)}>
              <option value="Data">Data da Venda</option>
              <option value="DataAprovacaoPedido">Data Aprovação</option>
              <option value="DataFaturamento">Data Faturamento</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Selecione o Ano:</FormLabel>
            <Select value={anos} id="years" onChange={(e) => setAnos(e.target.value)}>
              {/* <option value="">Todas</option> */}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Selecione o Mês:</FormLabel>
            <Select id="mes" value={mes} onChange={(e) => setMes(e.target.value)}>
              <option value="">Selecione o mês</option>
              {meses_select.map((mes) => (
                <option key={mes.value} value={mes.value}>
                  {mes.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Resumos:</FormLabel>
            <Select id="select-resumos" onChange={(e) => setResumofiltro(e.target.value)}>
              <option value="">Todas</option>
              <option value="1">Produtos</option>
              <option value="2">Serviços</option>
            </Select>
          </FormControl>
        </Box>
        <Box mt="auto">
          <Button colorScheme="blue" onClick={handleSubmit}>Filtrar</Button>
        </Box>
      </SimpleGrid>

      <SimpleGrid mt={2} columns={[1, 2, 3, 4]} display={{ md: 'flex' }} rounded="sm" p={2} width={"100%"}>
        {vendasResumo[0] ? (
          <Box borderRadius="md" m={1} boxShadow="md" p={4} w={{ md: '24%', lg: '24%', xl: '25%' }} bg="#5ce1e6">
            <Text fontSize={{ md: '13px', lg: '18px', xl: '28px' }} fontWeight="bold" textAlign="center">Vendas de Ontem</Text>
            <Text fontSize="" fontWeight="bold" textAlign="center">{vendasResumo[0].Data}</Text>
            <Flex alignItems="center" justifyContent="center" mb={2}>
              <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">
                {
                  vendasResumo[0].tipo === '1' ? (
                    <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">
                      Produtos R$ {vendasResumo[0].valorTotal}
                    </Text>
                  ) : vendasResumo[0].tipo === '2' ? (
                    <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">
                      Serviços: R$ {vendasResumo[0].valorTotal}
                    </Text>
                  ) : `Total: R$ ${vendasResumo[0].valorFinal}`
                }
              </Text>
              <IconBox ml={2} icon={
                <Icon w={['12px', '20px', '30px', '36px']} h={['10px', '17px', '28px', '32px']} as={HiOutlineShoppingCart} />
              }
              />
            </Flex>
            <Flex justifyContent="space-between" mb={2}>
              <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">Qtd: {vendasResumo[0].vendas}</Text>
              {
                vendasResumo[0].tipo === '1' ? (
                  <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">
                    Ticket Medio: R$ {vendasResumo[0].ticket}
                  </Text>
                ) : vendasResumo[0].tipo === '2' ? (
                  <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">
                    Ticket Medio: R$ {vendasResumo[0].ticket}
                  </Text>
                ) : (
                  <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">
                    Ticket Medio: R$ {vendasResumo[0].ticket}
                  </Text>
                )
              }
            </Flex>
          </Box>
        ) : (
          <Box borderRadius="md" m={1} boxShadow="md" p={4} w={{ md: '24%', lg: '24%', xl: '25%' }} bg="#5ce1e6">
            <Text fontSize={{ md: '13px', lg: '18px', xl: '28px' }} fontWeight="bold" textAlign="center">Vendas de Ontem</Text>
            <Text fontSize="" fontWeight="bold" textAlign="center">Valores Zerados</Text>
            <Flex alignItems="center" justifyContent="center" mb={2}>
              <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">R$ 0,00</Text>
              <IconBox ml={2} icon={
                <Icon w={['12px', '20px', '30px', '36px']} h={['10px', '17px', '28px', '32px']} as={HiOutlineShoppingCart} />
              }
              />
            </Flex>
            <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }}>Qtd: 0</Text>
          </Box>
        )}

        {vendasResumo[1] ? (
          <Box borderRadius="md" m={1} boxShadow="md" p={4} w={{ md: '24%', lg: '24%', xl: '25%' }} bg="#38b6ff">
            <Text fontSize={{ md: '13px', lg: '18px', xl: '28px' }} fontWeight="bold" textAlign="center">Vendas de Hoje</Text>
            <Text fontSize="" fontWeight="bold" textAlign="center">{vendasResumo[1].Data}</Text>
            <Flex alignItems="center" justifyContent="center" mb={2}>
              <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">
                {
                  vendasResumo[1].tipo === '1' ? (
                    <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">
                      Produtos R$ {vendasResumo[1].valorTotal}
                    </Text>
                  ) : vendasResumo[1].tipo === '2' ? (
                    <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">
                      Serviços: R$ {vendasResumo[1].valorTotal}
                    </Text>
                  ) : `Total: R$ ${vendasResumo[1].valorFinal}`
                }
              </Text>
              <IconBox ml={2} icon={
                <Icon w={['12px', '20px', '30px', '36px']} h={['10px', '17px', '28px', '32px']} as={HiOutlineShoppingCart} />
              }
              />
            </Flex>
            <Flex justifyContent="space-between" mb={2}>
              <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">Qtd: {vendasResumo[1].vendas}</Text>
              {
                vendasResumo[0].tipo === '1' ? (
                  <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">
                    Ticket Medio: R$ {vendasResumo[1].ticket}
                  </Text>
                ) : vendasResumo[0].tipo === '2' ? (
                  <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">
                    Ticket Medio: R$ {vendasResumo[1].ticket}
                  </Text>
                ) : (
                  <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">
                    Ticket Medio: R$ {vendasResumo[1].ticket}
                  </Text>
                )
              }
            </Flex>
          </Box>
        ) : (
          <Box borderRadius="md" m={1} boxShadow="md" p={4} w={{ md: '24%', lg: '24%', xl: '25%' }} bg="#1E90FF">
            <Text fontSize={{ md: '13px', lg: '18px', xl: '28px' }} fontWeight="bold" textAlign="center">Vendas de Hoje</Text>
            <Text fontSize="" fontWeight="bold" textAlign="center">Valores Zerados</Text>
            <Flex alignItems="center" justifyContent="center" mb={2}>
              <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">R$ 0,00</Text>
              <IconBox ml={2} icon={
                <Icon w={['12px', '20px', '30px', '36px']} h={['10px', '17px', '28px', '32px']} as={HiOutlineShoppingCart} />
              }
              />
            </Flex>
            <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }}>Quantidade: 0</Text>
          </Box>
        )}

        {mesResumo.Data ? (
          <Box borderRadius="md" m={1} boxShadow="md" p={4} w={{ md: '24%', lg: '24%', xl: '25%' }} bg="#1E90FF">
            <Text fontSize={{ md: '13px', lg: '18px', xl: '28px' }} fontWeight="bold" textAlign="center">Vendas Mês Atual</Text>
            <Text fontSize="" fontWeight="bold" textAlign="center">{meses[parseInt(mesResumo.Data) - 1] + '/' + anos}</Text>
            <Flex alignItems="center" justifyContent="center" mb={2}>
              <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">
                {
                  mesResumo.tipo === '1' ? (
                    <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">
                      Produtos R$ {mesResumo.valorFinal}
                    </Text>
                  ) : mesResumo.tipo === '2' ? (
                    <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">
                      Serviços: R$ {mesResumo.valorFinal}
                    </Text>
                  ) : `Total: R$ ${mesResumo.valorFinal}`
                }
              </Text>
              <IconBox ml={2} icon={
                <Icon w={['12px', '20px', '30px', '36px']} h={['10px', '17px', '28px', '32px']} as={HiOutlineShoppingCart} />
              }
              />
            </Flex>
            <Flex justifyContent="space-between" mb={2}>
              <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">Qtd: {mesResumo.vendas}</Text>
              {
                mesResumo.tipo === '1' ? (
                  <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">
                    Ticket Medio: R$ {mesResumo.ticket}
                  </Text>
                ) : mesResumo.tipo === '2' ? (
                  <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">
                    Ticket Medio: R$ {mesResumo.ticket}
                  </Text>
                ) : (
                  <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">
                    Ticket Medio: R$ {mesResumo.ticket}
                  </Text>
                )
              }
            </Flex>
          </Box>
        ) : (
          <Box borderRadius="md" m={1} boxShadow="md" p={4} w={{ md: '24%', lg: '24%', xl: '25%' }} bg="#ADD8E6">
            <Text fontSize={{ md: '13px', lg: '18px', xl: '28px' }} fontWeight="bold" textAlign="center">Vendas Mês Atual</Text>
            <Text fontSize="" fontWeight="bold" textAlign="center">Valores Zerados</Text>
            <Flex alignItems="center" justifyContent="center" mb={2}>
              <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">R$ 0,00</Text>
              <IconBox ml={2} icon={
                <Icon w={['12px', '20px', '30px', '36px']} h={['10px', '17px', '28px', '32px']} as={HiOutlineShoppingCart} />
              }
              />
            </Flex>
            <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }}>Qtd: 0</Text>
          </Box>
        )}
        {anualResumo.Data ? (
          <Box borderRadius="md" m={1} boxShadow="md" p={4} w={{ md: '24%', lg: '24%', xl: '25%' }} bg="#ADD8E6">
            <Text fontSize={{ md: '13px', lg: '18px', xl: '28px' }} fontWeight="bold" textAlign="center">Vendas Ano Atual</Text>
            <Text fontSize="" fontWeight="bold" textAlign="center">{anualResumo.Data}</Text>
            <Flex alignItems="center" justifyContent="center" mb={2}>
              <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">
                {
                  anualResumo.tipo === '1' ? (
                    <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">
                      Produtos R$ {anualResumo.valorFinal}
                    </Text>
                  ) : anualResumo.tipo === '2' ? (
                    <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">
                      Serviços R$ {anualResumo.valorFinal}
                    </Text>
                  ) : `Total: R$ ${anualResumo.valorFinal}`
                }
              </Text>
              <IconBox ml={2} icon={
                <Icon w={['12px', '20px', '30px', '36px']} h={['10px', '17px', '28px', '32px']} as={HiOutlineShoppingCart} />
              }
              />
            </Flex>
            <Flex justifyContent="space-between" mb={2}>
              <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">Qtd: {anualResumo.vendas}</Text>
              {
                anualResumo.tipo === '1' ? (
                  <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">
                    Ticket Medio: R$ {anualResumo.ticket}
                  </Text>
                ) : anualResumo.tipo === '2' ? (
                  <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">
                    Ticket Medio: R$ {anualResumo.ticket}
                  </Text>
                ) : (
                  <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }} fontWeight="bold">
                    Ticket Medio: R$ {anualResumo.ticket}
                  </Text>
                )
              }
            </Flex>
          </Box>
        ) : (
          <Box borderRadius="md" m={1} boxShadow="md" p={4} w={{ md: '24%', lg: '24%', xl: '25%' }} bg="#5ce1e6">
            <Text fontSize={{ md: '13px', lg: '18px', xl: '28px' }} fontWeight="bold" textAlign="center">Vendas Ano Atual</Text>
            <Text fontSize="" fontWeight="bold" textAlign="center">{obterAnoAtual()}</Text>
            {/* <Text fontSize="" fontWeight="bold" textAlign="center">Valores Zerados</Text> */}
            <Flex alignItems="center" justifyContent="center" mb={2}>
              <Text fontSize={{ md: '10px', lg: '13px', xl: '15px' }} fontWeight="bold">R$ 0,00</Text>
              <IconBox ml={2} icon={
                <Icon w={['12px', '20px', '30px', '36px']} h={['10px', '17px', '28px', '32px']} as={HiOutlineShoppingCart} />
              }
              />
            </Flex>
            <Text fontSize={{ md: '10px', lg: '12px', xl: '14px' }}>Qtd: 0</Text>
          </Box>
        )}
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='10px' mb='20px'>

        <DiarioVendas data={vendas} valorMes={mesResumo} mes={mes} anos={anos} />


        {/* <Mensal data={VendasAnual} /> */}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='10px' mb='20px'>
        <Mensal data={VendasAnual} anos={anos} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <VendedorChart data={vendedores} mes={mes} anos={anos} />
        {forma ? (
          <Forma data={forma} mes={mes} anos={anos} />
        ) : (
          <Forma />
        )}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 2, md: 1, xl: 1 }} display={{ md: 'flex' }} mb='20px'>
        {/* <Maps data={estados} mes={mes} anos={anos} /> */}
        <Uf data={estados} mes={mes} anos={anos} />
      </SimpleGrid>
      <SimpleGrid gap='20px' mb='20px'>
        {/* <VendedorChart data={vendedores} />  columns={{ base: 1, md: 2, xl: 2 }}*/}
        {/* {forma ? (
          <Conversao data={conversao} mes={mes} anos={anos} />
        ) : (
          <Conversao />
        )} */}
        {categ ? (
          <Categ data={categ} mes={mes} anos={anos} />
        ) : (
          <Categ />
        )}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <Produtos data={produtos} />
        <Clientes data={clientes} />
      </SimpleGrid>
      {perfil != 4 && (
        <>
          <Text
            color={"white"}
            // color={text ? text : ''}
            bg='inherit'
            borderRadius='inherit'
            fontWeight='bold'
            fontSize='34px'
            // _hover={{ color: { mainText } }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}>
            Visão Financeira
          </Text>
          <Financeiro></Financeiro>
        </>
      )}
    </Box>
  );
}
