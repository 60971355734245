import React, { forwardRef, useRef } from 'react';
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Box, Button, Text } from "@chakra-ui/react";
import jsPDF from 'jspdf';
import 'jspdf-autotable';


function somarArray(array) {
  var soma = 0;
  for (var elemento of array) {
    soma += elemento;
  }
  return soma;
}


const TabelaFinanceira = ({ dados }) => {

  const gerarPDF = () => {
    const doc = new jsPDF();

    const boldStyle = { font: 'bold', fontSize: 12 };
    const normalStyle = { font: 'normal', fontSize: 12 };

    const CentroDeCusto = [...new Set(dados.map((dado) => dado.CentroDeCusto))];

    const teste = CentroDeCusto.filter((grupo) => dados.some((dado) => dado.CentroDeCusto === grupo));

    console.log(teste);

    const tableData = [];
    tableData.push(['Centro de Custo', 'Plano de Conta', 'Valor', '(%)']);

    var totalDgeral = 0
    var totalRgeral = 0

    teste.map((grupo) => {
      if (grupo) {

        const filteredDados = dados.filter((dado) => {

          if (grupo === "" || grupo === null) {
            return (!dado.CentroDeCusto || dado.CentroDeCusto === "");
          } else {
            return dado.CentroDeCusto === grupo;
          }
        });

        var receitas = [];
        var despesas = [];
        var totalValues = 0;
        var totalDgroup = 0;
        var totalRgroup = 0;


        filteredDados.forEach((dado) => {
          const isDespesa = dado.Despesa === true;
          totalValues += isDespesa ? -dado.ValorTotalPago : dado.ValorTotalPago;
          totalDgroup += isDespesa ? +dado.ValorTotalPago : +0;
          totalRgroup += isDespesa ? +0 : +dado.ValorTotalPago;
          totalDgeral += isDespesa ? +dado.ValorTotalPago : +0;
          totalRgeral += isDespesa ? +0 : +dado.ValorTotalPago;


          if (isDespesa) {
            despesas.push(dado);
            despesasTotal.push(dado);
          } else {
            receitas.push(dado);
            receitasTotal.push(dado);
          }
        });

        tableData.push([grupo, '', '', '']);

        receitas.map((dado) => (
          // const teste = calcularPorcentagem(totalRgroup, dado.ValorTotalPago)

          tableData.push(['', '(+) Receita -' + dado.PlanoDeConta, formatarValor(dado.ValorTotalPago), calcularPorcentagem(totalRgroup, dado.ValorTotalPago)])
        ))
        tableData.push(['', 'Total Receitas:', formatarValor(totalRgroup), '100%'])

        despesas.map((dado) => (
          tableData.push(['', '(-) Despesa - ' + dado.PlanoDeConta, formatarValor(dado.ValorTotalPago), calcularPorcentagem(totalDgroup, dado.ValorTotalPago)])
        ))
        tableData.push(['', 'Total Despesas:', formatarValor(totalDgroup), '100%'])
        tableData.push(['', 'Saldo:', formatarValor(totalRgroup - totalDgroup), '100%'])

      }
    })

    tableData.push(['Receitas geral:', formatarValor(totalRgeral), '', ''])
    tableData.push(['Despesas geral:', formatarValor(totalDgeral), '', ''])
    tableData.push(['Saldo geral:', formatarValor(totalRgeral - totalDgeral), '', ''])


    doc.autoTable({
      head: [tableData[0]],
      body: tableData.slice(1),
      columnStyles: { 0: { fontStyle: 'bold' } },
      // html: { 
      //   styles: { 
      //     'b': { fontStyle: 'bold' } // Define o estilo para a tag HTML <b>
      //   }
      // },
      // startY: doc.previousAutoTable.finalY + 20, // Começar na posição final da tabela anterior
    });

    doc.save("relatorio_DRE.pdf");
  };

  // console.log(dados)

  const totalgeraltrue = [];
  const totalgeralfalse = [];

  // const grupoExistente = [...new Set(dados.map((dado) => dado.CentroDeCusto))];

  const CentroDeCusto = [...new Set(dados.map((dado) => dado.CentroDeCusto))];

  const teste = CentroDeCusto.filter((grupo) => dados.some((dado) => dado.CentroDeCusto === grupo));

  function calcularPorcentagem(total, parcial) {
    // Garante que os argumentos são números
    total = parseFloat(total);
    parcial = parseFloat(parcial);

    // Verifica se os valores são válidos
    if (isNaN(total) || isNaN(parcial) || total === 0) {
      return "Valores inválidos";
    }

    // Calcula a porcentagem
    var porcentagem = (parcial / total) * 100;

    // Arredonda para duas casas decimais
    porcentagem = Math.round(porcentagem * 100) / 100;

    return porcentagem + "%";
  }

  const formatarValor = (valor) => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(valor);
  };


  var receitasTotal = [];
  var despesasTotal = [];


  return (
    <>
      <Table name="dre" variant="striped" w='100%' size="sm" maxWidth="100%">
        {/* Tabela para grupos True */}
        <Thead>
          <Tr>
            <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Centro de Custo</Th>
            <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Plano de Conta</Th>
            <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">Valor</Th>
            <Th textAlign="center" width="25%" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black">(%)</Th>
          </Tr>
        </Thead>
        <Tbody>

          {teste.map((grupo) => {
            const filteredDados = dados.filter((dado) => {


              if (grupo === "" || grupo === null) {
                return (!dado.CentroDeCusto || dado.CentroDeCusto === "");
              } else {
                return dado.CentroDeCusto === grupo;
              }
            });

            // console.log(filteredDados)

            var receitas = [];
            var despesas = [];
            var totalValues = 0;
            var totalDgroup = 0;
            var totalRgroup = 0;


            // console.log(filteredDados)

            filteredDados.forEach((dado) => {
              const isDespesa = dado.Despesa === true;
              totalValues += isDespesa ? -dado.ValorTotalPago : dado.ValorTotalPago;
              totalDgroup += isDespesa ? +dado.ValorTotalPago : +0;
              totalRgroup += isDespesa ? +0 : +dado.ValorTotalPago;

              if (isDespesa) {
                despesas.push(dado);
                despesasTotal.push(dado);
              } else {
                receitas.push(dado);
                receitasTotal.push(dado);
              }
            });

            // console.log(grupo)

            // console.log("Receitas:", receitas);
            // console.log("Despesas:", despesas);
            // console.log("Total Values:",);
            // console.log("Total Despesas:", totalDgroup);
            // console.log("Total Receitas:", totalRgroup);

            // console.log(receitas);
            // console.log(totalDgroup, totalRgroup);

            return (
              grupo !== "" && grupo !== undefined && (
                <React.Fragment key={grupo}>
                  <Tr>
                    <Td colSpan={15}>
                      <Text fontWeight="bold">{grupo || "Sem Centro de Custo"}</Text>
                    </Td>
                  </Tr>
                  {receitas.map((dado) => (
                    <Tr key={dado.PlanoDeConta}>
                      <Td textAlign="center" width="25%"></Td>
                      <Td width="25%"><span style={{ color: 'blue' }}>(+) Receita - </span>{dado.PlanoDeConta}</Td>
                      <Td textAlign="center" width="25%">{formatarValor(dado.ValorTotalPago)}</Td>
                      <Td textAlign="center" width="25%">{calcularPorcentagem(totalRgroup, dado.ValorTotalPago)}</Td>
                    </Tr>
                  ))}
                  <Tr>
                    <Td textAlign="center" width="25%"></Td>
                    <Td textAlign="center" width="25%"><Text fontWeight="bold">Total Receitas: </Text></Td>
                    <Td textAlign="center" width="25%"><Text fontWeight="bold">{formatarValor(totalRgroup)}</Text></Td>
                    <Td textAlign="center" width="25%"><Text fontWeight="bold">100%</Text></Td>
                  </Tr>
                  {despesas.map((dado) => (
                    <Tr key={dado.PlanoDeConta}>
                      <Td textAlign="center" width="25%"></Td>
                      <Td width="25%"><span style={{ color: 'red' }}>(-) Despesa - </span>{dado.PlanoDeConta}</Td>
                      <Td textAlign="center" width="25%">(-) {formatarValor(dado.ValorTotalPago)}</Td>
                      <Td textAlign="center" width="25%">{calcularPorcentagem(totalDgroup, dado.ValorTotalPago)}</Td>
                    </Tr>
                  ))}
                  <Tr>
                    <Td></Td>
                    <Td textAlign="center" width="25%"><Text fontWeight="bold">Total Despesas: </Text></Td>
                    <Td textAlign="center" width="25%"><Text fontWeight="bold"><span>(-) </span>{formatarValor(totalDgroup)}</Text></Td>
                    <Td textAlign="center" width="25%"><Text fontWeight="bold">100%</Text></Td>
                  </Tr>
                  <Tr>
                    <Td></Td>
                    <Td textAlign="center" width="25%"><Text fontWeight="bold">Saldo: </Text></Td>
                    <Td textAlign="center" width="25%"><Text fontWeight="bold">{formatarValor(totalValues)}</Text></Td>
                    <Td></Td>
                  </Tr>
                </React.Fragment>
              )
            );
          })}
        </Tbody>
        <Text mt={3} fontWeight="bold" fontSize={{ base: 'sm', md: 'md', lg: 'lg' }} >Resultado Final:</Text>
        <Tbody>
          {/* Receitas */}
          <Tr>
            <Td><Text fontWeight="bold">Total Receitas</Text></Td>
            {(() => {
              const elementosTd = [];
              for (let i = 0; i < totalgeralfalse.length; i++) {
                const totalReceitasPeriodo = receitasTotal.reduce((acc, dado) => acc + dado.ValorTotalPago, 0);
                elementosTd.push(
                  <Td key={i}>
                    <Text fontWeight="bold">{formatarValor(totalReceitasPeriodo)}</Text>
                  </Td>
                );
              }
              return elementosTd;
            })()}
            <Td><Text fontWeight="bold">{formatarValor(somarArray(receitasTotal.map(dado => dado.ValorTotalPago)))}</Text></Td>
            <Td colSpan={2}></Td>
          </Tr>

          {/* Despesas */}
          <Tr>
            {/* <Td></Td> */}
            <Td><Text fontWeight="bold">Total Despesas</Text></Td>
            {(() => {
              const elementosTd = [];
              for (let i = 0; i < totalgeraltrue.length; i++) {
                const totalDespesasPeriodo = despesasTotal.reduce((acc, dado) => acc + dado.ValorTotalPago, 0);
                elementosTd.push(
                  <Td key={i}>
                    <Text fontWeight="bold">{formatarValor(totalDespesasPeriodo)}</Text>
                  </Td>
                );
              }
              return elementosTd;
            })()}
            <Td><Text fontWeight="bold"><span>(-) </span>{formatarValor(somarArray(despesasTotal.map(dado => dado.ValorTotalPago)))}</Text></Td>
            <Td colSpan={2}></Td>
          </Tr>

          {/* Diferença */}
          <Tr>
            {/* <Td></Td> */}
            <Td><Text fontWeight="bold">Saldo</Text></Td>
            {(() => {
              const elementosTd = [];
              for (let i = 0; i < totalgeralfalse.length; i++) {
                const diferencaSubtraida = receitasTotal.reduce((acc, dado) => acc + dado.ValorTotalPago, 0) - despesasTotal.reduce((acc, dado) => acc + dado.ValorTotalPago, 0);
                elementosTd.push(
                  <Td key={i}>
                    <Text fontWeight="bold">{formatarValor(diferencaSubtraida)}</Text>
                  </Td>
                );
              }
              return elementosTd;
            })()}
            <Td><Text fontWeight="bold">{formatarValor(somarArray(receitasTotal.map(dado => dado.ValorTotalPago)) - somarArray(despesasTotal.map(dado => dado.ValorTotalPago)))}</Text></Td>
            <Td colSpan={2}></Td>
            {/* //- despesasTotal.map(dado => dado.ValorTotalPago))}</Text></Td> */}
          </Tr>
        </Tbody>
      </Table>
      <Button mt={4} colorScheme="blue" onClick={gerarPDF}>
        Gerar PDF
      </Button>
    </>
  );
};

export default TabelaFinanceira;


// ... (código anterior)

// Fora do loop onde 'receitas' é definido

// ... (código posterior)




