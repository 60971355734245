import axios from 'axios';
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import LineChart from "components/charts/BarChart";
import React, { useState, useEffect } from "react";
import { MdBarChart } from "react-icons/md";
import ReactApexChart from "react-apexcharts";


function obterAnoAtual() {
  const dataAtual = new Date();
  const anoAtual = dataAtual.getFullYear();
  return anoAtual;
}



export default function Mensal({ data, mes, anos }) {
  // const { ...rest } = props;
  const [barChart, setbarChart] = useState([]);
  const [estados, setEstados] = useState('');
  const [ds_mes, setMes] = useState('');
  const [ano, setAno] = useState('');

  const meses_select = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];


  useEffect(() => {
    setbarChart(data);
    setMes(meses_select[mes - 1]);
    setAno(anos);
  }, [data]);

  const series = {};
  let valorTotalGeral = 0;

  for (let i = 0; i < barChart.length; i++) {
    const dado = barChart[i];
    const name = "Categorias";

    // Cria uma série se ainda não existe para o status atual
    if (!series[name]) {
      series[name] = {
        name: name,
        data: [],
      };
    }


    // Encontra ou cria um ponto na série com as informações do dado atual
    let pontoExistente = series[name].data.find(ponto => ponto.x === (dado.categoria || "Sem Categoria"));

    if (!pontoExistente) {
      // Se o ponto ainda não existe, cria um novo ponto
      pontoExistente = {
        x: dado.categoria || "Sem Categoria ", // Se dado.UF for vazio ou nulo, use "Sem estado"
        y: 0,
        // valorTotalFormatado: dado.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
      };

      series[name].data.push(pontoExistente);
    }

    // Adiciona o valor atual ao ponto existente
    pontoExistente.y += dado.vTotalVendas;
    valorTotalGeral += dado.valorVendas;

    // Ordena os pontos da série em ordem crescente com base no valor de x
    series[name].data.sort((a, b) => (a.x === "Sem estado" ? 0 : a.x) - (b.x === "Sem Categoria" ? 0 : b.x));
  }

  // console.log(series)

  const seriesArray = Object.values(series);

  const title = ds_mes+"/"+ano+" - Valor Total do Período - R$"+valorTotalGeral.toLocaleString('pt-BR', { minimumFractionDigits: 2 });


  const options = {
    chart: {
      type: 'bar',
      height: 500,
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    title: {
      text: title,
      align: 'center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: 'Helvetica, Arial, sans-serif',
        color: 'white'
      },
    },
    plotOptions: {
      bar: {
        // horizontal: true,
        // columnWidth: '35%',
        distributed: true,
      },
    },
    tooltip: {
      theme: "dark",
      enabled: true,
      style: {
        fontSize: '14px', // Ajuste o tamanho da fonte conforme necessário
        background: '#2c3e50', // Cor de fundo desejada
        color: '#ffffff', // Cor do texto desejada
      },
      onDatasetHover: {
        highlightDataSeries: true,
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      show : true,
      labels: {
        // formatter: function (value) {
        //   return 'R$ ' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        // },
        style: {
          colors: "white",
        }
      }
    },
    yaxis: {
      show: true,
      labels: {
        formatter: function (value) {
          return 'R$ ' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        style: {
          colors: "white",
        }
      },
    },
    fill: {
      opacity: 1
    },
    legend: {
      labels: {
        colors: "white",
        // useSeriesColors: false
      },
    },
    responsive: [
      {
        breakpoint: 768, // Define o ponto de quebra para telas menores
        options: {
          title: {
            style: {
              fontSize: '14px' // Tamanho do título para telas menores
            }
          }
        }
      }
      // Você pode adicionar mais breakpoints e opções conforme necessário
    ]
  }

  return (
    <Card bg="#0B0B61" color="white" borderRadius={0} align='center' w='100%'>
      <Flex align='center' w='100%' px='15px' py='10px'>
        <Text
          me='auto'
          // color={textColor}
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'>
          Evolução Categoria de Produtos
        </Text>
      </Flex>
      {/* <Flex align='center' w='100%' px='15px'>
        <Text
          me='auto'
          color="white"
          fontSize='sm'  // Tamanho menor
          lineHeight='100%'>
          {title}
        </Text>
      </Flex> */}
      <Box h='400px' mt='auto'>
        <ReactApexChart
          options={options}
          series={seriesArray}
          type='bar'
          width='100%'
          height='100%'
        />
      </Box>
    </Card>
  )
}