import React, { useState, useEffect } from 'react';
import { Grid, GridItem, Text, Button, Checkbox, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';

const ModalComponent = ({ data, onValuesSelected, isOpen, onClose }) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [initialSelectedValues, setInitialSelectedValues] = useState([]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (!checked) {
      setSelectedValues((prevSelectedValues) => [...prevSelectedValues, value]);
    } else {
      setSelectedValues((prevSelectedValues) => prevSelectedValues.filter((v) => v !== value));
    }
  };

  const handleSaveClick = () => {
    onValuesSelected(selectedValues);
    onClose();
  };

  useEffect(() => {
    setInitialSelectedValues(selectedValues);
  }, [selectedValues]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="600px">
        <ModalHeader>Selecionar Valores</ModalHeader>
        <ModalCloseButton />
        {data.length > 0 ? (
          <ModalBody>
            {/* {Object.keys(data[0]).map((key, index) => (
              <Checkbox
                key={index}
                value={key}
                onChange={handleCheckboxChange}
                isChecked={initialSelectedValues.includes(key)}
              >
                {key}
              </Checkbox>
            ))} */}
            {/* <Grid templateColumns="repeat(3, 1fr)" gap={1}>
              {Object.keys(data[0]).map((key, index) => (
                <GridItem key={index} w="200px">
                  <Checkbox
                    value={key}
                    onChange={handleCheckboxChange}
                    isChecked={initialSelectedValues.includes(key)}
                    p={2}
                    m={1}
                  >
                    {key}
                  </Checkbox>
                </GridItem>
              ))}
            </Grid> */}
            <Grid templateColumns="repeat(3, 1fr)" gap={1} flexWrap="wrap">
              {Object.keys(data[0]).map((key, index) => (
                <GridItem key={index}>
                  <Checkbox
                    value={key}
                    onChange={handleCheckboxChange}
                    isChecked={!initialSelectedValues.includes(key)}
                    p={2}
                    m={1}
                  >
                    <Text fontSize={['sm', 'md']} fontWeight="normal">
                      {key}
                    </Text>
                  </Checkbox>
                </GridItem>
              ))}
            </Grid>
          </ModalBody>
        ) : ''}
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSaveClick}>
            Salvar
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalComponent;


