import axios from 'axios';

const api = axios.create({
  baseURL: 'https://app.painelerp.com.br/api', // Coloque a URL da sua API aqui
  timeout: 10000, // Tempo limite da requisição em milissegundos
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export default api;

