import api from 'contexts/api';
import {
  Box, FormLabel, FormControl, Input, Icon, Select, SimpleGrid, Button, Text, Spinner, SelectField,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useToast
} from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon } from '@chakra-ui/icons';
import { HiOutlineShoppingCart } from "react-icons/hi";
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import Pagination from 'views/admin/AnaliticoProduto/components/paginate';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as XLSX from 'xlsx/xlsx.mjs';
import { MultiSelect } from "react-multi-select-component";
// import cadastroExpedicao from './components/cadastroExpedicao';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import DataDisplay from './components/table';
import CadastroExpedicao from './components/cadastroExpedicao';
import ModalComponent from './components/modal';

function removeObjectsByKey(objects, keysToRemove) {
  return objects.map(obj => {
    const newObj = {};
    for (let key in obj) {
      if (!keysToRemove.includes(key)) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  });
}

const jsonData = {
  cargaNumero: "000016",
  data: "06/05/2024",
  hora: "10:55:00",
  motorista: "CLEZIVAN LIRA SILVA",
  placa: "ORD-3526",
  observacoes: "",
  valorFrete: "0,00",
  pedidos: [
    { cliente: "JOSEFA GUILHERME DA SILVA - ME", numero: "010068", condicaoPagamento: "A VISTA" },
    { cliente: "ALMEIDA E JUNIOR COMERCIO DE ALIMENTOS LTDA", numero: "E010069", condicaoPagamento: "BOL 15 DIAS" },
    { cliente: "LEITE & PARANHOS LTDA - ME", numero: "010070", condicaoPagamento: "BOL 15 DIAS" },
    { cliente: "MARIA HELENA DIAS DE SOUZA", numero: "010071", condicaoPagamento: "BOL 15 DIAS" }
  ],
  pesoTotal: "4.948,00",
  totalVolumes: "000",
  valorTotal: "1.330,00",
  condicaoPagamento: {
    "A VISTA": "440,00",
    "BOL 15 DIAS": "4.508,00"
  }
};

function generatePDFteste(data) {
  // const { jsPDF } = window.jspdf;
  // const doc = new jsPDF();

  // // Cabeçalho
  // doc.text("Página: 001", 10, 10);
  // doc.text("PRODUTOS ALIMENTICIOS TIO FRANCISCO EIRELI", 10, 20);
  // doc.text(`Protocolo de Saída de Mercadorias - Carga Número: ${data.cargaNumero}`, 10, 30);

  // // Detalhes principais
  // doc.text(`Data: ${data.data}`, 10, 40);
  // doc.text(`Hora: ${data.hora}`, 50, 40);
  // doc.text(`Motorista: ${data.motorista}`, 10, 50);
  // doc.text(`Placa: ${data.placa}`, 50, 50);
  // doc.text(`Observações: ${data.observacoes}`, 10, 60);
  // doc.text(`Valor Frete: ${data.valorFrete}`, 10, 70);

  // // Tabela de pedidos
  // doc.text("Nº NF/Pedido Cliente Condição de Pagamento", 10, 80);
  // let yOffset = 90;
  // data.pedidos.forEach(pedido => {
  //     doc.text(`${pedido.cliente} ${pedido.numero} ${pedido.condicaoPagamento}`, 10, yOffset);
  //     yOffset += 10;
  // });

  // // Resumo dos valores
  // yOffset += 10;
  // doc.text(`Peso Total (Kgs): ${data.pesoTotal}`, 10, yOffset);
  // yOffset += 10;
  // doc.text(`Total de Volumes: ${data.totalVolumes}`, 10, yOffset);
  // yOffset += 10;
  // doc.text(`Valor Total: ${data.valorTotal}`, 10, yOffset);
  // yOffset += 10;
  // doc.text(`Condição de Pagamento:`, 10, yOffset);
  // yOffset += 10;
  // Object.keys(data.condicaoPagamento).forEach(condicao => {
  //     doc.text(`${condicao}: R$ ${data.condicaoPagamento[condicao]}`, 10, yOffset);
  //     yOffset += 10;
  // });

  // // Declaração e assinatura
  // yOffset += 10;
  // doc.text("Declaro ter conferido as mercadorias bem como as notas/pedidos sendo o responsável pela entrega e retorno dos documentos e pagamentos assinados e conferidos.", 10, yOffset);
  // yOffset += 20;
  // doc.text("Assinatura: ____________________________", 10, yOffset);
  // yOffset += 10;
  // doc.text(`${data.valorTotal}`, 10, yOffset);

  // // Desenha bordas
  // const pageWidth = doc.internal.pageSize.getWidth();
  // const pageHeight = doc.internal.pageSize.getHeight();
  // const margin = 10;

  // // Borda da página
  // doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);

  // // Borda da seção de cabeçalho
  // doc.rect(10, 5, pageWidth - 20, 30);

  // // Borda da seção de detalhes principais
  // doc.rect(10, 35, pageWidth - 20, 40);

  // // Borda da tabela de pedidos
  // doc.rect(10, 75, pageWidth - 20, (data.pedidos.length + 1) * 10);

  // // Borda da seção de resumo de valores
  // const resumoYStart = 75 + (data.pedidos.length + 1) * 10 + 10;
  // doc.rect(10, resumoYStart, pageWidth - 20, 50);

  // // Borda da seção de declaração e assinatura
  // const declaracaoYStart = resumoYStart + 50 + 10;
  // doc.rect(10, declaracaoYStart, pageWidth - 20, 40);

  // // Salva o PDF
  // doc.save("Resumo_para_entrega_com_bordas.pdf");
  const doc = new jsPDF();

  // Configurações de estilo
  const margin = 10;
  const padding = 5;
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const tableColumnWidth = (pageWidth - 2 * margin - 2 * padding) / 3;

  console.log(tableColumnWidth)
  // Cabeçalho
  doc.setFontSize(10);
  doc.text("Página: 001", margin + padding, margin + padding);
  doc.text("PRODUTOS ALIMENTICIOS TIO FRANCISCO EIRELI", margin + padding, margin + padding + 10);
  doc.text(`Protocolo de Saída de Mercadorias - Carga Número: ${data.cargaNumero}`, margin + padding, margin + padding + 20);

  // Desenha borda do cabeçalho
  doc.rect(margin, margin, pageWidth - 2 * margin, 30);

  // Detalhes principais com duas colunas
  const detailsStartY = margin + 40;
  doc.text(`Data: ${data.data}`, margin + padding, detailsStartY);
  doc.text(`Hora: ${data.hora}`, pageWidth / 2 + padding, detailsStartY);
  doc.text(`Motorista: ${data.motorista}`, margin + padding, detailsStartY + 10);
  doc.text(`Placa: ${data.placa}`, pageWidth / 2 + padding, detailsStartY + 10);
  doc.text(`Observações: ${data.observacoes}`, margin + padding, detailsStartY + 20);
  doc.text(`Valor Frete: ${data.valorFrete}`, margin + padding, detailsStartY + 30);

  // Desenha borda da seção de detalhes principais
  doc.rect(margin, detailsStartY - 5, pageWidth - 2 * margin, 40);

  // Tabela de pedidos

  console.log(margin + padding + tableColumnWidth)
  console.log(margin + padding + 2 * tableColumnWidth)
  const tableStartY = detailsStartY + 50;
  console.log(tableStartY);
  console.log(margin + padding);
  doc.text("Nº NF/Pedido", margin + padding, tableStartY);
  doc.text("Cliente", margin + padding + tableColumnWidth - 30, tableStartY);
  doc.text("Condição de Pagamento", margin + padding + 2 * tableColumnWidth + 15, tableStartY);
  let yOffset = tableStartY + 10;

  data.pedidos.forEach(pedido => {
    doc.text(pedido.numero, margin + padding, yOffset);
    doc.text(pedido.cliente, margin + padding + tableColumnWidth - 30, yOffset);
    doc.text(pedido.condicaoPagamento, margin + padding + 2 * tableColumnWidth + 15, yOffset);
    yOffset += 10;
  });

  // Desenha borda da tabela de pedidos
  doc.rect(margin, tableStartY - 10, pageWidth - 2 * margin, (data.pedidos.length + 1) * 10 + padding);

  // Resumo dos valores
  const resumoStartY = tableStartY + (data.pedidos.length + 1) * 10 + padding;
  doc.text(`Peso Total (Kgs): ${data.pesoTotal}`, margin + padding, resumoStartY);
  doc.text(`Total de Volumes: ${data.totalVolumes}`, margin + padding, resumoStartY + 10);
  doc.text(`Valor Total: ${data.valorTotal}`, margin + padding, resumoStartY + 20);
  doc.text(`Condição de Pagamento:`, margin + padding, resumoStartY + 30);
  let condicaoY = resumoStartY + 40;
  Object.keys(data.condicaoPagamento).forEach(condicao => {
    doc.text(`${condicao}: R$ ${data.condicaoPagamento[condicao]}`, margin + padding, condicaoY);
    condicaoY += 10;
  });

  // Desenha borda da seção de resumo de valores
  doc.rect(margin, resumoStartY - 10, pageWidth - 2 * margin, condicaoY - resumoStartY + 30);

  // Declaração e assinatura
  const declaracaoStartY = condicaoY + 5;
  doc.text("Declaro ter conferido as mercadorias bem como as notas/pedidos sendo o responsável pela entrega", margin + padding, declaracaoStartY + 5);
  doc.text(" e retorno dos documentos e pagamentos assinados e conferidos.", margin + padding, declaracaoStartY + 10);
  doc.text("Assinatura: ____________________________", margin + padding, declaracaoStartY + 25);

  // Desenha borda da seção de declaração e assinatura
  doc.rect(margin, declaracaoStartY, pageWidth - 2 * margin, 40);

  // Salva o PDF
  doc.save("Resumo_para_entrega_com_tabela.pdf");
}


function generatePDF(excel, total, filtros) {

  console.log()

  let tableFiltro = transformaFiltros([filtros]);
  let result = [];

  for (let i = 0; i < tableFiltro.length; i++) {
    const rowEntries = Object.entries(tableFiltro[i]);
    const filtrosConvertidos = rowEntries.reduce((acc, [key, value]) => {
      let convertedValue = value;
      if (typeof value === 'object' && value !== null && value.props && value.props.children) {
        convertedValue = value.props.children.toString(); // Converte o objeto em uma string
      }
      acc.push({ [key]: convertedValue });
      return acc;
    }, []);
    result.push(filtrosConvertidos);
  }

  const doc = new jsPDF('landscape');
  let tableData = [];

  const data = excel;
  const headers = Object.keys(data[0]).slice(0, 12);
  const headers_total = Object.values(total).slice(0, 12).map(obj => Object.values(obj)[0]);

  for (let i = 0; i < data.length; i++) {
    const rowValues = Object.values(data[i]).slice(0, 12);
    const convertedRowValues = rowValues.map((value) => {
      if (typeof value === 'object' && value !== null && value.props && value.props.children) {
        return value.props.children.toString(); // Converte o objeto em uma string
      }
      return value;
    });
    tableData.push(convertedRowValues);
  }


  doc.setFontSize(18);
  doc.text('Relatório Quadro de Vendas', 15, 15);

  doc.setFontSize(10);
  doc.text('Filtros:', 15, 25);

  const filters = result[0]; // Obtém os filtros do resultado

  if (filters) {
    let currentX = 35; // Posição X inicial para os valores dos filtros
    const y = 25; // Posição Y para a primeira linha dos filtros

    const mergedFilters = filters.reduce((acc, filter) => {
      const [label, value] = Object.entries(filter)[0]; // Obtém a legenda e o valor do filtro
      return `${acc}${label} ${value}, `; // Concatena a legenda e o valor com vírgula
    }, '');

    // Remove a vírgula extra no final
    const mergedFiltersText = mergedFilters.slice(0, -2);

    // Define o tamanho da fonte para os filtros
    doc.setFontSize(8);

    // Verifica se o texto dos filtros cabe em uma linha
    const textWidth = doc.getStringUnitWidth(mergedFiltersText) * doc.internal.getFontSize();
    const maxWidth = doc.internal.pageSize.getWidth() - currentX - 10; // Largura máxima disponível
    let filteredText = mergedFiltersText;

    if (textWidth > maxWidth) {
      // O texto excede a largura máxima, então é necessário quebrar em várias linhas
      const lines = doc.splitTextToSize(mergedFiltersText, maxWidth);
      filteredText = lines.join('\n'); // Concatena as linhas com quebras de linha
    }

    // Adiciona o texto dos filtros no documento PDF
    doc.text(filteredText, currentX, y);
  }

  // Define a margem superior da tabela
  const tableMarginTop = 35;

  // Adicionar cabeçalhos
  doc.autoTable({
    head: [headers_total, headers], // Adicione os cabeçalhos em ordem
    body: tableData,
    startY: tableMarginTop, // Ajuste a posição vertical para evitar sobreposição com o título e a legenda
    columnWidth: 'auto',
    styles: {
      fontSize: 6,
      cellStyles: {
        overflow: 'linebreak', // Quebra de linha automática para células
      },
    },
  });

  doc.save('quadro_diario.pdf');
}


function calcularTotalizadores(array) {
  const totalizadores = {};


  array.forEach((obj) => {
    for (const key in obj) {
      const valor = obj[key];

      if (typeof valor === 'string' && valor.includes('R$')) {
        const valorNumerico = parseFloat(valor.replace(/[^\d,-]+/g, '').replace(',', '.'));
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else if (key === 'Quantidade') {
        const valorNumerico = parseFloat(valor);
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else {
        if (!totalizadores[key]) {
          totalizadores[key] = '';
        }
      }
    }
  });

  const resultado = [];

  for (const key in totalizadores) {
    let value = totalizadores[key];
    if (key !== 'Quantidade') {
      if (key === 'Ticket Medio') {
        const ticketMedioValue = parseFloat(totalizadores['VT. Vendas']) / totalizadores['Quantidade'];
        value = ticketMedioValue !== NaN ? formatarValor(ticketMedioValue) : '';
      } else {
        value = totalizadores[key] !== '' ? formatarValor(totalizadores[key]) : '';
      }
      // value = totalizadores[key] !== '' ? `${formatarValor(totalizadores[key])}` : '';
    }
    resultado.push({
      [`Total ${key}`]: value,
    });
  }

  return resultado;
}

const formatDate = (date) => {
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  if (month.length === 1) {
    month = '0' + month;
  }
  let day = date.getDate().toString();
  if (day.length === 1) {
    day = '0' + day;
  }
  return `${year}-${month}-${day}`;
};


function formatarDataHora(valor) {
  if (valor === null || valor === undefined) {
    return "Sem Data";
  }

  const data = new Date(`${valor}T00:00`);
  if (isNaN(data)) {
    return "Sem Data";
  }

  const dia = ("0" + data.getDate()).slice(-2);
  const mes = ("0" + (data.getMonth() + 1)).slice(-2);
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}

function transformaFiltros(data) {

  console.log(data)
  return data.map((item) => {
    const filtro = {};

    if (item.empresa) {
      filtro['Empresa: '] = item.empresa;
    }

    if (item.TipoData) {
      filtro['Tipo Data: '] = item.TipoData;
    }

    if (item.formas) {
      filtro['Forma Pagamento: '] = item.formas;
    }

    if (item.grupo) {
      filtro['Grupo Lançamento: '] = item.grupo;
    }

    if (item.cliente) {
      filtro['Cliente: '] = item.cliente;
    }

    if (item.grupoPessoa) {
      filtro['Grupo do Cliente: '] = item.grupoPessoa;
    }

    if (item.centro) {
      filtro['Centro de Custo: '] = item.centro;
    }

    if (item.banco) {
      filtro['Banco: '] = item.banco;
    }

    if (item.cidade) {
      filtro['Cidade: '] = item.cidade;
    }

    if (item.uf) {
      filtro['UF:'] = item.uf;
    }

    if (item.vendedor) {
      filtro['Vendedor: '] = item.vendedor;
    }

    if (item.plano) {
      filtro['Plano de Contas: '] = item.plano;
    }

    if (item.inicio) {
      filtro['Data Inicio: '] = formatarDataHora(item.inicio);
    }

    if (item.fim) {
      filtro['Data Final: '] = formatarDataHora(item.fim);
    }

    return filtro;
  });
}

const decodeStatus = (status) => {
  switch (status) {
    case 0:
      return "Aguardando";
    case 2:
      return "Separação";
    case 4:
      return "Despachada";
    // Adicione mais cases conforme necessário para outros valores de status
    default:
      return "Desconhecido";
  }
};

function generateTableData(analitico, dataAtual) {
  // console.log(analitico)
  if (analitico.length === 0) {
    const keys = [
      "ID",
      "Cídigo",
      "Códigos Vendas",
      "Empresa",
      "Status",
      "Data Emissao",
      "Transportadora",
      "Data Despacho",
    ];

    const emptyObject = {};
    keys.forEach(key => {
      emptyObject[key] = '';
    });

    return [emptyObject];
  }
  return analitico.map((item) => ({
    "ID": item._id,
    "Codigo": item.Codigo,
    "Códigos Vendas": item.Codigos,
    "Empresa": item.Empresa,
    "Status": decodeStatus(item.Status),
    "Data Emissao": item.DataEmissao,
    "Transportadora": item.Transportadora,
    "Data Despacho": item.DataDespacho,
  }));
}


function gerarExcel(dados, objeto) {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(dados);

  // Extrair os valores do objeto
  const objetoValores = Object.values(objeto).map(item => Object.values(item)[0]);

  // Obter a matriz existente dos dados
  const matrizDados = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  // Mesclar as matrizes (inserir a nova matriz acima da matriz existente)
  const matrizFinal = [objetoValores, ...matrizDados];

  // Converter a matriz final em uma planilha
  const novaWorksheet = XLSX.utils.aoa_to_sheet(matrizFinal);

  // Adicionar a nova planilha ao workbook
  XLSX.utils.book_append_sheet(workbook, novaWorksheet, 'Sheet 1');

  XLSX.writeFile(workbook, 'quadro_diario.xlsx');
}


function formatarValor(valor) {
  if (valor === null || valor === undefined) {
    return "R$ 00,00";
  }

  const valorFormatado = valor.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return valorFormatado;
}

function getMesAtual() {
  const now = new Date();
  const mesAtual = String(now.getMonth() + 1).padStart(2, '0'); // +1 porque os meses começam de zero
  return mesAtual;
}

function obterAnoAtual() {
  const dataAtual = new Date();
  const anoAtual = dataAtual.getFullYear();
  return anoAtual;
}


export default function UserReports() {

  const toast = useToast();
  const [value, setValue] = useState([])
  const [analitico, setAnalitico] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [status, setStatus] = useState('');
  const [empresa, setEmpresa] = useState([]);
  const [origem, setOrigens] = useState('');
  const [prod, setFiltroProd] = useState('');
  const [categVenda, setCategVenda] = useState('');
  const [mes, setMes] = useState(getMesAtual());
  const [vendedor, setVendedor] = useState('');
  const [tabela, setTabela] = useState('');
  const [diaAtual, setDia] = useState('');
  const [filtros, setFiltros] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [inicio, setInicio] = useState('');
  const [fim, setFim] = useState('');
  const [TipoData, setTipoData] = useState('DataEmissao');
  const [generoProd, setGeneroProd] = useState("00 – Mercadoria para Revenda");
  const history = useHistory();
  const [token, setToken] = useState('');
  const [dados, setDados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState('');
  const [excel, setExcel] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [camposTotal, setCampos] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);
  const [valores, setValores] = useState({});
  const [anos, setAnos] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [update, setUpdate] = useState(false);
  const [deleteTrigger, setDeleteTrigger] = useState(false);


  const openModal = (data) => {
    setSelectedUser(data); // Define o usuário selecionado
    console.log(data);
    setIsModalOpen(true);
  };

  async function excluiCarga(data) {
    const key = await getData();

    const param = {};
    param.user = key
    param.id = data

    const response = await api.delete('/excluir-carga', { params: param, timeout: 1000000 });

    // setSelectedUser(data); // Define o usuário selecionado
    console.log(response);
    // setIsModalOpen(true);
    setDeleteTrigger(true);
  };

  useEffect(() => {
    if (deleteTrigger) {
      setUpdate(prev => !prev);  // Troque o valor de 'update' para ativar o useEffect principal
      setDeleteTrigger(false);   // Resete o gatilho
    }
  }, [deleteTrigger]);

  const closeModal = () => {
    setSelectedUser(null); // Reseta o usuário selecionado
    setIsModalOpen(false);
  };

  const handleSubmitModal = async (dados) => {
    // await fetchCasais();
    closeModal();
  };


  const optionsStatus = [
    { value: 0, label: 'Aguardando' },
    { value: 2, label: 'Separação' },
    { value: 4, label: 'Despachada' },

  ];

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleValuesSelected = (values) => {
    setSelectedValues(values);
  };

  const handleClickExcel = () => {
    gerarExcel(excel, total);
  };

  const handleClickPdf = () => {
    generatePDF(excel, total, filtros);
  };

  // const handleClickPdf = () => {
  //   generatePDF(jsonData);
  // };

  const addCodigosToDataArray = (dataArray) => {
    dataArray.forEach(data => {
      const uniqueCodigoVendas = new Set();

      if (data.items && Array.isArray(data.items)) {
        data.items.forEach(item => {
          if (item.CodigoVenda !== undefined) {
            uniqueCodigoVendas.add(item.CodigoVenda);
          }
        });
      }

      data.Codigos = `(${[...uniqueCodigoVendas].join(' , ')})`;
    });

    return dataArray;
  };

  async function index(data) {

    // console.log(data)
    const pedidos = addCodigosToDataArray(data)
    // console.log(pedidos)
    const dados_formatados = await generateTableData(pedidos, diaAtual)
    // console.log(dados_formatados);
    setCampos(dados_formatados)
    const objFinal = removeObjectsByKey(dados_formatados, selectedValues)
    // console.log(dados_formatados)
    const totais = calcularTotalizadores(objFinal)

    // console.log(totais)

    // console.log("dados formatados")
    // console.log(objFinal, totais)
    setExcel(objFinal)
    setTotal(totais)

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToShow = objFinal.slice(startIndex, endIndex);
    // console.log(itemsToShow);
    setAnalitico(itemsToShow)
  }

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem('@storage_Key')
      if (value !== null) {
        return value;// value previously stored
      }
    } catch (e) {
      // error reading value
    }
  }


  useEffect(async () => {
    const param = {};
    const key = await getData();

    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const formattedFirstDay = formatDate(firstDayOfMonth);
    const formattedLastDay = formatDate(lastDayOfMonth);

    setInicio(formattedFirstDay);
    setFim(formattedLastDay);

    if (typeof key !== 'undefined' && key !== null) {
      param.user = key;
      setFiltros(param);

      const fetchData = async () => {
        setIsLoading(true);

        try {
          const [filtrosData, dadosData] = await Promise.all([
            api.get('/filtros-logistica', { params: param, timeout: 500000 }),
            api.get('/logistica', { params: param, timeout: 5000000 }),
          ]);

          const filtros = filtrosData.data[0];
          // console.log(filtros)
          const emp = filtros.map(pessoas => ({
            value: pessoas._id,
            label: pessoas.NomeFantasia
          }));

          const selectEmpresas = document.getElementById("select-empresas");
          filtros.forEach(empresa => {
            const option = document.createElement("option");
            option.value = empresa._id;
            option.text = empresa.NomeFantasia;
            selectEmpresas.appendChild(option);
          });

          // console.log(emp)
          // setEmpresa(emp);

          console.log(empresa)
          const dados = dadosData.data[0];
          // setValores(options)
          // console.log(dados)

          setToken(key);
          setDados(dados);
          setTotalItems(dados.length);
          setAnos(obterAnoAtual());

          if (currentPage === 1) {
            await index(dados);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else {
      history.push('/');
    }
  }, [isModalOpen, update]);

  //executa a paginação na alteração de seus estados
  useEffect(() => {
    index(dados);
  }, [currentPage, itemsPerPage, selectedValues]);


  //aplica os filtros
  const handleFilter = async () => {
    const data = {}

    if ((typeof token !== 'undefined') && token !== null) {
      data.user = token

      if ((selected !== '') && selected !== null) {
        data.status = selected
      }

      if ((TipoData !== '') && TipoData !== null) {
        data.tipoData = TipoData
      }

      if ((empresa !== '') && empresa !== null) {
        data.empresa = empresa
      }


      if (inicio !== null && inicio !== '') {
        if (fim !== null && fim !== '') {
          data.fim = fim
          data.inicio = inicio
        } else {
          alert("preencher os dois periodos");
        }
      }

      setFiltros(data)

      if (Object.keys(data).length !== 0) {
        setCurrentPage(1)
        setItemsPerPage(20)
      }

      try {
        setIsLoading(true);
        api.get('/logistica', { params: data, timeout: 1000000 })
          .then(response => {
            console.log(response.data[0])
            
            if (response.data.length !== 0) {
              // console.log(response.data[0])
              setDados(response.data[0]);
              setTotalItems(response.data.length);
            } else {
              
              console.log("entrou")
              setDados(response.data[0]);
              handlePageChange(1)
              setTotalItems(20);
            }

            if (currentPage === 1) {
              index(response.data[0]);
            }
          })
          .catch(error => {
            console.error(error);
          }).finally(() => {
            setIsLoading(false)
          });;

        // console.log(data[0].length)
      } catch (error) {
        console.error(error);
      }
    } else {
      history.push('/');
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // handleSubmit(); // Atualiza os dados ao alterar a página
  };


  const handleItemsPerPageChange = (newItens) => {
    setItemsPerPage(newItens);
    setCurrentPage(1); // Reset currentPage when changing items per page
    // handleSubmit();
  };


  const [selected, setSelected] = useState([]);

  // const handleSelect = (selectedList) => {
  //   if (selectedList.length > 1) {
  //   //   setSelected([selectedList[selectedList.length - 1]]);
  //   // } else {
  //     setSelected(selectedList);
  //   }
  // };


  return (
    <Box mt={{ sm: '140px', md: '12%', lg: '15%', xl: '6%' }}>
      {isLoading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="rgba(0, 0, 0, 0.5)" // Define a cor de fundo como transparente com um leve tom escuro (50% de opacidade)
          zIndex="9999"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            color="blue.500"
            size="xl"
          />
        </Box>
      )}
      <SimpleGrid
        columns={[1, 2, 3, 5]}
        spacing={5} mb={5}
        bg={"gray.200"} rounded="md" p={5}
      // border="1px solid black"
      >
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Status da Carga:</FormLabel>
            <MultiSelect
              options={optionsStatus}
              value={selected}
              onChange={setSelected}
              hasSelectAll={false}
              // hasSelectAll={false}
              disableSearch={false}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos",
                  "clearSearch": "Limpar Pesquisa",
                  "clearSelected": "Limpar Selecionado",
                  "noOptions": "Sem opções",
                  "search": "Pesquisar",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Criar",
                }
              }
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Empresas:</FormLabel>
            <Select bg={"white"} id="select-empresas" onChange={(e) => setEmpresa(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        {/* <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Vendedor:</FormLabel>
            <Input bg={"white"} type="input" id="prod" onChange={(e) => setVendedor(e.target.value)} />
          </FormControl>
        </Box> */}
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Tipo Data:</FormLabel>
            <Select bg={"white"} id="select-data" onChange={(e) => setTipoData(e.target.value)}>
              <option value="DataEmissao">Data Emissão</option>
              <option value="DataDespacho">Data Despacho</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Início do Período:</FormLabel>
            <Input bg={"white"} type="date" id="inicio" value={inicio} onChange={(e) => setInicio(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Fim do Período:</FormLabel>
            <Input bg={"white"} type="date" id="Fim" value={fim} onChange={(e) => setFim(e.target.value)} />
          </FormControl>
        </Box>
        <Box mt="auto">
          <Button colorScheme="blue" onClick={handleFilter}>Filtrar</Button>
        </Box>
      </SimpleGrid>

      <SimpleGrid mt='1%'>
        <Button size="sm" w="10%"
          onClick={handleOpenModal}
          border="1px solid black"
        >Selecionar Campos</Button>
        <ModalComponent
          data={camposTotal}
          onValuesSelected={handleValuesSelected}
          isOpen={isOpen}
          onClose={handleCloseModal}
          selectedValues={selectedValues}
        />
        {/* <ModalComponent data={analitico} onValuesSelected={handleValuesSelected} isOpen={isOpen} onClose={handleCloseModal} valuesCheck={selectedValues} /> */}
        <Box mt='1%'>
          <Text fontWeight="bold">Valores Retirados: {selectedValues.length > 0 ? selectedValues.join(', ') : 'Nenhum valor selecionado'}</Text>
        </Box>
      </SimpleGrid>
      <SimpleGrid mt='2%'>
        <TableContainer w='100%' overflowX="auto" >
          {analitico && total && (
            <DataDisplay data={analitico} total={total} onclick={openModal} excluir={excluiCarga} />
          )}
        </TableContainer>
      </SimpleGrid>
      <Box display='flex'>
        <Pagination
          totalItems={totalItems}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          onLimitChange={handleItemsPerPageChange}
        />
        <Button mt={4}
          colorScheme="blue" mb="5%"
          mr={1} size="sm" fontSize={{ sm: "10px" }}
          leftIcon={<DownloadIcon />}
          onClick={() => openModal()}>Novo</Button>
        {/* <Button
         mt={4} colorScheme="blue" mb="5%" size="sm"
         fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} 
         onClick={handleClickPdf}>PDF</Button> */}
      </Box>

      <Modal isOpen={isModalOpen} onClose={closeModal} size="xl">
        <ModalOverlay />
        <ModalContent
          // margin="auto"
          maxW="90%"
        // height="100%"
        >
          <ModalHeader>{selectedUser ? 'Editando uma Carga' : 'Montagem de Carga'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CadastroExpedicao ID={selectedUser} onClose={() => closeModal()} /> {/* Passa o usuário selecionado para o componente Form */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
