// export default DataDisplay;
import React, { useState, useRef } from 'react';
import { TableContainer, IconButton, Table, Thead, Tbody, Tr, Th, Td, Box,
    AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";

const DataDisplay = ({ data, total, onclick, excluir }) => {
    // Filtra as chaves dos objetos para remover a chave "ID"
    const filteredKeys = data.length > 0 ? Object.keys(data[0]).filter(key => key !== 'ID') : [];

    const formatDateBR = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) return dateString; // Retorna o valor original se não for uma data válida
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const [isOpen, setIsOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const onClose = () => setIsOpen(false);
    const cancelRef = useRef();

    const handleDeleteClick = (id) => {
        setItemToDelete(id);
        setIsOpen(true);
    };

    const confirmDelete = () => {
        excluir(itemToDelete);
        setIsOpen(false);
    };

    // console.log(filteredKeys)

    return (
        <Box border="1px" overflowY="auto" w='100%'>
            <Table id="teste" variant="striped" w='100%' size="sm" maxWidth="100%">
                <Thead>
                    {/* <Tr>
                        {total.some(item => Object.values(item).some(value => value !== '' || value !== 'R$ NaN')) ? (
                            total.map((item, index) => (
                                index === 0 ? (
                                    <Td bg={'#E6E6E6'} borderBottom="1px" borderColor="black" key={index} fontWeight="bold">Totais:</Td>
                                ) : (
                                    <Td bg={'#E6E6E6'} borderBottom="1px" borderColor="black" key={index} textAlign="center">{Object.values(item)[0]}</Td>
                                )
                            ))
                        ) : (
                            <Td border="1px" bg={'#E6E6E6'} colSpan={total.length > 0 ? total.length : 1} textAlign="center" borderColor="black" fontWeight="bold">Totais:</Td>
                        )}
                    </Tr> */}
                    {data.length > 0 ? (
                        <Tr>
                            {filteredKeys.map((key) => (
                                <Th textAlign="center" bg={'#E6E6E6'} borderBottom="1px" borderColor="black" key={key}>{key}</Th>
                            ))}
                            <Th textAlign="center" bg={'#E6E6E6'} borderBottom="1px" borderColor="black">Ações</Th>
                        </Tr>
                    ) : ''}
                </Thead>
                <Tbody>
                    {data.length > 0 ? (
                        data.some(item => Object.values(item).some(value => value !== '')) ? (
                            data.map((item, index) => (
                                <Tr key={index}>
                                    {filteredKeys.map((key, index) => (
                                        <Td textAlign="center" id={item[key]} key={index}>
                                            {key === 'Data Emissao' || key === "Data Despacho" ? formatDateBR(item[key]) : item[key]}
                                        </Td>
                                    ))}
                                    <Td textAlign="center">
                                        <IconButton
                                            aria-label="Editar"
                                            icon={<EditIcon />}
                                            onClick={() => onclick(item.ID)}
                                            variant="ghost"
                                            size="sm"
                                        />
                                        <IconButton
                                            aria-label="Editar"
                                            icon={<DeleteIcon />}
                                            onClick={() => handleDeleteClick(item.ID)}
                                            // onClick={() => excluir(item.ID)}
                                            variant="ghost"
                                            size="sm"
                                        />
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Td colSpan={filteredKeys.length + 1} textAlign="center">
                                    Nenhum registro foi encontrado!
                                </Td>
                            </Tr>
                        )
                    ) : (
                        <Tr>
                            <Td colSpan={1} textAlign="center">
                                Nenhum registro foi encontrado!
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Confirmar Exclusão
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Você tem certeza que deseja excluir este item? Esta ação não pode ser desfeita.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancelar
                            </Button>
                            <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                                Excluir
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    );
};

export default DataDisplay;
