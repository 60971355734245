// Chakra imports
import axios from 'axios';
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import LineChart from "components/charts/BarChart";
import React, { useState, useEffect } from "react";
import { MdBarChart } from "react-icons/md";
import ReactApexChart from "react-apexcharts";
// import {
//   barChartDataConsumption,
//   barChartOptionsConsumption,
// } from "variables/charts";


export default function Forma({ data, mes, anos }) {
  // const { ...rest } = props;
  const [Forma, setForma] = useState([]);
  const [ds_mes, setMes] = useState('');
  const [ano, setAno] = useState('');

  const meses_select = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  useEffect(() => {
    setForma(data)
    setMes(meses_select[mes - 1]);
    setAno(anos);

  }, [data]);

  let valorTotalGeral = 0;
  const FormadePagamento = Forma.map(key => key.FormadePagamento ? key.FormadePagamento : "Sem Forma de pagamento");
  const venda = Forma.map(key => key.valorTotal);


  venda.forEach(valor => {
    valorTotalGeral += valor;
  });
  //   const formatarMoeda = (valor) => valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });

  // // Formata os valores da série antes de passá-los para o gráfico
  //   const seriesFormatadas = venda.map((valor) => formatarMoeda(valor));

  //   const seriesFormatadas = FormadePagamento.map((valor, index) => ({
  //     name: options.labels[index],
  //     y: valor,
  //   }));

  const title = ds_mes+"/"+ano+" - Valor Total do Período - R$"+valorTotalGeral.toLocaleString('pt-BR', { minimumFractionDigits: 2 });

  // const title = "Total - R$150.000,00";
  const options = {
    chart: {
      width: 320,
      type: 'donut',
      toolbar: {
        show: false
      },
    },
    // tooltip: {
    //   theme: "dark",
    //   enabled: true,
    //   style: {
    //     fontSize: '14px', // Ajuste o tamanho da fonte conforme necessário
    //     background: '#2c3e50', // Cor de fundo desejada
    //     color: 'white', // Cor do texto desejada
    //   },
    //   onDatasetHover: {
    //     highlightDataSeries: true,
    //   },
    // },
    title: {
      text: title,
      align: 'center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: 'Helvetica, Arial, sans-serif',
        color: 'white'
      },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'left',
      labels: {
        colors: "white",
        // useSeriesColors: false
      },
      name: {
        show: true,
        fontSize: '22px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 600,
        colors: "white",
        offsetY: -10,
        formatter: function (val) {
          return val
        },

      },
      floating: false,
      fontSize: '14px',
      offsetX: 0,
      offsetY: 0,
      // colors: "white"
    },
    labels: FormadePagamento,
    // colors: "white",
    tooltip: {
      y: {
        formatter: function (valor, { seriesIndex, w }) {
          // Aqui você pode personalizar o formato do valor no tooltip
          return 'Valor: R$ ' + valor.toFixed(2);
        }
      }
    },
    responsive: [
      {
        breakpoint: 768, // Define o ponto de quebra para telas menores
        options: {
          title: {
            style: {
              fontSize: '14px' // Tamanho do título para telas menores
            }
          }
        }
      }
      // Você pode adicionar mais breakpoints e opções conforme necessário
    ]
  }

  return (
    <Card bg="#0B0B61" color="white" align='center' direction='column' w='100%'>
      <Flex align='center' w='100%' px='15px' py='10px'>
        <Text
          me='auto'
          // color={}
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'>
          Formas de Pagamentos
        </Text>
      </Flex>
      {/* <Flex align='center' w='100%' px='15px'>
        <Text
          me='auto'
          color="white"
          fontSize='xl'  // Tamanho menor
          lineHeight='100%'>
          {title}
        </Text>
      </Flex> */}
      <Box h='400px' mt='auto'>
        <ReactApexChart
          options={options}
          color={"white"}
          series={venda}
          type='donut'
          width='100%'
          height='100%'
        />
      </Box>
    </Card>
  )
}