import api from 'contexts/api';
import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";


export default function VendedorChart({ data, mes, anos }) {
    const [vendedores, setVendedores] = useState([]);
    const [ds_mes, setMes] = useState('');
    const [ano, setAno] = useState('');

    const meses_select = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
    ];



    useEffect(() => {
        setVendedores(data)
        setMes(meses_select[mes - 1]);
        setAno(anos);
    }, [data]);

    // console.log(vendedores)

    const categ = [];
    const series = [{
        name: "Total Vendas",
        data: []
    }];
    let valorTotalGeral = 0;

    for (let index = 0; index < vendedores.length; index++) {
        const element = vendedores[index];
        categ.push(element.nomeVendedor)

        if (element.meta == null) {
            element.meta = 0;
        }

        valorTotalGeral += element.totalVendas;

        if (element) {
            series[0].data.push(
                {
                    x: element.nomeVendedor,
                    y: element.totalVendas,
                    goals: [
                        {
                            name: 'Meta',
                            value: element.meta,
                            strokeHeight: 5,
                            strokeColor: '#775DD0'
                        }
                    ]
                }
            )
        }
    }

    // console.log(series)
    const title = ds_mes + "/" + ano + " - Valor Total do Período - R$" + valorTotalGeral.toLocaleString('pt-BR', { minimumFractionDigits: 2 });


    const options =
    {
        chart: {
            height: 350,
            type: 'bar',
            toolbar: {
                show: false
            },
        },
        title: {
            text: title,
            align: 'center',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: undefined,
                color: 'white'
            },
        },
        tooltip: {
            theme: "dark",
            enabled: true,
            style: {
                fontSize: '14px', // Ajuste o tamanho da fonte conforme necessário
                background: '#2c3e50', // Cor de fundo desejada
                color: '#ffffff', // Cor do texto desejada
            },
            onDatasetHover: {
                highlightDataSeries: true,
            },
        },
        xaxis: {
            show: true,
            labels: {
                style: {
                    colors: "white",
                }
            },
        },
        yaxis: {
            show: true,
            labels: {
                formatter: function (value) {
                    return 'R$ ' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
                },
                style: {
                    colors: "white"
                }
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '60%'
            }
        },
        colors: ['#00E396'],
        dataLabels: {
            enabled: false
        },
        legend: {
            show: true,
            labels: {
                colors: "white",
                // useSeriesColors: false
            },
            showForSingleSeries: true,
            customLegendItems: ['Vendedores', 'Metas'],
            markers: {
                fillColors: ['#00E396', '#775DD0']
            },
            style: {
                colors: "white"
            }
        },
        responsive: [
            {
                breakpoint: 768, // Define o ponto de quebra para telas menores
                options: {
                    title: {
                        style: {
                            fontSize: '14px' // Tamanho do título para telas menores
                        }
                    }
                }
            }
            // Você pode adicionar mais breakpoints e opções conforme necessário
        ]
    }
    const textColor = useColorModeValue("secondaryGray.900", "white");


    return (
        // {...rest}
        <Card bg="#0B0B61" color="white" align='center' direction='column' w='100%' >
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text
                    me='auto'
                    color={"white"}
                    fontSize='xl'
                    fontWeight='700'
                    lineHeight='100%'>
                    Vendedores X Metas
                </Text>
            </Flex>
            {/* <Flex align='center' w='100%' px='15px'>
                <Text
                    me='auto'
                    color="white"
                    fontSize='sm'  // Tamanho menor
                    lineHeight='100%'>
                    {title}
                </Text>
            </Flex> */}
            <Box h='400px' mt='auto'>
                <ReactApexChart
                    options={options}
                    series={series}
                    type='bar'
                    width='100%'
                    height='100%'
                />
            </Box>
        </Card>
    )
}    