// import React, { useEffect, useState } from 'react';
// import { Button, FormControl, FormErrorMessage, FormLabel, Input, VStack } from '@chakra-ui/react';
// import axios from 'axios';
// import { useParams, useHistory } from 'react-router-dom';

// const Form = () => {
//   const { userId } = useParams();
//   const history = useHistory();
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [banco, setBanco] = useState('');
//   const [db, setDb] = useState('');
//   const [password, setPassword] = useState('');
//   const [vendedor, setVendedor] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState('');

//   useEffect(() => {
//     if (userId) {
//       loadUserData();
//     }
//   }, [userId]);

//   const loadUserData = async () => {
//     try {
//       const response = await axios.get(`/api/users/${userId}`);
//       const userData = response.data;

//       setName(userData.name);
//       setEmail(userData.email);
//       setBanco(userData.banco);
//       setDb(userData.db);
//       setPassword(userData.password);
//       setVendedor(userData.vendedor);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     try {
//       setIsLoading(true);
//       setError('');

//       const newUser = { name, email, banco, db, password, vendedor };
//       if (userId) {
//         await axios.put(`/api/users/${userId}`, newUser);
//       } else {
//         await axios.post('/api/users', newUser);
//       }

//       setName('');
//       setEmail('');
//       setBanco('');
//       setDb('');
//       setPassword('');
//       setVendedor('');

//       history.push('/'); // Redirecionar para a página de listagem após a criação ou atualização bem-sucedida
//     } catch (error) {
//       setError('Ocorreu um erro ao salvar o usuário.');
//       console.error(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <VStack spacing={4} align="stretch">
//       <form onSubmit={handleSubmit}>
//         <FormControl isRequired isInvalid={!!error}>
//           <FormLabel>Nome</FormLabel>
//           <Input type="text" value={name} onChange={(event) => setName(event.target.value)} />
//         </FormControl>
//         <FormControl isRequired isInvalid={!!error}>
//           <FormLabel>Email</FormLabel>
//           <Input type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
//         </FormControl>
//         <FormControl isRequired isInvalid={!!error}>
//           <FormLabel>Banco</FormLabel>
//           <Input type="text" value={banco} onChange={(event) => setBanco(event.target.value)} />
//         </FormControl>
//         <FormControl isRequired isInvalid={!!error}>
//           <FormLabel>DB</FormLabel>
//           <Input type="text" value={db} onChange={(event) => setDb(event.target.value)} />
//         </FormControl>
//         <FormControl isRequired isInvalid={!!error}>
//           <FormLabel>Password</FormLabel>
//           <Input type="password" value={password} onChange={(event) => setPassword(event.target.value)} />
//         </FormControl>
//         <FormControl isRequired isInvalid={!!error}>
//           <FormLabel>Vendedor</FormLabel>
//           <Input type="text" value={vendedor} onChange={(event) => setVendedor(event.target.value)} />
//         </FormControl>
//         {error && <FormErrorMessage>{error}</FormErrorMessage>}
//         <Button type="submit" colorScheme="blue" isLoading={isLoading}>
//           {userId ? 'Atualizar' : 'Criar'}
//         </Button>
//       </form>
//     </VStack>
//   );
// };

// export default Form;


import React, { useEffect, useState } from 'react';
import { Button, FormControl, FormErrorMessage, FormLabel, Input, VStack, Select } from '@chakra-ui/react';
import axios from 'axios';
import api from 'contexts/api';
import { MultiSelect } from "react-multi-select-component";

const Form = ({ user, onSubmit }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [banco, setBanco] = useState('');
  const [db, setDb] = useState('');
  const [password, setPassword] = useState('');
  const [vendedor, setVendedor] = useState('');
  const [perfils, setPerfil] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setBanco(user.banco);
      setDb(user.db);
      setPassword(user.password);
      setVendedor(user.vendedor);
      setPerfil(findOptionsByValues(user.perfil));
    } else {
      // Reseta os campos do formulário quando o usuário não está definido (modo de inclusão)
      setName('');
      setEmail('');
      setBanco('');
      setDb('');
      setPassword('');
      setVendedor('');
      setPerfil([]);
    }
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      setError('');

      const perfil = concatenateLabels(perfils);

      const newUser = { name, email, banco, db, password, vendedor, perfil};
      if (user) {
        await api.put(`/users/${user.user_id}`, newUser);
      } else {
        const response = await api.post('/users', newUser);
        console.log(response)
      }

      setName('');
      setEmail('');
      setBanco('');
      setDb('');
      setPassword('');
      setVendedor('');

      onSubmit(); // Chama a função de submissão do formulário no componente pai
    } catch (error) {
      setError('Ocorreu um erro ao salvar o usuário.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const options = [
    { value: 1, label: 'Basico' },
    { value: 2, label: 'Completo' },
    { value: 3, label: 'Logistica' },
    { value: 4, label: 'Vendedor' }
  ];

  function findOptionsByValues(values) {
    // Quebra a string recebida por "," e converte para um array de números
    const valueArray = values.split(',').map(value => parseInt(value.trim(), 10));
    
    // Busca os objetos correspondentes aos valores fornecidos
    const foundOptions = options.filter(option => valueArray.includes(option.value));
  
    // Retorna os objetos encontrados dentro de um array
    return foundOptions;
  }
  
  function concatenateLabels(optionsArray) {
    // Mapeia os labels dos objetos e os concatena em uma única string separada por ","
    return optionsArray.map(option => option.value).join(',');
  }

  return (
    <VStack spacing={4} align="stretch">
      <form onSubmit={handleSubmit}>
        <FormControl isRequired isInvalid={!!error}>
          <FormLabel>Nome</FormLabel>
          <Input type="text" value={name} onChange={(event) => setName(event.target.value)} />
        </FormControl>
        <FormControl isRequired isInvalid={!!error}>
          <FormLabel>Email</FormLabel>
          <Input type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
        </FormControl>
        <FormControl isRequired isInvalid={!!error}>
          <FormLabel>Banco</FormLabel>
          <Input type="text" value={banco} onChange={(event) => setBanco(event.target.value)} />
        </FormControl>
        <FormControl isRequired isInvalid={!!error}>
          <FormLabel>DB</FormLabel>
          <Input type="text" value={db} onChange={(event) => setDb(event.target.value)} />
        </FormControl>
        <FormControl isRequired isInvalid={!!error}>
          <FormLabel>Password</FormLabel>
          <Input type="password" value={password} onChange={(event) => setPassword(event.target.value)} />
        </FormControl>
        <FormControl isRequired isInvalid={!!error}>
          <FormLabel>Vendedor</FormLabel>
          <Input type="text" value={vendedor} onChange={(event) => setVendedor(event.target.value)} />
        </FormControl>
        {/* <FormControl>
          <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Perfil:</FormLabel>
          <Select id="select-empresas" value={perfil} onChange={(e) => setPerfil(e.target.value)}>
            <option value="">Selecione</option>
            <option value="1">Basico</option>
            <option value="2">Completo</option>
          </Select>
        </FormControl> */}
        <FormControl>
          <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Perfil:</FormLabel>
          <MultiSelect
            options={options}
            value={perfils}
            onChange={setPerfil}
            hasSelectAll={false}
            // hasSelectAll={false}
            disableSearch={false}
            overrideStrings={
              {
                "allItemsAreSelected": "Todos",
                "clearSearch": "Limpar Pesquisa",
                "clearSelected": "Limpar Selecionado",
                "noOptions": "Sem opções",
                "search": "Pesquisar",
                "selectAll": "Todos",
                "selectAllFiltered": "Select All (Filtered)",
                "selectSomeItems": "Selecione",
                "create": "Criar",
              }
            }
          />
        </FormControl>
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
        <Button mt={1} type="submit" colorScheme="blue" isLoading={isLoading}>
          {user ? 'Atualizar' : 'Incluir'}
        </Button>
      </form>
    </VStack>
  );
};

export default Form;
