// Chakra imports
import axios from 'axios';
import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";


function extrairDia(data) {
    const partes = data.split('-');
    return parseInt(partes[2]);
}


export default function Diario({ data }) {
    // const { ...rest } = props;
    const [barChartDataConsumption, setbarChartDataConsumption] = useState([]);

    useEffect(() => {
        setbarChartDataConsumption(data)
    }, [data]);


    const categ = [];
    const series = {};

    let hoje = new Date();
    // let primeiroDiaDoMes = new Date(hoje.getFullYear(), hoje.getMonth(), 1).getTime();
    let primeiroDiaDoMes = new Date(hoje.getFullYear(), hoje.getMonth(), 1).getDate()
    let ultimoSegundoDoMes = new Date(hoje.getFullYear(), hoje.getMonth(), new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0).getDate(), 23, 59, 59, 999).getDate();

    let VtotalEntrada = 0;
    let VtotalSaida = 0;

    // Loop para processar os dados e criar as séries
    for (let i = 0; i < barChartDataConsumption.length; i++) {
        const dado = barChartDataConsumption[i];
        let name = "";

        // console.log(dado.Despesa)

        if (dado.Despesa) {
            name = "Despesa"
        } else {
            name = "Receita"
        }

        if (!categ.includes(parseInt(dado.Data))) {
            categ.push(parseInt(dado.Data))
        }
        // Cria uma série se ainda não existe para o status atual
        if (!series[name]) {
            series[name] = {
                name: name,
                data: [],
            };
        }

        let novoPonto = {}

        // Adiciona um ponto à série com as informações do dado atual
        if (dado.Despesa) {
            novoPonto = {
                x: extrairDia(dado.Data),
                y: dado.vSaida
                // valorTotalFormatado: dado.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
            };

            VtotalSaida += dado.vSaida

        } else {
            novoPonto = {
                x: extrairDia(dado.Data),
                y: dado.vEntrada
                // valorTotalFormatado: dado.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
            };

            VtotalEntrada += dado.vEntrada
        }

        series[name].data.push(novoPonto);

        // Ordena os pontos da série em ordem crescente com base no valor de x
        series[name].data.sort((a, b) => a.x - b.x);
    }

    const seriesArray = Object.values(series);

    const title = "Total Receita R$" + VtotalEntrada.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) + " | Total Despesa R$" + VtotalSaida.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
    // console.log(seriesArray)

    const options = {
        chart: {
            type: 'bar',
            width: "100%",
            height: 350,
            zoom: {
                enabled: false
            },
        },
        title: {
            text: title,
            align: 'center',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: undefined,
                color: 'white'
            },
        },
        plotOptions: {
            bar: {
                barHeight: '100%', // ajuste o valor de barHeight para controlar a altura das barras
                columnWidth: '100%'
            },
        },
        tooltip: {
            theme: "dark",
            enabled: true,
            style: {
                fontSize: '14px', // Ajuste o tamanho da fonte conforme necessário
                background: '#2c3e50', // Cor de fundo desejada
                color: '#ffffff', // Cor do texto desejada
            },
            onDatasetHover: {
                highlightDataSeries: true,
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: "straight"
        },
        xaxis: {
            type: 'numeric',
            min: primeiroDiaDoMes,
            max: ultimoSegundoDoMes,
            tickAmount: 15,
            tickPlacement: 'on',
            tickInterval: 2,
            labels: {
                formatter: function (value) {
                    return Math.floor(value).toString();
                },
                style: {
                    colors: "white",
                }
            },
        },
        yaxis: {
            show: true,
            labels: {
                formatter: function (value) {
                    return value ? 'R$ ' + value.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : '';
                },
                style: {
                    colors: "white",
                }
            },
            // forceNiceScale: true
        },
        legend: {
            labels: {
                colors: "white",  // Altere aqui para a cor desejada
            },
        },
        responsive: [{
            breakpoint: 768, // define o tamanho da tela para a configuração móvel
            options: {
                xaxis: {
                    tickAmount: 5, // diminui o número de rótulos
                    tickInterval: 6 // aumenta o intervalo de dias
                },
                plotOptions: {
                    bar: {
                        barHeight: '30%', // ajuste o valor de barHeight para controlar a altura das barras
                        columnWidth: '100%'
                    },
                },
            }
        }]
    };
    // Chakra Color Mode
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const iconColor = useColorModeValue("brand.500", "white");
    const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
    );
    const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.100" }
    );
    return (
        <Card bg="#0B0B61" align='center' direction='column' w='100%'>

            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text
                    me='auto'
                    color={"white"}
                    fontSize='xl'
                    fontWeight='700'
                    lineHeight='100%'>
                    Fluxo de Caixa Diario
                </Text>
                {/* <Button
              align='center'
              justifyContent='center'
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              w='37px'
              h='37px'
              lineHeight='100%'
              borderRadius='10px'
              {...rest}>
              <Icon as={MdBarChart} color={iconColor} w='24px' h='24px' />
            </Button> */}
            </Flex>

            <Box h='400px' mt='auto'>
                {/* <LineChart
            chartData={bar}
            chartOptions={lineChartOptionsTotalSpent}
          /> */}
                <ReactApexChart
                    options={options}
                    series={seriesArray}
                    type='bar'
                    width='100%'
                    height='100%'
                />
            </Box>
        </Card>
    )
}